import React, { useState, useRef, useEffect, useContext } from 'react'
import {Box, Grid, Link, Button} from '@mui/material'
import { styled, alpha, createTheme } from '@mui/material/styles'
import Theme from '../config/Theme'
import axios from 'axios'
import Video from './video/Video'
import PageLayout from '../Layout/PageLayout'
import {getApiRoutes} from '../config/Routes'
import { useLocation } from 'react-router-dom'
import CustomCircularProgress from '../components/Loader'
import {isEqual, isEmpty} from 'lodash'
import { AuthContext } from '../redux/contexts/AuthContext'
import Login from '../views/auth/Login'


const theme = createTheme()

var already_loaded = true;

export default ({route}) => {
    const { isLoggedIn } = useContext(AuthContext)
    const [showLogin, setShowLogin] = useState(false)
    const location = useLocation()
    const [paramRequest, setParamRequest] = useState('following') /* pentru rewuest-ul de la tab-urile follwoing si foryou */
    const [clips, setClips] = useState([])
    const [offset, setOffset] = useState(0)
    const ref = useRef(null)
    const [page, setPage] = useState(1)
    const [loadingClips, setLoadingClips] = useState(false)
    const api = getApiRoutes()
    const gift = location?.state?.gift
    const [showGift, setShowGift] = useState(false)
    const [boughtGift, setBoughtGift] = useState([])
    const videoId = 0 || location?.state?.videoId /* got to videoId afiseza video-ul pe care ai dat click in alta pagina*/

    console.log("location", location)
    console.log("videoId",videoId)

    const toggleLogin = () => {
        setShowLogin(!showLogin)
    }

    const loadClips = () => {
        let following = isEqual(paramRequest,'following') ? true : false       
        setLoadingClips(true)
        let seed = Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000;

        if(page === '1') {
          //get last page
            axios.get(api.clips.path+'?first='+videoId+'&following='+following+'&liked=false&mine=false&saved=false&seed=' + seed + '&page=' + page).then(response => {
                setPage(response.data.meta.last_page);
                loadClips();
            });
        } 
        else {
            axios.get(api.clips.path+'?first='+videoId+'&following='+following+'&liked=false&mine=false&saved=false&seed=' + seed + '&page=' + page).then(response => {
                var newVideos = clips;
                newVideos = newVideos.concat(response.data.data);
                setClips(newVideos)
                setPage(page-1);
                
                if(page === '1')
                    setTimeout(function() {
                        console.log("test")
                        document.querySelector('video').play();
                        // allvideos[0].play();
                    }, 1500);
            });
        }
        setLoadingClips(false)
    }

    const isInViewPort = (el) => {
        const rect = el.getBoundingClientRect();
        const elemTop = rect.top + 400;
        const elemBottom = rect.bottom - 400;
        const isVisible = elemTop < window.innerHeight && elemBottom >= 0;
       
        return isVisible;
    }
    
    useEffect(() => {
        loadClips()

        const onScroll = () => setOffset(window.pageYOffset);
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, {
            passive: true
        });
        return () => window.removeEventListener('scroll', onScroll);
    }, [paramRequest]);
   

    var lastScrollTop = 0;

    window.onscroll = () => {
    var scrollTop = window.pageYOffset || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
    console.log("scrollTop=",scrollTop)
    if(already_loaded)
    {
      if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight-400) {
        already_loaded = false;
        setTimeout(function(){
          already_loaded = true;
        }, 1500);
        loadClips();
      }
    }

    let videos = document.querySelectorAll('video');
    if (scrollTop > lastScrollTop) {
      // downscroll code
      for (let i = 0; i < videos.length; i++) {
        if (isInViewPort(videos[i])) {
          // videos[i].play();
        } else {
          let newi = (parseInt(i/3)+1)*3;
            let newvideo = videos[newi];
          if(newvideo)
            newvideo.play();
            videos[i].pause();
        }
      }
    } else {
        // upscroll code
        for (let i = 0; i < videos.length; i++) {
            if (isInViewPort(videos[i])) {
            // videos[i].play();
            } else {
            let newi = (parseInt(i/3)-1)*3;
            
            let newvideo = videos[newi];
            if(newvideo)
                newvideo.play();

            videos[i].pause();
        }   
    }
}


    if(scrollTop > lastScrollTop)
       console.log('bottom');
    else
        console.log('top');
        lastScrollTop = scrollTop <= 0 ? 0 : scrollTop; // For Mobile or negative scrolling
    };


    const showBoughtGift = (gift) => {
        setBoughtGift(gift)
        setShowGift(true)
        setTimeout(function() {
            setShowGift(false)
            setBoughtGift([])
        }, 10000)
    }

    const renderBoughtGift = () => {
        if(showGift)
        {
            return (
                <Grid container justifyContent="flex-start" style={{zIndex: 250, marginTop:80, marginBottom:-40}}>
                    <Grid item md={12}>
                    <img src={boughtGift.image} style={{width: 50, height: 50, borderRadius:'50%', boxShadow:"0px 0px 16px 2px rgb(227 227 211 / 91%)"}} />
                    </Grid>
                </Grid>
            )
        }
    }

    useEffect(() => {
        gift && showBoughtGift(gift)
    },[gift])

    const handleClickTab = (param) => () => {
        setParamRequest(param)
    }

    useEffect(() => {
        /* daca vizualizez video-ul pe care dau click in alta pagina il duc pe tabul foryou indiferent daca e sau nu autentificat*/
        if(videoId != 0) setParamRequest('foryou')
    },[videoId])


    return (
        <PageLayout bgColor='#000000'>
            <Login {...{showLogin, toggleLogin}}/>
            {renderBoughtGift()}
            { loadingClips || isEmpty(clips) ?
                <Box sx={{flex:1, display:"flex", justifyContent:"center", alignItems:"center"}}>
                    <CustomCircularProgress/>
                </Box>
                :
                <Box sx={{ pt: 1, pb: 7,display:"flex", flex: 1, flexDirection:"column"}} ref={ref}>
                    <Box color="#fff" display='flex' justifyContent="center" marginTop={10} marginBottom={-5}>
                        <Box width={60} marginRight={2} textAlign="center">
                            <Link key="following" component="button" onClick={handleClickTab('following')} style={{color:"#fff", textDecoration:'none' }}>
                                Following
                            </Link>
                            <Box height={2} marginTop={0.5} backgroundColor="#fff" style={{display: isEqual(paramRequest,'following') ? 'block' : 'none'}}></Box>
                        </Box>
                        <Box width={60} textAlign="center">
                            <Link key="foryou" component="button" onClick={handleClickTab('foryou')}    style={{color:"#fff", textDecoration:'none'}}>
                                For you
                            </Link>
                            <Box height={2} marginTop={0.5} backgroundColor="#fff" style={{display: !isEqual(paramRequest,'following') ? 'block' : 'none'}}></Box>
                        </Box>
                    </Box>
                    {(isEqual(paramRequest,'following') && !isLoggedIn) && 
                        <Box sx={{display:"flex", flex:1, flexDirection:"column", justifyContent:"center", alignItems:"center", color:"#fff"}} >
                            <Box marginBottom={2}>You need to be logged in to access this.</Box>
                            <StyledButton variant="contained" size="small" onClick={toggleLogin} sx={{ display: { xs: 'none', sm: 'block' } }}>Logged In</StyledButton>
                        </Box>
                    }   
                    <Box className="allVideos">
                        <Grid container justifyContent="center" maxWidth="sm">
                        {
                            !(isEqual(paramRequest,'following') && !isLoggedIn) &&
                            
                            clips.map((clip, index) => (
                                <Grid item md={12} key={index} sx={{marginBottom: 1}}>
                                    <Video data={clip} id={index} key={index}/>
                                </Grid>
                            ))
                        }
                        </Grid>
                    </Box>
                </Box>
        }
        </PageLayout>
    );
}

const StyledButton = styled(Button)(({ theme }) => ({
    backgroundColor: Theme.yellowColor,
    color: Theme.primaryColor,
    marginLeft: 10,
    '&:hover': {
        backgroundColor: alpha(Theme.yellowColor, 0.75),
    },
}));

