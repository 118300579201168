import React from 'react'
import {Box, CircularProgress} from '@mui/material'
import Theme from "../config/Theme"


const CustomCircularProgress = (props) => {
    return (
        <Box sx={{ position: 'relative' }}>
            <CircularProgress
                variant="determinate"
                sx={{ color: 'rgb(0 0 0 / 12%)'}}
                size={30}
                thickness={4}
                {...props}
                value={100}
            />
            <CircularProgress
                variant="indeterminate"
                disableShrink
                sx={{
                    color: Theme.yellowColor,
                    animationDuration: '550ms',
                    position: 'absolute',
                    left: 0,
                }}
                size={30}
                thickness={4}
                {...props}
            />
        </Box>
    )
}

export default CustomCircularProgress
