import React, {Fragment} from 'react'
import '../config/i18n'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import './App.css'
import reportWebVitals from './reportWebVitals'
import { AuthProvider } from '../redux/contexts/AuthContext'
import Login from '../views/auth/Login'
import Home from '../views/Home'
import Profile from '../views/profile/Profile'
import Discover from '../views/discover/Discover'
import Gifts from '../views/Gifts'
import Settings from '../views/profile/Settings'
import ViewClips from '../views/discover/ViewClips'
import News from '../views/discover/News'
import Search from '../views/discover/Search'
import Wallet from '../views/profile/Wallet'
import Recharge from '../views/profile/Recharge'
import Notifications from '../views/notifications/Notifications'
import Conversations from '../views/notifications/Conversations'
import Chat from '../views/chat/Chat'
import Report from '../views/Report'
import Followers from '../views/profile/Followers'
import Following from '../views/profile/Following'
import EditProfile from '../views/profile/EditProfile'
import Verification from '../views/profile/Verification'
import Record from '../views/Record'
import Songs from '../views/Songs'
import TermsAndConditions from '../views/TermsAndConditions'
import Hashtag from '../views/Hashtag'

const App = () => {

    return (
        <Fragment>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/profile/:id" element={<Profile />} />
                <Route path="/edit-profile/:id" element={<EditProfile />} />
                <Route path='/verification/:id' element={<Verification/>} />
                <Route path="/discover" element={<Discover />} />
                <Route path="/gifts" element={<Gifts/>} />
                <Route path="/report" element={<Report/>} />
                <Route path="/settings" element={<Settings/>} />
                <Route path="/clips/:section_id" element={<ViewClips/>} />
                <Route path="/news" element={<News/>} />
                <Route path="/search" element={<Search/>} />
                <Route path="/wallet" element={<Wallet/>} />
                <Route path="/recharge" element={<Recharge/>} />
                <Route path="/notifications" element={<Notifications/>} />
                <Route path="/conversations" element={<Conversations/>} />
                <Route path="/chat" element={<Chat/>} />
                <Route path='/login' exact element={Login} />
                <Route path='/followers' element={<Followers/>} />
                <Route path='/following' element={<Following/>} />
                <Route path='/record' element={<Record/>} />
                <Route path='/terms-and-conditions' element={<TermsAndConditions/>} />
                <Route path='/:hashtag' element={<Hashtag/>} />
                {/* <Route path='/songs' element={<Songs/>} /> */}
            </Routes>
        </Fragment>
    )
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <AuthProvider>
            <App/>
        </AuthProvider>
    </BrowserRouter>
)


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();