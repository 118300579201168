import React, { useState, useEffect, useContext } from 'react'
import {Container, Box, Typography, Avatar, Badge} from '@mui/material'
import Theme from "../../config/Theme"
import axios from 'axios'
import {getApiRoutes} from '../../config/Routes'
import { AuthContext } from '../../redux/contexts/AuthContext'
import { isEmpty } from 'lodash'


const photo = require('../../assets/photo_placeholder.png')

export default () => {
    const { user } = useContext(AuthContext)
    const api = getApiRoutes()
    const [liveStreams, setLiveStreams] = useState([])

     const loadLiveStreams = () => {
        console.log("Loading livestreams");
        axios({
            method: api.livestreams.method,
            url: api.livestreams.path + "?page=1",
        }).then(function(response) {
            setLiveStreams(response.data.data)
        }).catch(function(error) {
            console.log(error.message)
        });
    }

    useEffect(() => {
        loadLiveStreams()
    }, [])

    // console.log("liveStream", liveStreams)

    const handleClickViewLive = (id) => {
        axios({
            method: 'post',
            url: api.livestreams.path + '/' + id + '/join',
            data: {
                uid: id
            }
        }).then(res => {
            console.log(res.data);
            
            // let data_live = res.data;
            // navigation.navigate("LiveStream", {from: 'home', data_live})
        }).catch(err => {
            console.log(err)
        })
    }

    const handleGoLive = () => {
        if (user.me) {
            console.log("golive")
        }
        else return;
    }

  
    return (
        <Box sx={{marginTop: 3, marginBottom: 3, display:"flex", alignItems:"center"}}>
            
            <Badge
                overlap="circular"
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                badgeContent={<Box sx={{backgroundColor:Theme.greenColor, paddingLeft: "5px", paddingRight:"5px", marginTop:"13px", marginLeft:"-65px", borderRadius:"4px"}}>
                    <Typography variant="caption">Go live</Typography>
                    </Box>}
            >
                <Avatar src={photo} sx={{marginRight: 2, border:"3px solid", borderColor:Theme.greenColor, width: 50, height: 50 }} onClick={handleGoLive}></Avatar>
            </Badge>
           
            {!isEmpty(liveStreams) ?
                <Box>
                    {liveStreams.map(livestream => {
                        return (
                            <Badge
                                overlap="circular"
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                badgeContent={<Box sx={{backgroundColor:Theme.yellowColor, paddingLeft: "5px", paddingRight:"5px", marginTop:"70px", marginLeft:"-6px"}}>{livestream.user.username}</Box>}
                            >
                                <Avatar 
                                    src={{uri: livestream.user.photo}}
                                    sx={{marginRight: 2, border:"3px solid", borderColor:Theme.greenColor, width: 50, height: 50 }}
                                    onClick={() => {handleClickViewLive(livestream.id)}}
                                />
                            </Badge>
                        )
                    })}

                </Box>
                :
                <Box><Typography variant="caption" color={Theme.whiteColor}>No live streams found to display.</Typography></Box>
            }
        </Box>
    )
}