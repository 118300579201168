import React, { useState, useEffect, Fragment, useContext } from 'react'
import {Container, Box, Typography, Avatar, Grid, CardHeader, Button, SvgIcon, IconButton } from '@mui/material'
import { styled, alpha } from '@mui/material/styles'
import Theme from '../../config/Theme'
import PageLayout from '../../Layout/PageLayout'
import TitlePageBar from '../../Layout/TitlePageBar'
import GoBackButton from '../../components/GoBackButton'
import icons from '../../assets/icons'
import { useNavigate } from 'react-router-dom'
import {getApiRoutes} from '../../config/Routes'
import axios from 'axios'
import ConversationsList from './ConversationsList'
import { AuthContext } from '../../redux/contexts/AuthContext'
import { isEmpty } from 'lodash'

const Conversations = () => {
    const {user, token} = useContext(AuthContext)
    const  userToken = token || localStorage.getItem('token')
    const navigate = useNavigate()
    const api = getApiRoutes()
    const [conversations, setConversations] = useState([])

    const renderLeftComponent = () => <GoBackButton/>

    const loadConversations = () => {
        axios.defaults.headers["Authorization"] = `Bearer ${userToken}`
        axios({
            method: api.threads.method,
            url: api.threads.path + '?user=' + user.data.id
        }).then(response => {
            setConversations(response.data.data)
        })
    }
       

    useEffect(() => {
        !isEmpty(user) && loadConversations()
    },[])

    useEffect(() => {
        !isEmpty(user) && loadConversations()
    },[user])

    return (
        <PageLayout bgColor='#000'>
            <TitlePageBar title="Conversations" showLeftComponent={true} leftComponent={renderLeftComponent} />
            <Box sx={{display:"flex",  flex: 1, flexDirection:"column"}}>
                <ConversationsList {...{conversations}}/>
            </Box>
        </PageLayout>
    )
}

export default Conversations

