import React, { Fragment } from 'react'
import {Grid, Box, Typography, Divider, Avatar } from '@mui/material'
import { styled, alpha } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import Theme from '../../config/Theme'
import { isEmpty, toLower } from 'lodash'
import monthNames from '../../components/monthNames'
import StarsIcon from '@mui/icons-material/Stars'
import {getApiRoutes} from '../../config/Routes'
import { useNavigate, Link  } from 'react-router-dom'


export default ({conversations}) => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const handleClickOpenChat = (conversation) => () => {
        navigate('/chat', {state: {'from':'conversation', ...conversation} })
    }

    return(
        isEmpty(conversations) ?
        <Box sx={{display:"flex", flex:1, justifyContent:"center", alignItems:'center'}}color="#fff" textAlign="center">No conversations</Box>
        :
        <Box>
            { conversations.map((conversation,idx) => {
                return (
                    <Fragment key={idx}>
                        <StyledGrid container sx={{display:"flex", alignItems:"center"}} onClick={handleClickOpenChat(conversation)}>
                            <Grid item xs={12} sm={6} sx={{display:"flex", alignItems:"center"}}>
                                <BorderAvatar alt="user.name" src={conversation.user.photo} />
                                <Box>
                                    <Box sx={{display:"flex", alignItems:"center"}}>
                                        <Typography color="#fff">{`@${conversation.user.username} `}</Typography>
                                        <StarsIcon sx={{color:Theme.greenColor, fontSize:20, marginLeft: 1}}/>
                                    </Box>
                                
                                    <Typography color={Theme.grayColor} sx={{fontSize: 13}}>
                                        {conversation.latest.body}
                                    </Typography>
                                </Box>
                                
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography color={Theme.grayColor} sx={{fontSize: 12}} textAlign="right">{`  pe ${new Date(conversation.latest.updated_at).getDate() + ' ' + toLower(t(monthNames[new Date(conversation.updated_at).getMonth()]))}`}</Typography>
                            </Grid>
                        </StyledGrid>
                        <Divider sx={{borderColor:Theme.grayDarkColor, marginTop:2, marginBottom:2}}/>
                    </Fragment>
                
                )
            })
        }
        </Box>
    )
}

const BorderAvatar = styled(Avatar)(() => ({
    border:"2px solid",
    borderColor: Theme.yellowColor,
    marginRight: 10,
    '& img': {
        borderRadius:'50%'
    }
}));

const StyledGrid = styled(Grid)(() => ({
    paddingLeft:16,
    paddingRight:16,
    '&:hover': {
        cursor:"pointer",
        borderRadius: 4,
        backgroundColor: alpha(Theme.whiteColor, 0.15),
    },
}));

