
import React, { useState } from 'react'
import { styled, alpha, createTheme, ThemeProvider } from '@mui/material/styles'
import Theme from '../config/Theme'
import { Box, Button, IconButton, SvgIcon } from '@mui/material'
import icons from '../assets/icons'
import MoreIcon from '@mui/icons-material/MoreVert'
import VolumeMuteIcon from '@mui/icons-material/VolumeMute'
import VolumeOffIcon from '@mui/icons-material/VolumeOff'
import ProfileMenu from '../components/ProfileMenu'
import LoginIcon from '@mui/icons-material/Login'

const theme = createTheme()

const RightSideHeader = ({ isLoggedIn, isMuted, muteVideos, colorSvgIcons, toggleLogin }) => {
    const [anchorEl, setAnchorEl] = useState(null)
    const isMenuOpen = Boolean(anchorEl)
    const menuId = 'primary-search-account-menu'
    const [showShareDialog, setShowShareDialog] = useState(false)
   
    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };


    return (
        <ThemeProvider theme={theme}>
            <StyledIconButton size="medium" aria-label="mute/unmute" color="inherit" onClick={muteVideos}>
                {(isMuted) ? <VolumeOffIcon /> : <VolumeMuteIcon />}
            </StyledIconButton>
            

            <ProfileMenu {...{isLoggedIn, anchorEl, isMenuOpen, menuId, handleMenuClose}}/>
            
            {!isLoggedIn && <StyledButton variant="contained" size="small" onClick={toggleLogin} sx={{ display: { xs: 'none', sm: 'block' } }}>Logged In</StyledButton> }

            {!isLoggedIn && 
                <StyledIconButton
                    size="medium"
                    aria-label="login"
                    // aria-haspopup="true"
                    onClick={toggleLogin}
                    color="inherit"
                    sx={{ display: { xs: 'block', sm: 'none' }, width: 40, height: 40 }}
                >
                    <LoginIcon fontSize="small"/> 
                
                </StyledIconButton>
            }

            <StyledIconButton
                size="medium"
                aria-label="show more"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
            >
                <SvgIcon>
                    <path d={icons.ic_more} fill={colorSvgIcons}/>
                </SvgIcon>
            </StyledIconButton>
        </ThemeProvider>
    )
}

export default RightSideHeader

const StyledButton = styled(Button)(({ theme }) => ({
    backgroundColor: Theme.yellowColor,
    color: Theme.primaryColor,
    marginLeft: 10,
    '&:hover': {
        backgroundColor: alpha(Theme.yellowColor, 0.75),
    },
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    '&:hover': {
        backgroundColor: alpha(Theme.whiteColor, 0.15),
    },
}));