import React, { useState, useEffect, useContext  } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, SvgIcon, Typography, TextField } from '@mui/material'
import { styled, alpha } from '@mui/material/styles'
import Theme from "../config/Theme"
import PageLayout from '../Layout/PageLayout'
import TitlePageBar from '../Layout/TitlePageBar'
import {getApiRoutes} from '../config/Routes'
import axios from 'axios'
import GoBackButton from '../components/GoBackButton'
import reasons from '../config/reasons'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { useParams, useLocation } from 'react-router-dom'
import { AuthContext } from '../redux/contexts/AuthContext'
import { isEqual } from 'lodash'


export default () => {
    const { t } = useTranslation()
    const api = getApiRoutes()
    const { user } = useContext(AuthContext)
    const [reason, setReason] = useState(Object.values(reasons)[0])
    const [message, setMessage] = useState('')
    const location = useLocation()
    const { userId, from } = location.state
    
   
    const renderLeftComponent = () => <GoBackButton/>

    const handleResonSelect = e => {
        setReason(e.target.value)
    }

    const handleChange = (e) => {
       setMessage(e.target.value)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const subject_type = isEqual(from,'profile') ? 'user' : isEqual(from,'home') ? 'clip' : isEqual(from,'comment') ? 'comment' : '' /*'in:clip,comment,user' */
        const dataForSubmit = { 
            'subject_id': userId, 
            'subject_type': subject_type, 
            'reason': Object.keys(reason)[0], 
            'message': message 
        } 
        axios({
            method: api.reports.method,
            url: api.reports.path,
            data: dataForSubmit
        }).then(res => {
            console.log("res", res)
        }).catch(err => {
            console.log(err.response.data)
        })
    }
           
    return (
        <PageLayout bgColor='#000'>
            <TitlePageBar title="Report" showLeftComponent={true} leftComponent={renderLeftComponent}/>
            
            <Box sx={{display:"flex",  flex: 1, flexDirection:"column"}}>
                <Box sx={{display:"flex",  justifyContent:"space-between", alignItems:"center"}}>
                    <StyledForm size="small" sx={{ m: 1, width: 300 }}>
                        <InputLabel id="reason">Reason</InputLabel>
                        <Select
                            labelId="reason"
                            id="reason"
                            value={reason}
                            label="Reason"
                            onChange={handleResonSelect}
                            style={{color:"#fff", fontSize:12}}
                            // autoFocus
                            
                        >
                            {
                                reasons.map(reason => {
                                    return (
                                        <MenuItem key={Object.values(reason)} value={reason}>{Object.values(reason)}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </StyledForm>
                    <CustomTextfield
                        size="small"
                        required
                        autoFocus
                        margin="dense"
                        id="message"
                        label="Message"
                        fullWidth
                        variant="outlined"
                        // error={!!errorsMessage?.email}
                        // helperText={errorsMessage?.email && t("errorEmail")}
                        name="message"
                        value={message}
                        onChange={handleChange}
                        autoComplete="off"
                    />
                    <StyledButton variant="contained" type="submit" onClick={handleSubmit}>Send</StyledButton>
                </Box> 
            </Box>
        </PageLayout>
    )
}

const CustomTextfield = styled(TextField)(() => ({
    width: 300,
    fontSize:12,
    '& label.Mui-focused': {
        color: Theme.grayColor,
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: Theme.grayColor,
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: Theme.grayColor,
        },
        '&:hover fieldset': {
            borderColor: Theme.grayColor,
        },
        '&.Mui-focused fieldset': {
            borderColor: Theme.grayColor,
        },
    },
    '& .MuiInputBase-input':{
        color: Theme.grayColor,
        fontSize:12
    },
    '& label': {
        color: Theme.grayColor,
        fontSize:13,
    },
}));

const StyledButton = styled(Button)(() => ({
    backgroundColor: Theme.grayDarkColor,
    color: Theme.grayColor,
    borderRadius: 5,
    paddingLeft: 20,
    paddingRight: 20,
    '&:hover': {
        backgroundColor: alpha(Theme.whiteColor, 0.15),
    },
}));

const StyledForm = styled(FormControl)(() => ({
    
        minWidth: 200,
        marginTop:10,
        marginLeft:'0px',
        "& .MuiOutlinedInput-input": {
            color: Theme.yellowColor,
        },
        "& .MuiInputLabel-root": {
            color: Theme.yellowColor,
            fontSize: 12,
            fontFamily:'Roboto',
        },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: Theme.yellowColor
        },
        "&:hover .MuiOutlinedInput-input": {
            color: Theme.yellowColor,
        },
        "&:hover .MuiInputLabel-root": {
            color: Theme.yellowColor
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: Theme.yellowColor
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
            color: Theme.yellowColor,
        },
        "& .MuiInputLabel-root.Mui-focused": {
            color: Theme.yellowColor
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: Theme.yellowColor
        },
        '& .MuiSelect-icon ':{
            color: Theme.yellowColor
        },
        '& .MuiList-root-MuiMenu-list':{
            backgroundColor:"cyan"
        }
}));

