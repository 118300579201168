import React, {useState} from 'react'
import { useTranslation } from 'react-i18next'
import Theme from '../../config/Theme'
import { Box, IconButton, SvgIcon, TextField  } from '@mui/material'
import { styled, alpha } from '@mui/material/styles'
import icons from '../../assets/icons'
import { useEffect } from 'react'


const ChatForm = ({messageRef, inputEmpty, handleSendMessage}) => {
    const { t } = useTranslation()
    const [newMessage, setNewMessage] = useState('')

    const handleChange = (e) => {
        setNewMessage(e.target.value)
        messageRef.current=e.target.value
    }

    useEffect(() => {
        console.log("input", inputEmpty)
        inputEmpty && setNewMessage('')
    },[inputEmpty])
         
    return (
        <Box sx={{position:"sticky", bottom:0, width:"inherit", display:"flex", alignItems:"center", justifyContent:"space-between"}} backgroundColor="#000">
        <CustomTextfield
            autoFocus
            margin="dense"
            id="newMessage"
            label="Type something here..."
            variant="filled"
            name="newMessage"
            value={newMessage}
            onChange={handleChange}
            autoComplete="off"
        />
        {/* <Box style={{display:"flex", flex:0.1}}> */}
        {/* <Box backgroundColor="cyan"> */}
            <StyledIconButton
                size="medium"
                aria-label="home"
                color="inherit"
                onClick={handleSendMessage}
            >
                <SvgIcon>
                    <path d={icons.ic_baseline_send_24} fill={Theme.yellowColor}/>
                </SvgIcon>
            </StyledIconButton>
        {/* </Box> */}
        </Box>
    )
}

export default ChatForm

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    backgroundColor: Theme.grayDarkColor,
    color: Theme.primaryColor,
    '&:hover': {
        backgroundColor: alpha(Theme.grayDarkColor, 0.75),
    },
}));

const CustomTextfield = styled(TextField)(() => ({
    width:'100%',
    marginRight:10,
    fontSize:13,
    '& .MuiFilledInput-root':{
        backgroundColor:Theme.grayDarkColor,
        borderTopLeftRadius:4,
        borderTopRightRadius:4,
        borderBottom: "1px solid",
        borderBottomColor:Theme.grayColor
    },
    '& .MuiFilledInput-root:hover': {
        backgroundColor:Theme.grayDarkColor,
    },
    '& .MuiFilledInput-root.Mui-focused': {
        backgroundColor:Theme.grayDarkColor,
        borderBottom: "1px solid",
        borderBottomColor:Theme.greenColor
    },
  
    '& label.Mui-focused': {
        color: Theme.grayColor,
    },
    '& .MuiFilledInput-root:after':{
        borderBottom: "1px solid",
        borderBottomColor:Theme.greenColor
    },
    '& .MuiInputBase-input':{
        color: Theme.grayColor,
        fontSize:13,
        backgroundColor:Theme.grayDarkColor,
    },
    '& .MuiInputBase-input:focus':{
        color: Theme.grayColor,
        fontSize:13,
        backgroundColor:Theme.grayDarkColor
    },
    '& label': {
        color: Theme.grayColor,
        fontSize:13,
    },
}));

