import React, { useEffect, useState } from 'react'
import {Container, Grid, Box, Typography, IconButton, Chip, Card, CardMedia, CardContent } from '@mui/material'
import { styled, alpha } from '@mui/material/styles'
import Theme from "../../config/Theme"
import PageLayout from '../../Layout/PageLayout'
import TitlePageBar from '../../Layout/TitlePageBar'
import {getApiRoutes} from '../../config/Routes'
import axios from 'axios'
import DoneIcon from '@mui/icons-material/Done'
import { useNavigate } from 'react-router-dom'
import GoBackButton from '../../components/GoBackButton'
import monthNames from '../../components/monthNames'

const cardImage = require('../../assets/image_placeholder.png')

export default () => {
    const api = getApiRoutes()
    const navigate = useNavigate()
    const [showCheckmark, setShowcheckmark] = useState(false)
    const [articlesSections, setArticlesSections] = useState([])
    const [articles, setArticles] = useState([])
    const [page, setPage] = useState(1)
    const [articlesSectionId, setAriclesSectionId] = useState('')

    // const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    const loadArticlesSections = () => {
        axios({
            method: api.articlesSections.method,
            url: api.articlesSections.path + '?page='+ page,
        }).then((response) => {
            console.log("art=". response)
            setArticlesSections(response.data.data)
        }).catch((error) => {
            console.log(error.message);
        });
    }

    const loadArticles = () => {
        axios({
            method: api.articles.method,
            url: api.articles.path + '?page='+ page,
        }).then((response) => {
            console.log("art2=". response)
            setArticles(response.data.data)
        }).catch((error) => {
            console.log(error.message);
        });
    }

    useEffect(() =>{
        loadArticlesSections()
    },[])

    useEffect(() =>{
        loadArticles()
    },[articlesSectionId])

  
    const renderLeftComponent = () => <GoBackButton/>

    const handleClickChip = id => () => {
        setShowcheckmark(!showCheckmark)
        setAriclesSectionId(id)
        console.log("id", id)
    }

    return (
        <PageLayout bgColor='#000'>
            <TitlePageBar title="News" showLeftComponent={true} leftComponent={renderLeftComponent}/>
            <Box sx={{display:"flex",  flex: 1, flexDirection:"column"}}>
                <Grid container spacing={2} marginTop={2} marginBottom={2}>
                    { articlesSections.map((artS, idx) => {
                        return (
                            showCheckmark ?
                            <Grid item key={idx}>
                                <Chip
                                    key={idx} 
                                    label={artS.name} 
                                    icon={<DoneIcon fontSize="small" color="#fff"/>} 
                                    sx={{backgroundColor: Theme.grayDarkColor, color:"#fff"}}
                                    onClick={handleClickChip(artS.id)}
                                /> 
                            </Grid>
                            :
                            <Grid item key={idx}>
                                <Chip
                                    key={idx} 
                                    label={artS.name} 
                                    sx={{backgroundColor: Theme.grayDarkColor, color:"#fff"}}
                                    onClick={handleClickChip(artS.id)}
                                />
                            </Grid>
                        )
                    })
                }
                    
                </Grid>
                
                <Grid container spacing={2} marginTop={2} marginBottom={2}>
                
                    {
                        articles.map((article,idx) => {
                            return (
                                <Grid item key={idx}>
                                    <Card sx={{display:"flex", backgroundColor:Theme.grayDarkColor, borderRadius: 2}}>
                                        <CardMedia
                                            component="img"
                                            sx={{ width: 151 }}
                                            image={cardImage}
                                            alt="Smile"
                                        />
                                    <CardContent sx={{ flex: '1 0 auto', width:250, display: 'flex', flexDirection: 'column' }}>
                                        <Typography component="div" variant="caption" color={Theme.grayColor} textAlign="right">
                                            {`Published on ${new Date(article.published_at).getDate()} ${monthNames[new Date(article.published_at).getMonth()]}`}
                                        </Typography>
                                        <Typography component="div" variant="subtitle2" color="#fff" marginTop={1}>
                                            {article.title}
                                        </Typography>
                                        <Box sx={{marginTop:"auto"}}>
                                            <Typography component="span" color={Theme.grayColor} fontWeight="bold" variant="caption">
                                                Source
                                                <Typography component="span" variant="caption" color={Theme.grayColor}> {article.source}</Typography>
                                            </Typography>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>
                            )}

                        )
                    }
               
                </Grid>

            </Box>
        </PageLayout>
    )
}

