import React, { useEffect, useState, useRef, useContext } from 'react'
import { Grid, Box, Typography, SvgIcon, IconButton, Avatar, Stack, Alert, AlertTitle  } from '@mui/material'
import { styled, alpha } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import Theme from '../../config/Theme'
import {getApiRoutes} from '../../config/Routes'
import { AuthContext } from '../../redux/contexts/AuthContext'
import { useLocation } from 'react-router-dom'
import PageLayout from '../../Layout/PageLayout'
import TitlePageBar from '../../Layout/TitlePageBar'
import GoBackButton from '../../components/GoBackButton'
import icons from '../../assets/icons'
import axios from 'axios'
import ConfirmDialog from '../../components/ConfirmDialog'
import ChatContent from './ChatContent'
import ChatForm from './ChatForm'

import { isEqual, isEmpty, isUndefined, capitalize } from 'lodash'


const Chat = ({navigation}) => {
    const { t } = useTranslation()
    const api = getApiRoutes()
    const location= useLocation()
    const {token} = useContext(AuthContext)
    const  userToken = token || localStorage.getItem('token')
    const { user, from } = location?.state
    const conversation = location?.state.id
    const [messages, setMessages] = useState([])
    const [maxPage, setMaxPage] = useState(5)
    const [page, setPage] = useState(1)
    const [showConfirmDialog, setShowConfirmDialog] = useState(false)
    const messageRef = useRef()
    const [inputEmpty, setInputEmpty] = useState(false)
    const [conversations, setConversations] = useState([])
      
    const titleHeader = isEqual(from, 'home') ? 'Comments' : `@${user.username}`
    

    const loadConversations = () => {
        axios({
            method: api.threads.method,
            url: api.threads.path + '?user=' + user.id
        }).then(response => {
            setConversations(response.data.data)
        })
    }

    useEffect(() => {
        if (!isEmpty(user) && from ==='home') loadConversations()
    },[user])

    const loadMessages = () => {
        axios.defaults.headers["Authorization"] = `Bearer ${userToken}`
        if (from === 'conversation') {
            axios({
                method: api.threads.method,
                url: api.threads.path + `/${conversation}/messages?page=` + page
            }).then(response => {
                setMaxPage(parseInt(response.data.meta.last_page))
                setPage(page + 1) 
                setMessages(prev => [...prev, ...response.data.data])
            })
        }
        else if (from === 'profile' || from === 'home') {
            axios({
                method: api.threads.method,
                url: api.threads.path + '?user=' + user.id
            }).then(response => {
                const usersConversations = isEqual(from,'profile') ? response.data.data : conversations
                const userConversation = usersConversations.find(conversation => isEqual(conversation.user.id, user.id))
                axios({
                    method: api.threads.method,
                    url: api.threads.path + `/${userConversation.id}/messages`
                }).then(response => {
                    setMessages(response.data.data)
                })
                
            })      
        }
      
    }
   
    useEffect(()=>{
        if(page<=maxPage) loadMessages()
    },[page])

    useEffect(()=>{
        inputEmpty && loadMessages()
    },[inputEmpty])


    const toggleConfirmDialog = () => {
        setShowConfirmDialog(!showConfirmDialog)
    }

    const renderLeftComponent = () => <GoBackButton/>

    const renderRightComponent = () => {
        return(
            <StyledIconButton
                size="medium"
                aria-label="blockUser"
                color="inherit"
                onClick={toggleConfirmDialog}
            >
                <SvgIcon>
                    <path d={icons.ic_exclamtion} fill={Theme.grayColor}/>
                </SvgIcon>
            </StyledIconButton>
        )
    }

    const handleOk = () => {
        console.log("to do blockUser")
        toggleConfirmDialog()
    }

    const [responseMsg, setResponseMsg] = useState('')

    const returnAlert = (response) => {
        return(
            <Stack sx={{ width: '100%' }} spacing={1}>
                <Alert severity="success" color="info" onClose={()=>setResponseMsg('')}>
                    <strong>{capitalize(response)}</strong>
                </Alert>
            </Stack>
        )
    }
    

    const handleSendMessage = () => {
        if (isEmpty(messageRef.current)) return
        setInputEmpty(true)      
        axios({
            method: 'post',
            url: api.threads.path + `/${conversation}/messages`,
            data: {
                body: messageRef.current
            }
        }).then(function (response) {
            
            if (response.data ==='this user is not following you' || response.data ==='you are not following this user') setResponseMsg(response.data)
            else {
                setPage(1)
                setMessages([])
            }
            
                
        }).catch(function (error) {
            console.log(error)
        })
       

    }

    const renderContentConfirmDialog = () => {
        return (
            <Box margin={3} textAlign="center">
                <Typography color="#fff" sx={{fontSize: 16}}>{t("Do you want to block this user? None of you will be able to send messages.")}</Typography>
            </Box>
        )
    }

    return (
        <PageLayout bgColor='#000'>
            <ConfirmDialog {...{showConfirmDialog, toggleConfirmDialog, renderContentConfirmDialog, handleOk}} />
            <TitlePageBar title={titleHeader} showLeftComponent={true} leftComponent={renderLeftComponent} showRightComponent={true} rightComponent={renderRightComponent}/>
            
            <Box sx={{display:"flex", flex: 1, flexDirection:"column"}}>
                {!isEmpty(responseMsg) && returnAlert(responseMsg)}
                <ChatContent {...{messages}}/>
                <ChatForm {...{messageRef, inputEmpty,handleSendMessage}}/>
            </Box>
            
        </PageLayout>
    )
}

export default Chat

const StyledIconButton = styled(IconButton)(() => ({
    '&:hover': {
        backgroundColor: alpha(Theme.whiteColor, 0.15),
    },
}));

const BorderAvatar = styled(Avatar)(() => ({
    border:"3px solid",
    borderColor: Theme.yellowColor,
    marginRight: 10,
    '& img': {
        borderRadius:'50%'
    }
}));

const StyledGrid = styled(Grid)(() => ({
    paddingLeft:16,
    paddingRight:16,
    '&:hover': {
        cursor:"pointer",
        borderRadius: 4,
        backgroundColor: alpha(Theme.whiteColor, 0.15),
    },
}));

