import React, { useEffect, useState, useContext, useRef, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Box, SvgIcon, Typography, TextField, Link, Button, InputAdornment, Switch, MenuItem, FormControl, Select } from '@mui/material'
import { styled, alpha } from '@mui/material/styles'
import Theme from '../config/Theme'
import icons from '../assets/icons'
import PageLayout from '../Layout/PageLayout'
import TitlePageBar from '../Layout/TitlePageBar'
import {getApiRoutes} from '../config/Routes'
import axios from 'axios'
import GoBackButton from '../components/GoBackButton'
import { useLocation } from 'react-router-dom'
import {isEmpty, isEqual, isUndefined} from 'lodash'
import { AuthContext } from '../redux/contexts/AuthContext'
import Songs from './Songs'
import ConfirmDialog from '../components/ConfirmDialog'

const languages =[{name:"English", value:"eng"},{name:"Romanian", value:"ro"}]

export default () => {
    const { t } = useTranslation()
    const location = useLocation()
    const api = getApiRoutes()
    const { token } = useContext(AuthContext)
    const  userToken = token || localStorage.getItem('token')
    const formRef = useRef()
    const [loading, setLoading] = useState(false)
    const chosenSong = location?.state?.chosenSong || {}
    const [video, setVideo] = useState(null)
    const [videoSrc, setVideoSrc] = useState('')
    const inputVideoRef = useRef(new MediaSource())
    const videoRef = useRef(new MediaSource())
    const [errorsMessage, setErrorsMessage] = useState('')
    const [videoLocation, setVideoLocation] = useState('')
    const [description, setDescription] = useState('')
    const [language, setLanguage] = useState(languages[0].value)
    const [isPrivate, setIsPrivate] = useState(false)
    const [comments, setComments] = useState(true)
    const [duet, setDuet] = useState(true)
    const [showConfirmDialog, setShowConfirmDialog] = useState(false)

        
    const renderLeftComponent = () => <GoBackButton/>

    

    const URL = window.URL || window.webkitURL
    const handleUploadVideo = e => { //attachedFile
        setVideo(e.target.files[0])
        setVideoSrc(e.target.files[0])
        const file = e.target.files[0]
        const urlBlob = URL.createObjectURL(file)
        setVideoSrc(urlBlob)
    }

  
    const handleSwitch = e => {
        const name = e.target.name
        isEqual(name,"isPrivate") ? setIsPrivate(e.target.checked) : isEqual(name,"comments") ? setComments(e.target.checked) : setDuet(e.target.checked)
    }

    const toggleConfirmDialog = () => {
        setShowConfirmDialog(!showConfirmDialog)
    }

    const handleOk = () => {
        toggleConfirmDialog()
    }

    const renderContentConfirmDialog = () => {
        return (
            <Box margin={3} textAlign="center">
                <Typography color="#fff" sx={{fontSize: 16}}>{t("No video selected")}</Typography>
            </Box>
        )
    }
   
    if (isUndefined(FormData.prototype.toObject)) {
        FormData.prototype.toObject = function () {
            const obj = {}
    
            for (var pair of this.entries()) {
                let key = pair[0]
                if (key.includes('true_flag_')) {
                    key = key.replace('true_flag_', '')
                    obj[key] = 1
                } else {
                    obj[key] = pair[1]
                }
            }
    
            return obj
        }
    }

    function resetForm() {
        setVideo(null)
        setVideoSrc('')
        setDescription('')
        setLanguage(languages[0].value)
        setIsPrivate(false)
        setComments(true)
        setComments(true)
        setDuet(true)
    }

    const handleFormSubmit = e => {
        e?.preventDefault()
        if (loading) return


        if (!video) {
            toggleConfirmDialog()
            return
        }

        setLoading(true)
        const formData = new FormData(formRef.current)
        console.log(video)
        formData.append('video', video)
        
        video && formData.append('duration', Math.round(videoRef.current?.duration))
        !isEmpty(chosenSong) && formData.append('song', chosenSong.id)
        !isEmpty(description) && formData.append('description', description)
        formData.append('language', language) //language to detect add etc
        formData.append('private', isEqual(isPrivate, true) ? 1 : 0)
        formData.append('comments', isEqual(comments, true) ? 1 : 0)
        formData.append('duet', isEqual(duet, true) ? 1 : 0)
       
        console.log('formData', formData.toObject())

        axios({
            method: 'post',
            headers: {
                "Content-Type": "multipart/form-data",
                'Accept': '*/*'
            },
            url: api.clips.path,
            // body: formData.toObject(),
            data:formData,
        }).then(function (response) {
            console.log("response",response)
            alert('Video was sent to the server, please wait for the approval!')
            setLoading(false)
            resetForm()
        }).catch(function (error) {
            console.log(error)
        })
    }
       

    return (
        <PageLayout bgColor='#000'>
            <TitlePageBar title="Record" showLeftComponent={true} leftComponent={renderLeftComponent}/>

            <Grid container maxWidth="lg" sx={{display:"flex",  flex: 1, margin:'auto'}}>
                <Grid item xs={12} md={6}>
                    <ConfirmDialog {...{showConfirmDialog, toggleConfirmDialog, renderContentConfirmDialog, handleOk}} />
                    <form id="submit_form_layout" ref={formRef}>
                        <Box sx={{border: "1px solid #cccccc",borderRadius:1, display:"flex", flexDirection:"column"}}>
                            <Box sx={{display:"flex", flexWrap:"wrap"}}>
                                <input
                                    ref={inputVideoRef}
                                    style={{ display: 'none' }}
                                    id="upload-vieo"
                                    // name="video"
                                    type="file"
                                    accept="video/*"
                                    onChange={handleUploadVideo}
                                />
                                <label htmlFor="upload-vieo" style={{width:"100%"}}>
                                    <UploadButton size="large" aria-label="record" component="span"
                                        startIcon={ <SvgIcon><path d={icons.ic_upload_recorder} fill="#000000"/></SvgIcon> }
                                    >
                                        Upload video
                                    </UploadButton>
                                </label>
                            </Box>

                            { !isEmpty(videoSrc) && 
                            <Fragment>
                                <Box sx={{padding:2, textAlign:"center"}}>
                                    <video
                                        id="video"
                                        style= {{width:'auto', maxWidth:'100%', maxHeight:200, aspectRatio: 16/9 }}
                                        ref={videoRef}
                                        src={videoSrc}
                                        type="video/mp4"
                                        controls
                                    ></video>
                                    <Box>
                                        <Typography variant="caption" color="#fff" fontWeight={300}>{video.name}</Typography>
                                    </Box>
                                    </Box>    
                            </Fragment>
                            }    
                            <Box sx={{padding:2}}>
                                <CustomTextfield
                                    margin="dense"
                                    id="description"
                                    label="Type something here..."
                                    variant="filled"
                                    error={!!errorsMessage?.description}
                                    helperText={errorsMessage?.description && t("errorDescription")}
                                    name="description"
                                    multiline
                                    rows={2}
                                    defaultValue={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    autoComplete="off"
                                />    
                                <CustomTextfield
                                    margin="dense"
                                    id="location"
                                    label="Location"
                                    // placeholder="Location"
                                    variant="filled"
                                    error={!!errorsMessage?.location}
                                    helperText={errorsMessage?.location && t("errorLocation")}
                                    name="location"
                                    defaultValue={videoLocation}
                                    onChange={(e) => setVideoLocation(e.target.value)}
                                    autoComplete="off"
                                    InputProps={{
                                        startAdornment: (
                                        <InputAdornment position="start">
                                            <SvgIcon>
                                                <path d={icons.ic_baseline_my_location_24} fill={Theme.grayColor}/>
                                            </SvgIcon>
                                        </InputAdornment>
                                        ),
                                    }}
                                />
                                <Box sx={{display:"flex", justifyContent:"space-between", alignItems:"center", marginY:2}}>
                                    <Typography variant="caption" color="#fff" fontWeight={300}>Content language</Typography>
                                    <StyledForm size="small">
                                        <Select
                                            variant="filled"
                                            labelId="language"
                                            id="language"
                                            value={language}
                                            label="Language"
                                            onChange={(e) => setLanguage(e.target.value)}
                                            style={{color:"#fff", fontSize:12}}
                                        >
                                            {languages.map(language => {
                                                return (
                                                    <MenuItem key={language.name} value={language.value}>{language.name}</MenuItem>
                                                )
                                            })
                                            }
                                            
                                            
                                        </Select>
                                    </StyledForm>
                                </Box>
                                <Box sx={{display:"flex", justifyContent:"space-between", marginBottom:2}}>
                                    <Typography variant="caption" color="#fff" fontWeight={300}>Make this clip private?</Typography>
                                    <CustomSwitch
                                        checked={isEqual(isPrivate,true) ? true : false}
                                        name="isPrivate"
                                        value={isPrivate}
                                        onChange={handleSwitch}
                                    />
                                </Box>
                                <Box sx={{display:"flex", justifyContent:"space-between", marginBottom:2}}>
                                    <Typography variant="caption" color="#fff" fontWeight={300}>Enable comments on this clip?</Typography>
                                    <CustomSwitch
                                        checked={isEqual(comments,true) ? true : false}
                                        name="comments"
                                        value={comments}
                                        onChange={handleSwitch}
                                    />
                                </Box>
                                <Box sx={{display:"flex", justifyContent:"space-between", marginBottom:2}}>
                                    <Typography variant="caption" color="#fff" fontWeight={300}>Allow people to record duet?</Typography>
                                    <CustomSwitch
                                        checked={isEqual(duet,true) ? true : false}
                                        name="duet"
                                        value={duet}
                                        onChange={handleSwitch}
                                    />
                                </Box>
                                
                            </Box>   
                            
                            <StyledButton 
                                type="submit"
                                size="large" 
                                aria-label="record" 
                                startIcon={ <SvgIcon><path d={icons.ic_selected} fill="#ffffff"/></SvgIcon> }
                                onClick={handleFormSubmit}
                                disabled={loading}
                            >
                                Save
                            </StyledButton>
                        </Box>
                        </form>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Box sx={{display:"flex", justifyContent:"center", marginY:{xs:2, md:0} }}>
                            <SvgIcon>
                                <path d={icons.ic_baseline_music_note_24} fill="#fff"/>
                            </SvgIcon>
                            <Typography color="#fff">{!isEmpty(chosenSong) ? (location.state.chosenSong.title) : 'Choose a song'}</Typography>
                        </Box>
                        
                        <Songs/>
                    </Grid>
            </Grid>
        </PageLayout>
    )
}

const UploadButton = styled(Button)(() => ({
    width:"100%",
    color:Theme.primaryColor,
    backgroundColor:Theme.yellowColor,
    borderRadius: "0px 0px 0px 0px",
    '&:hover': {
        backgroundColor: alpha(Theme.yellowColor, 0.75),
    },
}));

const StyledButton = styled(Button)(() => ({
    width:"100%",
    color:Theme.whiteColor,
    backgroundColor:"transparent",
    marginTop:"auto",
    borderRadius: "0px 0px 0px 0px",
    '&:hover': {
        backgroundColor: alpha(Theme.whiteColor, 0.15),
    },
    '&:disabled': {
        backgroundColor: Theme.grayColor,
        color:Theme.whiteColor,
    },
}));

const CustomTextfield = styled(TextField)(() => ({
    width: '100%',
    fontSize:13,
    
    '& .MuiFilledInput-root':{
        backgroundColor:Theme.grayDarkColor,
        borderTopLeftRadius:4,
        borderTopRightRadius:4,
        borderBottom: "1px solid",
        borderBottomColor:Theme.grayColor
        
    },
    '& .MuiFilledInput-root:hover': {
        backgroundColor:Theme.grayDarkColor,
    },
    '& .MuiFilledInput-root.Mui-focused': {
        backgroundColor:Theme.grayDarkColor,
        borderBottom: "0px",
    },
  
    '& label.Mui-focused': {
        color: Theme.yellowColor,
    },
    '& .MuiFilledInput-root:after':{
        borderBottom: "1px solid",
        borderBottomColor:"#fdf800"
    },
    '& .MuiInputBase-input':{
        color: Theme.whiteColor,
        fontSize:13,
        backgroundColor:Theme.grayDarkColor,
        borderTopLeftRadius:4,
        borderTopRightRadius:4,
    },
    '& .MuiInputBase-input:focus':{
        color: Theme.whiteColor,
        fontSize:13,
        backgroundColor:Theme.grayDarkColor
    },
    '& label': {
        color: Theme.grayColor,
        fontSize:13,
    },
}));

const StyledForm = styled(FormControl)(() => ({
    minWidth: 200,
   
    '& .MuiFilledInput-root.Mui-focused': {
        borderBottom: "0px",
    },
    '& .MuiFilledInput-root:after':{
        borderBottom: "1px solid",
        borderBottomColor:"#fdf800"
    },
    "& .MuiInputLabel-root": {
        color: Theme.whiteColor,
        fontSize: 12,
        fontFamily:'Roboto',
    },
   
    '& .MuiSelect-icon ':{
        color: Theme.whiteColor
    },
}));

const CustomSwitch = styled(Switch)(() => ({
  
    '& .MuiSwitch-switchBase': {
        color: Theme.grayDarkColor,
        '&$checked': {
            color: Theme.yellowColor,
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: Theme.greenColor,
    },
        '& .MuiSwitch-switchBase.Mui-checked+ .MuiSwitch-track': {
        backgroundColor: Theme.greenColor,
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        border: 0,
        backgroundColor: Theme.grayColor,
        '&$checked': {
            color: Theme.greenColor,
        },
    },
    
   
}));
