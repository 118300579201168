import React, { useState, useEffect, useContext } from 'react'
import {Grid, Box, Typography, Link, Button, SvgIcon, Alert} from '@mui/material'
import { styled, alpha } from '@mui/material/styles'
import Theme from '../config/Theme'
import PageLayout from '../Layout/PageLayout'
import TitlePageBar from '../Layout/TitlePageBar'
import GoBackButton from '../components/GoBackButton'
import {getApiRoutes} from '../config/Routes'
import axios from 'axios'
import { isEmpty } from 'lodash'
import StarsIcon from '@mui/icons-material/Stars'
import icons from '../assets/icons'
import { useLocation, useNavigate } from 'react-router-dom'
import { AuthContext } from '../redux/contexts/AuthContext'

const Gifts = ({ }) => {
    const location= useLocation()
    const navigate = useNavigate()
    const { token } = useContext(AuthContext)
    const  userToken = token || localStorage.getItem('token')
    const api = getApiRoutes()
    const [gifts, setGifts] = useState([])
    const [creditsGift, setCreditsGift]= useState(0)
    const [chosedGift, setChosedGift] = useState()
    const userId = location?.state?.user.id
    

    const renderLeftComponent = () => <GoBackButton/>

    const loadGifts = () => {
        axios.defaults.headers["Authorization"] = `Bearer ${userToken}`
        axios({
            method: api.items.method,
            url: api.items.path
        }).then(response => {
            setGifts(response.data.data)
        }).catch((error) => {
            console.log(error.message);
        });
    }

    useEffect(() => {
        loadGifts();
    }, []);

    const handleChooseGift = gift => () => {
        setChosedGift(gift)
        setCreditsGift(gift.price) /*valoarea din db acesta este un ex. */
        window.scrollTo(0, 0)
    }

    const handleSendGift = () => {
        
        if(!chosedGift) 
            return alert('First chose a gift!')
            
        axios({
            method: api.wallet.gift.method,
            url: api.wallet.gift.path,
            data: {
                to: userId,
                items: [
                    {
                        id: chosedGift.id,
                        quantity: 1
                    }
                ]
            }
        }).then(res => {
            console.log("res",res)
            alert(`${chosedGift.name} a fost trimis cu succes`)
            // showBoughtGift(chosedGift)
            navigate('/',{state:{'gift':chosedGift}})

        }).catch(err => {
            // console.log(err.response.data)
            err.response.data.errors.items.forEach(item => {
                alert(item)
            })
        })
        // navigate('/',{state:{'gift':chosedGift}})
    }
    

    return (
        <PageLayout bgColor='#000'>
            <TitlePageBar title="Gifts" showLeftComponent={true} leftComponent={renderLeftComponent}/>
            <Box sx={{display:"flex",  flex: 1, flexDirection:"column"}}>
                <Grid container marginTop={3}>
                    <Grid item xs={12} marginBottom={3} sx={{display:"flex", alignItems:"center"}}>
                        <Typography variant="body1" color={Theme.whiteColor} fontWeight="bold">Total
                            <Typography component="span" variant="body1" color={Theme.whiteColor}>
                                {` ` + creditsGift} credits
                            </Typography>
                        </Typography>
                        <StyledButton 
                            variant="contained" 
                            size="small"
                            endIcon={
                                <SvgIcon>
                                    <path d={icons.ic_baseline_send_24} fill={Theme.blackColor}/>
                                </SvgIcon> 
                            }
                            onClick={handleSendGift}
                        >
                            Send
                        </StyledButton>
                    </Grid>

                    { isEmpty(gifts) ?
                        <Grid item>
                            <Typography variant="caption" color={Theme.grayColor}>No gifts found to display</Typography>
                        </Grid>
                        :
                        gifts.map((gift,idx) => {
                            return (
                                <Link component="button" sx={{textDecoration:"none"}} onClick={handleChooseGift(gift)}>
                                <StyledGrid item key={idx} sx={{display:"flex", flexDirection:"column"}}>
                                    <img src={gift.image} style={{width:150, height:150, borderRadius:10}} alt={gift.name}/>
                                    <Box sx={{display:"flex", justifyContent:"center"}}>
                                        <StarsIcon sx={{color:Theme.yellowColor, fontSize:20, marginRight: 1}}/>
                                        <Typography variant="caption" color={Theme.whiteColor}>{gift.price}</Typography>
                                    </Box>
                                </StyledGrid>
                                </Link>
                            )
                        })
                    }
                </Grid>
            </Box>
        </PageLayout>
    )
}

export default Gifts


const StyledGrid = styled(Grid)(() => ({
    borderRadius: 4,
    padding: 10,
    '&:hover': {
        borderRadius: 4,
        backgroundColor: alpha(Theme.whiteColor, 0.15),
    },
}));

const StyledButton = styled(Button)(() => ({
    paddingLeft:20, 
    paddingRight:20,
    backgroundColor: Theme.yellowColor,
    color: Theme.primaryColor,
    marginLeft: 10,
    '&:hover': {
        backgroundColor: alpha(Theme.yellowColor, 0.75),
    },
}));