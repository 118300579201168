import React, { useState, useEffect } from 'react'
import {Container, Box, Typography, Grid, SvgIcon, Link} from '@mui/material'
import { useTranslation } from 'react-i18next'
import Theme from "../../config/Theme"
import axios from 'axios'
import {getApiRoutes} from '../../config/Routes'
import icons from '../../assets/icons'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import PageLayout from '../../Layout/PageLayout'
import TitlePageBar from '../../Layout/TitlePageBar'
import GoBackButton from '../../components/GoBackButton'
import {isEmpty} from 'lodash'


export default () => {
    const api = getApiRoutes()
    const location = useLocation()
    const {section_id} = useParams()
    const [section, setSection] = useState(location?.state)
    const [clips, setClips] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [perPage, setPerPage] = useState(15)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    
    const loadClips = (currentPage) => {
        setLoading(true)
        axios({
            method: api.clips.method,
            url: api.clips.path + '?sections[]='+ section_id + '&page=' + currentPage,
        }).then((response) => {
            setClips(prev => [...prev, ...response.data.data])
            const lastPage = response.data.meta.last_page
            if(currentPage < lastPage) {
                setCurrentPage(currentPage + 1)
        }
            
        }).catch((error) => {
            console.log(error.message);
        });
        setLoading(false)   
    }
   
    const handleClickClip = (id) => () => {
        navigate('/',{state:{'videoId':id}}) /*send clip id */
    }

    useEffect(() => {
        loadClips(currentPage)
    },[currentPage])
   

    const renderLeftComponent = () => <GoBackButton/>

    return (
        <PageLayout bgColor='#000'>
            <TitlePageBar  title={section} showLeftComponent={true} leftComponent={renderLeftComponent}/>
            <Box sx={{display:"flex",  flex: 1, flexDirection:"column"}}>
                <Grid container maxWidth="md" spacing={2} width={'100%'} marginLeft={0}>
                    { isEmpty(clips) ?
                        <Typography variant="caption" color={Theme.whiteColor} fontWeight={300} marginLeft={1}>Loading</Typography>
                    :
                        clips.map((clip, index) => {
                            return(
                                <Grid item key={index} >
                                    <Link key={clip.id} component="button" sx={{textDecoration:"none"}} onClick={handleClickClip(clip.id)}>
                                    <Box sx={{display:"flex", width:150, height:150}}>
                                        <img src={clip.preview} style={{width:150, height:150, borderRadius:10}} alt={clip.id}/>
                                        <Box sx={{position:"absolute", display:"flex", alignSelf:"flex-end" }} marginBottom={1} paddingLeft={1}>
                                            <SvgIcon fontSize="small">
                                                <path d={icons.ic_baseline_favorite_border_24} fill="#fff"/>
                                            </SvgIcon>
                                            <Typography variant="caption" color={Theme.whiteColor} fontWeight={300} marginLeft={1}>{clip.likes_count}</Typography>
                                        </Box>
                                    </Box>
                                    </Link>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </Box>
        </PageLayout>
    )
}
