import React, { Fragment } from 'react'
import {Grid, Box, Typography, Divider, Avatar, IconButton } from '@mui/material'
import { styled, alpha } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import Theme from '../../config/Theme'
import { isEmpty, toLower } from 'lodash'
import monthNames from '../../components/monthNames'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import {getApiRoutes} from '../../config/Routes'
import axios from 'axios'


export default ({notifications}) => {
    const api = getApiRoutes()
    const { t } = useTranslation()

    const getType = (type) => {
        switch (type) {
            case 'clip_approved':
                return "Uploaded clip is now approved."
            case 'commented_on_your_clip':
                return "Commented on your clip."
            case 'download_description':
                return "Should not taker longer than a few seconds."
            case "download_title":
                return "Downloading your clip"
            case "else":
                return "This notification content is not supported, yet."
            case "liked_your_clip":
                return "Liked your clip."
            case "mentioned_you_in_comment":
                return "Mentioned you in a comment."
            case "posted_new_clip":
                return "Posted a new clip."
            case "preview_description":
                return "Should not taker longer than a few seconds."
            case "preview_title":
                return "Previewing your clip"
            case "save_description":
                return "The videoclip has been successfully saved."
            case "save_title":
                return "Saved to gallery"
            case "started_following_you":
                return "has started following you."
            case "tagged_you_in_clip":
                return "Tagged you in a clip."
            case "upload_description":
                return "Should not taker longer than a few seconds."
            case "upload_failed_description":
                return "The video has to been saved to drafts."
            case "upload_failed_title":
                return "Upload failed"
            case "watermark_description":
                return "Should not taker longer than a few seconds."
            case "watermark_title":
                return "Optimizing clip..."
        }
    }

    const handleDelete = (notification) => () => {
        // axios.delete('https://stage.smilelive.app/api/notifications/' + notification.id)
        axios({
            method: 'delete',
            url: api.notifications.path + '/notification.id',
        })
        .then(response => {
            console.log(response)
        }).catch((error) => {
            console.log(error.message);
        });
    }

    return(
        isEmpty(notifications) ?
        <Box sx={{display:"flex", flex:1, justifyContent:"center", alignItems:'center'}} color="#fff" textAlign="center">
            No received notifications
        </Box>
        :
        <Box>
            { notifications.map((notification,idx) => {
                return (
                    <Fragment key={idx}>
                        <Grid container maxWidth="sm" sx={{display:"flex", alignItems:"center"}}>
                            <Grid item xs={12} sx={{display:"flex", alignItems:"center"}}>
                                <BorderAvatar alt="user.name" src={notification.user.photo} />
                                <Box>
                                <Typography component="span" color="#fff">{`@${notification.user.username + ' ' + getType(notification.type)}`}
                                    <Box>
                                        <Typography component="span" color={Theme.grayColor} sx={{fontSize: 12}}>{`  pe ${new Date(notification.created_at).getDate() + ' ' + toLower(t(monthNames[new Date(notification.created_at).getMonth()]))}`}</Typography>
                                            <StyledIconButton
                                                size="small"
                                                aria-label="delete"
                                                color="inherit"
                                                onClick={handleDelete(notification)}
                                            >
                                                <DeleteForeverIcon size="small" sx={{color:"#fff", fontSize:'20px'}}/>
                                            </StyledIconButton>
                                    </Box>
                                </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        <Divider sx={{borderColor:Theme.grayDarkColor, marginTop: 2, marginBottom: 2}}/>
                    
                </Fragment>
                )
            })
        }
        </Box>
        
    )
}

const BorderAvatar = styled(Avatar)(() => ({
    border:"2px solid",
    borderColor: Theme.yellowColor,
    marginRight: 10,
    '& img': {
        borderRadius:'50%'
    }
}));

const StyledIconButton = styled(IconButton)(() => ({
    '&:hover': {
        backgroundColor: alpha(Theme.whiteColor, 0.15),
    },
}));