import React, { useState, useRef } from 'react'
import { Box } from '@mui/material'
import RightSideBar from './partials/RightSideBar'
import VideoFooter from './partials/VideoFooter'


const Video =  ({type = 'video', data, id}) => {
	const [playing, setPlaying] = useState(false)
	const videoRef = useRef(null)

  
	const onVideoPress = () => {
		// console.log('onVideoPress', videoRef.current)
		document.querySelectorAll('video').forEach(vid => vid.pause())
		if (playing) {
			videoRef.current.pause()
			setPlaying(false)
		} else {
			videoRef.current.play()
			setPlaying(true)
		}
	}

	const handleVideoEnded = e => {
		if(videoRef.current)
		{
			const nextPlayVideoId = parseInt(e.target.id) + 1
			document.querySelectorAll('video').forEach(vid => vid.pause())
			document.getElementById(nextPlayVideoId).play()
		}
	}
  	

  	return (
		<Box className={type} key={data.id} sx={{borderRadius: 1, boxShadow: "1px 1px 10px 2px rgb(0 0 0 / 14%)", overflow: "hidden", zIndex: 1}}>
			
			<video key={id} id={id} name={'video_'+id} className="video__player" onClick={onVideoPress} onEnded={handleVideoEnded} ref={videoRef} src={data.video} crossOrigin="anonymous"></video>
		
			<RightSideBar {...{data}}/>

			<VideoFooter {...{data}}/>      
		
		</Box>
  	)
}

export default Video