import React, { Fragment } from 'react'
import { Typography, Divider, List, ListItemButton , ListItemText, ListItemAvatar, Avatar } from '@mui/material'
import { styled } from '@mui/material/styles'
import StarsIcon from '@mui/icons-material/Stars'
import Theme from "../../config/Theme"
import { useNavigate } from 'react-router-dom'


export default ({users}) => {
    const navigate = useNavigate()

    const handleClick = (user) => () => {
        navigate('/profile/' + user.id)
    }

    return (
        <List>
        {
            users.map((user,idx) => {
                return (
                    <Fragment key={idx}>
                        <ListItemButton key={idx} alignItems="flex-start" onClick={handleClick(user)}>
                            <ListItemAvatar>
                                <BorderAvatar alt="user.name" src={user.photo} />
                            </ListItemAvatar>
                            <ListItemText 
                                primaryTypographyProps={{
                                    fontSize: 14,
                                    fontWeight: 'bold',
                                    letterSpacing: 0,
                                    color:"#fff"
                                }}
                                primary={user.name}
                                secondary={
                                    <Fragment>
                                        <Typography
                                        sx={{ display: 'inline' }}
                                        component="span"
                                        variant="caption"
                                        color={Theme.grayColor}
                                        >
                                        { (user.views_count>=1000) ? (user.views_count/1000).toFixed(1) +`k `: user.views_count} - @{user.username}
                                    </Typography>
                                    <StarsIcon sx={{color:Theme.greenColor, fontSize:20, marginLeft: 2}}/>
                                    </Fragment>
                                }
                            />
                        </ListItemButton>
                                                
                        <Divider variant="inset" component="li" sx={{borderColor:Theme.grayDarkColor}}/>
                    </Fragment> 
                )
            })
        }
        </List>
    )
}

const BorderAvatar = styled(Avatar)(({ theme }) => ({
    border:"3px solid",
    borderColor: Theme.yellowColor,
    '& img': {
        borderRadius:'50%'
    }
}));