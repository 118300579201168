import React, { useState, useContext, useEffect } from 'react'
import { Box, Typography, SvgIcon, IconButton, CircularProgress  } from '@mui/material'
import { styled, alpha, createTheme, ThemeProvider } from '@mui/material/styles'
import Theme from '../../../config/Theme'
import icons from "../../../assets/icons"
import Login from '../../auth/Login'
import { useNavigate  } from 'react-router-dom'
import ShareDialog from '../../../components/ShareDialog'
import { AuthContext } from '../../../redux/contexts/AuthContext'
import {getApiRoutes} from '../../../config/Routes'
import axios from 'axios'
import {isNull} from 'lodash'


const theme = createTheme()

const RightSideBar = ({ data }) => {
    const api = getApiRoutes()
    const navigate = useNavigate()
    const { isLoggedIn } = useContext(AuthContext)
    const [showLogin, setShowLogin] = useState(false)
    const [showShareDialog, setShowShareDialog] = useState(false)
       
    const toggleLogin = () => {
        setShowLogin(!showLogin)
    }

    const handleClickComments = () => {
        navigate('/chat', {state: {'from':'home', 'user': data?.user} })
    }
    const handleLikes = () => {
        axios({
            method: 'post',
            url: api.clips.path + '/' + data.id + '/likes',
        }).then(function(response) {
            console.log("response",response)
        }).catch(function(error){
            console.log(error)
        })
    }
    const handleBookMark = () => {
        axios({
            method: 'post',
            url: api.clips.path + '/' + data.id + '/saves',
        }).then(function(response) {
            console.log("response",response)
        }).catch(function(error){
            console.log(error)
        })
    }
    
    const handleClickGifts = () => {
        navigate('/gifts', {state:data})
    }

    const toggleShareDialog = () => {
        setShowShareDialog(!showShareDialog)
    }
    
    const [progress, setProgress] = useState(0)
    
    const CustomCircularProgress = ({value,...props}) => {
        return (
            <Box sx={{ position: 'relative' }}>
                <CircularProgress
                    variant="determinate"
                    sx={{ color: 'rgb(0 0 0 / 12%)'}}
                    size={30}
                    thickness={4}
                    {...props}
                    value={100}
                />
                <CircularProgress
                    variant="indeterminate"
                    disableShrink
                    sx={{
                        color: "#fff",
                        animationDuration: '550ms',
                        position: 'absolute',
                        left: 0,
                    }}
                    size={30}
                    thickness={4}
                    {...props}
                    value={value}
                />
        </Box>
        )
    }

    return (
        <ThemeProvider theme={theme}>
            <Login {...{showLogin, toggleLogin}}/>
            <ShareDialog {...{showShareDialog, toggleShareDialog, data}}/>
            <Box className="videoSidebar">
                <CustomCircularProgress value={data.duration}/>
                <Typography variant="body1" color ="#fff" className="textShadow">{data.duration}</Typography>
                <Box className="videoSidebar__button">
                    <StyledIconButton color="inherit" size="large">
                        <SvgIcon className="svgIconShadow" fontSize="large">
                            <path d={icons.ic_baseline_remove_red_eye_24} fill="#fff" />
                        </SvgIcon>
                    </StyledIconButton>
                    <Typography variant="body1" color ="#fff" className="textShadow">{data.views_count}</Typography>
                </Box>

                <Box className="videoSidebar__button">
                    <StyledIconButton color="inherit" size="large" onClick={handleLikes}>
                        <SvgIcon className="svgIconShadow" fontSize="large">
                            {   data.liked ? 
                                <path d={icons.ic_baseline_favorite_24} fill="#FE2B54" /> 
                                : 
                                <path d={icons.ic_baseline_favorite_border_24} fill="#fff" /> 
                            }
                        </SvgIcon>
                    </StyledIconButton>
                    <Typography variant="body1" color ="#fff" className="textShadow">{data.likes_count}</Typography>
                </Box>

                <Box className="videoSidebar__button">
                    <StyledIconButton color="inherit" size="large" onClick={handleClickComments}>
                        <SvgIcon className="svgIconShadow" fontSize="large">
                            <path d={icons.ic_message2} fill="#fff" /> 
                        </SvgIcon>
                    </StyledIconButton>
                    <Typography variant="body1" color ="#fff" className="textShadow">{data.comments_count}</Typography>
                </Box>
                
                <Box className="videoSidebar__button">
                    <StyledIconButton color="inherit" size="large" onClick={handleBookMark}>
                        <SvgIcon className="svgIconShadow" fontSize="large">
                            {data.saved ?
                                <path d={icons.ic_save_checked} fill="#fff"/> 
                                :
                                <path d={icons.ic_save_normal} fill="#fff"/> 
                            }
                        </SvgIcon>
                        
                    </StyledIconButton>
                </Box>

                <Box className="videoSidebar__button">
                    <StyledIconButton color="inherit" size="large" onClick={isLoggedIn ? handleClickGifts : toggleLogin}>
                        <SvgIcon className="svgIconShadow" fontSize="large">
                            <path d={icons.ic_clip_gift} fill="#fff" /> 
                        </SvgIcon>
                    </StyledIconButton>
                </Box>

                <Box className="videoSidebar__button">
                    <StyledIconButton color="inherit" size="large" onClick={toggleShareDialog}>
                        <SvgIcon className="svgIconShadow" fontSize="large">
                            <path d={icons.ic_share} fill="#fff" /> 
                        </SvgIcon>
                    </StyledIconButton>
                </Box>
            </Box>
      </ThemeProvider>
    )
}

export default RightSideBar

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    // marginLeft: 10,
    '&:hover': {
        backgroundColor: alpha(Theme.whiteColor, 0.15),
    },
}));

