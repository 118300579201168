import React, { useState, Fragment, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import Theme from '../../config/Theme'
import { Box, IconButton, SvgIcon, Dialog, DialogTitle, DialogContent, Typography, Button, Link } from '@mui/material'
import { styled, alpha } from '@mui/material/styles'
import icons from '../../assets/icons'
import LoginWithEmail from './LoginWithEmail'
import LoginWithSms from './LoginWithSms'
import { isEqual } from 'lodash'
import GoogleLogin from './GoogleLogin'
import FacebookLogin from './FacebookLogin'
import { useNavigate } from 'react-router-dom'
import {getApiRoutes} from '../../config/Routes'
import axios from 'axios'
import { AuthContext } from '../../redux/contexts/AuthContext'


export default ({showLogin, toggleLogin}) => {
    const { t } = useTranslation()
    const api = getApiRoutes()
    const { signIn } = useContext(AuthContext)
    const navigate = useNavigate()
    const [showLoginEmail, setShowLoginEmail] = useState(false)
    const [showLoginSms, setShowLoginSms] = useState(false)
    const [errorsMessage, setErrorsMessage] = useState()
    
    const toggleLoginEmail = () => {
        setShowLoginEmail(!showLoginEmail)
    }

    const toggleLoginSms = () => {
        setShowLoginSms(!showLoginSms)
    }

    const handleClick = (type) => () => {
        toggleLogin()
        { isEqual(type, 'email') ?
            toggleLoginEmail()
            :
            toggleLoginSms()
        }
    }

    /* auth with google */
    const onSuccess = (response) => {
        axios({
            method: api.google.method,
            url: api.google,
        }).then(function(response) {
            console.log(response)
            let data = response.data;
            
            if(data.existing != true)
            {
                setErrorsMessage({otp:'errorOtp'})
            } else {
                signIn({token: data.token})
                navigate('/')
            }
        }).catch(function(error) {
            console.log(error.message);
        });
    }

    const onFailure = (response) => () => {
        console.log('failed', response)
    }

    const responseFacebook = (response) => () => {
        console.log('responseFacebook', response)
    }

    return (
        <Fragment>
            <LoginWithEmail {...{showLoginEmail, toggleLoginEmail, toggleLogin}}/>
            <LoginWithSms {...{showLoginSms, setShowLoginSms, toggleLoginSms, toggleLogin}}/>

            <Dialog
                keepMounted
                open={showLogin}
                onClose={toggleLogin}
                maxWidth="xs"
                fullWidth
                scroll={'body'}
                PaperProps={{
                    style: {
                        backgroundColor: Theme.blackColor,
                        color: 'white',
                        borderRadius: 5
                    },
                }}
                BackdropProps={{
                    style: {
                        backgroundColor: "#3c3c3c66",
                        color: 'white',
                        borderRadius: 5
                    },
                }}
            >
        
            <DialogTitle sx={{backgroundColor: Theme.blackColor}}>
                <Box sx={{display:"flex", alignItems:"center", justifyContent:"center"}}>
                    <StyledIconButton aria-label="close" onClick={toggleLogin}>
                        <SvgIcon>
                            <path d={icons.ic_baseline_close_24} fill="#fff"/>
                        </SvgIcon>
                    </StyledIconButton>
                    <Box sx={{ flexGrow: 1, textAlign:"center"}}>Login</Box>
                </Box>
            </DialogTitle>
            
            <DialogContent sx={{borderTop:'1.5px solid', borderTopColor:Theme.grayDarkColor}}>
                <Typography color="#fff" fontSize={13} textAlign="center" marginTop={3}> 
                    {t("Please login by clicking on any of the below service to continue using the app.")}
                </Typography>

                {/* <FcbBtn sx={{backgroundColor: Theme.colorButtonFacebook, width:"100%", color:"#fff", marginTop: 3}}
                    startIcon={ <SvgIcon><path d={icons.ic_facebook} fill="#fff"/></SvgIcon> }
                    // onClick={""}
                >
                    Facebook
                </FcbBtn> */}
                <FacebookLogin {...{responseFacebook}}/>

                <GoogleLogin {...{onSuccess, onFailure}}/>
                
                <SmsBtn sx={{backgroundColor: Theme.colorButtonSms, width:"100%", color:"#000", marginTop: 3}}
                    startIcon={ <SvgIcon><path d={icons.ic_sms} fill="#000"/></SvgIcon> }
                    onClick={handleClick("sms")}
                >
                    SMS
                </SmsBtn>
                <EmailBtn sx={{backgroundColor: Theme.colorButtonEmail, width:"100%", color:"#000", marginTop: 3, marginBottom: 3}}  
                    startIcon={ <SvgIcon> <path d={icons.ic_email} fill="#000"/></SvgIcon> }
                    onClick={handleClick("email")}
                >
                    Email
                </EmailBtn>
                <Typography color="#fff" fontSize={13} textAlign="center"> 
                   {t("By logging in add use our app, you agree having read our")}  {` `}
                    <Link color={Theme.greenColor} href="https://smilelive.app/policy.html">Privacy Policy</Link> and {` `}
                    <Link color={Theme.greenColor} href="/terms-and-conditions">Terms of Use</Link>
                </Typography>
            </DialogContent>
        </Dialog>  
        </Fragment>     
    )
}

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    left: 0,
    '&:hover': {
        backgroundColor: alpha(Theme.whiteColor, 0.15),
    },
}));

const FcbBtn = styled(Button)(({ theme }) => ({
    '&:hover': {
        backgroundColor: alpha(Theme.colorButtonFacebook, 0.75),
    },
}));


const SmsBtn = styled(Button)(({ theme }) => ({
    '&:hover': {
        backgroundColor: alpha(Theme.colorButtonSms, 0.75),
    },
}));

const EmailBtn = styled(Button)(({ theme }) => ({
    '&:hover': {
        backgroundColor: alpha(Theme.colorButtonEmail, 0.75),
    },
}));