import React from 'react'
import { useTranslation } from 'react-i18next'
import Theme from '../config/Theme'
import { Box, IconButton, SvgIcon, Dialog, DialogTitle, DialogContent, Button, Link } from '@mui/material'
import { styled, alpha } from '@mui/material/styles'
import icons from '../assets/icons'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'


export default ({showShareDialog, toggleShareDialog, data}) => {
    const { t } = useTranslation()
   
    return (
            <Dialog
                keepMounted
                open={showShareDialog}
                onClose={toggleShareDialog}
                maxWidth="xs"
                fullWidth
                scroll={'body'}
                PaperProps={{
                    style: {
                        backgroundColor: Theme.blackColor,
                        color: 'white',
                        borderRadius: 5
                    },
                }}
                BackdropProps={{
                    style: {
                        backgroundColor: "#3c3c3c66",
                        color: 'white',
                        borderRadius: 5
                    },
                }}
            >
        
            <DialogTitle sx={{backgroundColor: Theme.blackColor}}>
                <Box sx={{display:"flex", alignItems:"center", justifyContent:"center"}}>
                    <StyledIconButton aria-label="close" onClick={toggleShareDialog}>
                        <SvgIcon>
                            <path d={icons.ic_baseline_close_24} fill="#fff"/>
                        </SvgIcon>
                    </StyledIconButton>
                    <Box sx={{ flexGrow: 1, textAlign:"center"}}>Share clip to...</Box>
                </Box>
            </DialogTitle>
            
            <DialogContent sx={{borderTop:'1.5px solid', borderTopColor:Theme.grayDarkColor}}>
                <Box marginTop={3} pl={2} pr={2} sx={{display:"flex", justifyContent:"space-between"}}>
                    <Link href="https://ro-ro.facebook.com/" target="_blank" rel="noopener">
                        <SvgIcon fontSize="large">
                            <path d={icons.ic_facebook_colored} fill="#2AA4F4"/>
                        </SvgIcon>
                    </Link>

                    <Link href="https://www.instagram.com/" target="_blank" rel="noopener">
                        <SvgIcon fontSize="large"  viewBox='0 0 48 48'>
                            <path d={icons.ic_instagram} fill="#fff"/>
                        </SvgIcon>
                    </Link>

                    <Link href="https://www.instagram.com/" target="_blank" rel="noopener">
                        <SvgIcon fontSize="large"  viewBox='0 0 48 48'>
                            <path d={icons.ic_twitter_colored} fill="#2AA4F4"/>
                        </SvgIcon>
                    </Link>

                    <Link href="https://web.whatsapp.com/" target="_blank" rel="noopener">
                        <WhatsAppIcon fontSize="large" sx={{color:Theme.greenColor}}/>
                    </Link>  
                    <SvgIcon fontSize="large">
                        <path d={icons.ic_baseline_more_horiz_24} fill="#fff"/>
                    </SvgIcon>
                    
                </Box>
                
            </DialogContent>
        </Dialog>  
        
    )
}

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    left: 0,
    '&:hover': {
        backgroundColor: alpha(Theme.whiteColor, 0.15),
    },
}));

const FcbBtn = styled(Button)(({ theme }) => ({
    '&:hover': {
        backgroundColor: alpha(Theme.colorButtonFacebook, 0.75),
    },
}));

const GoogleBtn = styled(Button)(({ theme }) => ({
    '&:hover': {
        backgroundColor: alpha(Theme.colorButtonGoogle, 0.75),
    },
}));

const SmsBtn = styled(Button)(({ theme }) => ({
    '&:hover': {
        backgroundColor: alpha(Theme.colorButtonSms, 0.75),
    },
}));

const EmailBtn = styled(Button)(({ theme }) => ({
    '&:hover': {
        backgroundColor: alpha(Theme.colorButtonEmail, 0.75),
    },
}));