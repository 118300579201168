import React, { useState, Fragment, useContext } from 'react'
import { Box, TextField, InputAdornment, IconButton, SvgIcon, Tabs, Tab, Typography, Button } from '@mui/material'
import { styled, alpha,useTheme } from '@mui/material/styles'
import AppBar from '@mui/material/AppBar'
import PropTypes from 'prop-types'
import SwipeableViews from 'react-swipeable-views'
import Theme from "../config/Theme"
import icons from '../assets/icons'
import CustomCircularProgress from '../components/Loader'
import { isEmpty, isEqual, isNull } from 'lodash'
import PlayerSong from '../components/PlayerSong'
import { useEffect } from 'react'
import {getApiRoutes} from '../config/Routes'
import axios from 'axios'
import GoBackButton from '../components/GoBackButton'
import { useNavigate, useLocation } from 'react-router-dom'
import { AuthContext } from '../redux/contexts/AuthContext'
 

const imgSong = require('../assets/image_placeholder.png')

export default() => {
    const api = getApiRoutes()
    const { token } = useContext(AuthContext)
    const  userToken = token || localStorage.getItem('token')
    const theme = useTheme()
    const [search, setSearch] = useState('')
    const[tab, setTab] = useState(0)
    const [resetKey, setResetKey] = useState(0)
    const [songs, setSongs] = useState([])
    const [song, setSong] = useState(null)
    const [sections, setSections] = useState([])
    const [maxPage, setMaxPage] = useState(5)
    const [page, setPage] = useState(1)
    const [isChangeTab, setIsChangeTab]= useState(false)
    const [tabIdx, setTabIdx]= useState('')

    const loadSongSections = () => {
        axios.defaults.headers["Authorization"] = `Bearer ${userToken}`
		axios({
			method:api.songSections.method,
			url: api.songSections.path  + '?page=' + page,
		}).then((response) => {
            setSections(response.data.data)
        }).catch((error) => {
            console.log(error.message);
        });
	}

    const loadSongs = () => {
		axios({
			method:api.songs.method,
			url: api.songs.path  + '?page=' + page,
		}).then((response) => {
            setMaxPage(parseInt(response.data.meta.last_page))
            setPage(page + 1)
            setSongs(prev => [...prev, ...response.data.data])
            
        }).catch((error) => {
            console.log(error.message);
        });
	}

    const loadSongTab = () => {
        axios({
            method: api.songs.method,
            url: api.songs.path + '?sections[]='+tabIdx?.id,
        }).then((response) => {
            setSongs(response.data.data)
            setIsChangeTab(false)
        }).catch((error) => {
            console.log(error.message);
        });
    }

    useEffect(() =>{
        loadSongSections() /*incarca sectiunile pop, sectiune1for music, clasica, manele */
    },[])

 
    useEffect(() =>{
        isChangeTab && loadSongTab()
    },[isChangeTab])

    useEffect(()=>{
        if(page<=maxPage) loadSongs()
    },[page])
   
    const handleSearch = (e) => {
        setSearch(e.target.value)
    }

    const handleEmptySearch = () => {
        setSearch('')
    }
   
    const handleChange = (e, newValue) => {
        setTab(newValue);
        setTabIdx(sections[newValue])
        setIsChangeTab(true)
    }
    
    const handleChangeIndex = (index) =>{
        setTab(index)
    }


    useEffect(() => {
        isChangeTab && setResetKey(prev => prev + 1)

    },[isChangeTab])


    const TabPanel= (props) => {
        const { children, value, index, ...other } = props;

        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box sx={{ p: 3 }}>
                {children}
              </Box>
            )}
          </div>
        );
      }
      
      TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
      };
      
    function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
    }
        
    const openPlayer = (song) => () =>{
        setSong(song)
    }
   

    return (
        <Fragment>
            <Box sx={{padding:{xs:0, md:2} }}>
            <CustomTextfield
                autoFocus
                margin="dense"
                id="search"
                label="Search song or artist..."
                placeholder="Search song or artist..."
                // size="small"
                variant="outlined"
                name="search"
                value={search}
                onChange={handleSearch}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                        <SvgIcon>
                            <path d={icons.ic_baseline_search_24} fill={Theme.grayColor}/>
                        </SvgIcon>
                        
                        
                        </InputAdornment>
                    ),
                    endAdornment:(
                        <InputAdornment position="end" >
                            <StyledIconButton onClick={handleEmptySearch} size="small">
                                <SvgIcon>
                                    <path d={icons.ic_baseline_close_24} fill={Theme.grayColor}/>
                                </SvgIcon>
                            </StyledIconButton>
                        </InputAdornment>
                    )
                }}
            />
            </Box>

            <Box sx={{  maxWidth:"100%", minWidth:"200px", height:"480px", overflowY:"scroll", padding:{xs:0, md:2}   }}>
                <AppBar position="static" sx={{backgroundColor:'transparent'}}>
                    <StyledTabs
                        value={tab}
                        onChange={handleChange}
                        aria-label="search-tabs"
                        scrollButtons="auto"
                        variant="scrollable"
                        allowScrollButtonsMobile
                    >
                        {sections.map(section => {
                            return (
                            <StyledTab key={section.name} label={section.name} {...a11yProps(section.id)}/>    
                            )
                        })
                        }
                    </StyledTabs>
                </AppBar>

                <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={tab}
                    onChangeIndex={handleChangeIndex}
                >
                    <TabPanel value={tab} index={tab} dir={theme.direction}>
                        { isEmpty(songs) ?
                            <Box sx={{display:"flex", justifyContent:"center", alignItems:"center", paddingTop:"20%"}}>
                                <CustomCircularProgress/>
                            </Box>
                        :
                        <Box key={resetKey}>
                        {songs.map(song => {
                            return(
                                <Box key={resetKey+`-${song.title}`} sx={{display:"flex", alignItems:"center"}}>
                                    <Box marginRight={2} sx={{display:"flex", alignItems:"center"}}>
                                        <Box>
                                            <img src={imgSong} width={40} height={40} alt="Smile" style={{borderRadius:5}}/>
                                        </Box>
                                        <Box marginLeft={2} width={60}>
                                            <Typography variant="body2" color="#fff">{song.title}</Typography>
                                            <Typography variant="caption" color={Theme.grayColor}>{song.duration}s</Typography>
                                        </Box>
                                </Box>
                                    <StyledButton
                                        size="small" 
                                        onClick={openPlayer(song)}
                                    >
                                        <SvgIcon>
                                            <path d={icons.ic_baseline_play_arrow_24} fill={Theme.yellowColor}/>
                                        </SvgIcon>
                                        
                                    </StyledButton>
                                </Box>
                            )
                        })}
                        </Box>
                    }
                    </TabPanel>
                </SwipeableViews>
                
            </Box>
            {!isNull(song) && <PlayerSong {...{song, setSong}} />}
        </Fragment>
    )
}

const CustomTextfield = styled(TextField)(() => ({
    width: '100%',
    // marginTop: 20,
    fontSize:14,
    '& label.Mui-focused': {
        color: Theme.grayColor,
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: Theme.grayColor,
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: Theme.grayColor,
        },
        '&:hover fieldset': {
            borderColor: Theme.grayColor,
        },
        '&.Mui-focused fieldset': {
            borderColor: Theme.grayColor,
        },
    },
    '& .MuiInputBase-input':{
        color: Theme.grayColor,
        fontSize:12
    },
    '& label': {
        color: Theme.grayColor,
        fontSize:14,
    },
    
  
}));

const StyledIconButton = styled(IconButton)(() => ({
    '&:hover': {
        backgroundColor: alpha(Theme.whiteColor, 0.15),
    },
}));

const StyledButton = styled(Button)(() => ({
    color:"white",
    backgroundColor:"transparent",
    border: "1px solid",
    borderColor: Theme.grayColor,
    '&:hover': {
        backgroundColor: alpha(Theme.whiteColor, 0.15),
    },
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
    '& .MuiTabs-indicator': {
        backgroundColor: Theme.yellowColor,
    },
  })
)

const StyledTab = styled(Tab)(({ theme }) => ({
    color: Theme.grayColor,
    padding:'8px 12px',
    fontSize:'12px',
    fontWeight:400,
    '&.Mui-selected': {
        color: Theme.yellowColor,
    },
    '&.Mui-focusVisible': {
        backgroundColor: Theme.yellowColor,
    },
    
  })
);

const CheckButton = styled(Button)(() => ({
    marginLeft: 10,
    color:"white",
    backgroundColor:Theme.yellowColor,
    '&:hover': {
        backgroundColor: alpha(Theme.whiteColor, 0.15),
    },
}));