import React, { useEffect, useState } from 'react'
import { Tabs, Tab, Box } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import { styled, useTheme  } from '@mui/material/styles'
import PropTypes from 'prop-types'
import SwipeableViews from 'react-swipeable-views'
import Theme from "../../config/Theme"
import {getApiRoutes} from '../../config/Routes'
import axios from 'axios'
import CustomCircularProgress from '../../components/Loader'
import { isEmpty } from 'lodash'
import Users from './Users'
import Hashtags from './Hashtags'


export default ({search}) => {
    const theme = useTheme()
    const api = getApiRoutes()
    const[tab, setTab] = useState(0)
	const [users, setUsers] = useState([])
	const [hashtags, setHashtags] = useState([])
	const [page, setPage] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
	
	const loadingUsers = () => {
		axios({
			method:api.users.method,
			url: api.users.path  + '?page=' + currentPage +'&q=' + search,
		}).then((response) => {
            setUsers(prev => [...prev, ...response.data.data])
			const lastPage = response.data.meta.last_page
            if(currentPage < lastPage) {
              setCurrentPage(currentPage + 1)
            }
        }).catch((error) => {
            console.log(error.message);
        });
	}

	const loadingHashtags = () => {
		axios({
			method:api.hashtags.method,
			url: api.hashtags.path  + '?page=' + currentPage +'&q=' + search,
		}).then((response) => {
            setHashtags(prev =>[...prev, ...response.data.data])
			const lastPage = response.data.meta.last_page
            if(currentPage < lastPage) {
              setCurrentPage(currentPage + 1)
            }
        }).catch((error) => {
            console.log(error.message);
        });
	}

	useEffect(()=>{
		loadingUsers()
		loadingHashtags()
	},[currentPage])

	useEffect(()=>{
		loadingUsers()
		loadingHashtags()
	},[search])


    const handleChange = (e, newValue) => {
        setTab(newValue);
    }
    
    const handleChangeIndex = (index) =>{
        setTab(index)
    }

    const TabPanel= (props) => {
        const { children, value, index, ...other } = props;
      
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box sx={{ p: 3 }}>
                {children}
              </Box>
            )}
          </div>
        );
      }
      
      TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
      };
      
      function a11yProps(index) {
        return {
          id: `full-width-tab-${index}`,
          'aria-controls': `full-width-tabpanel-${index}`,
        };
      }
        
    return (
        <Box sx={{ width: '100%' }}>
            <AppBar position="static"sx={{backgroundColor:'transparent'}}>
                <StyledTabs
                    value={tab}
                    onChange={handleChange}
                    aria-label="search-tabs"
                    centered
                >
                    <StyledTab label="Users" {...a11yProps(0)}/>
                    <StyledTab label="HashTags" {...a11yProps(1)}/>
                </StyledTabs>
            </AppBar>

            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={tab}
                onChangeIndex={handleChangeIndex}
            >
                <TabPanel value={tab} index={0} dir={theme.direction}>
					{ isEmpty(users) ?
						<Box sx={{display:"flex", justifyContent:"center", alignItems:"center", paddingTop:"20%"}}>
							<CustomCircularProgress/>
						</Box>
					:
					<Users {...{users}}/>
					}
                    
                </TabPanel>
                <TabPanel value={tab} index={1} dir={theme.direction}>
					{ isEmpty(hashtags) ?
						<Box sx={{display:"flex", justifyContent:"center", alignItems:"center", paddingTop:"20%"}}>
							<CustomCircularProgress/>
						</Box>
					:
					<Hashtags {...{hashtags}}/>
					}
                </TabPanel>
            </SwipeableViews>
        </Box>
    )
}

const StyledTabs = styled(Tabs)(({ theme }) => ({
    '& .MuiTabs-indicator': {
        backgroundColor: Theme.yellowColor,
    },
  })
)

const StyledTab = styled(Tab)(({ theme }) => ({
    color: Theme.grayColor,
    width: 150,
    '&.Mui-selected': {
        color: Theme.yellowColor,
    },
    '&.Mui-focusVisible': {
        backgroundColor: Theme.yellowColor,
    },
    
  })
);


