import React from 'react'
import { Grid, Box, Typography, IconButton, Avatar } from '@mui/material'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { styled, alpha } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import Theme from '../../config/Theme'
import { isEmpty, toLower } from 'lodash'
import monthNames from '../../components/monthNames'
import StarsIcon from '@mui/icons-material/Stars'


const ChatContent = ({messages}) => {
    const { t } = useTranslation()
      
    return(
        isEmpty(messages) ? 
        <Box sx={{display:"flex", flex:1, justifyContent:"center", alignItems:'center'}} color="#fff" textAlign="center">No display messages</Box>
        :
        <Box sx={{height:'100%',alignItems:"center", marginBottom: "75px"}}>
            {messages.map((message,idx) => {
                return(
                    message.user?.me ?
                    <Grid container key={idx} item xs={12} sm={12} sx={{display:"flex", alignItems:"center", justifyContent:"end"}}>
                        <Box textAlign="right">
                            <Box sx={{backgroundColor: Theme.greenColor, color:"#000", borderRadius: 2, borderBottomRightRadius:0, padding:0.5}}>
                                <Typography variant="caption">
                                    {message.body}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography component="span" color={Theme.grayColor} sx={{fontSize: 12}}>{`  pe ${new Date(message.updated_at).getDate() + ' ' + toLower(t(monthNames[new Date(message.updated_at).getMonth()]))}`}</Typography>
                                <StyledIconButton
                                    size="medium"
                                    aria-label="delete"
                                    color="inherit"
                                >
                                <DeleteForeverIcon sx={{color:Theme.grayColor, fontSize: 18}}/>
                            </StyledIconButton>
                            </Box>
                        </Box>
                        
                    </Grid>
                    :
                    <Grid key={idx} item xs={12} sm={12} sx={{display:"flex", alignItems:"center", marginBottom: 1}}>
                         <BorderAvatar alt="" src={message.user.photo} />
                         <Box>
                            <Box sx={{display:"flex", alignItems:"center"}}>
                                <Typography color="#fff" variant="body2" fontSize={12}>{`@${message.user.username} `}</Typography>
                                <StarsIcon sx={{color:Theme.greenColor, fontSize:20, marginLeft: 1}}/>
                            </Box>
                            <Box sx={{backgroundColor: Theme.yellowColor, color:"#000", borderRadius: 2, borderTopLeftRadius:0, padding:0.5}}>
                                <Typography variant="caption">
                                    {message.body}
                                </Typography>
                            </Box>
                            <Typography color={Theme.grayColor} fontSize={12}>{`  pe ${new Date(message.updated_at).getDate() + ' ' + toLower(t(monthNames[new Date(message.updated_at).getMonth()]))}`}</Typography>
                            
                         </Box>
                    </Grid>
                )
            })
            }
        </Box>
    )
}
export default ChatContent

const BorderAvatar = styled(Avatar)(() => ({
    border:"3px solid",
    borderColor: Theme.yellowColor,
    marginRight: 10,
    '& img': {
        borderRadius:'50%'
    }
}));
const StyledIconButton = styled(IconButton)(({ theme }) => ({
    '&:hover': {
        backgroundColor: alpha(Theme.whiteColor, 0.15),
    },
}));
