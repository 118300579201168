import React, { useState } from 'react'
import { Tabs, Tab, Box, SvgIcon } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import { styled, useTheme  } from '@mui/material/styles'
import PropTypes from 'prop-types'
import SwipeableViews from 'react-swipeable-views'
import Theme from "../../config/Theme"
import ClipsTab from './ClipsTab'
import FavoritesTab from './FavoritesTab'
import BookmarksTab from './BookmarksTab'
import WarningTab from './WarningTab'
import icons from '../../assets/icons'


export default ({user}) => {
    const userId = user.id
    const theme = useTheme()
    const[tab, setTab] = useState(0)
	
    const handleChange = (e, newValue) => {
        setTab(newValue);
    }
    
    const handleChangeIndex = (index) =>{
        setTab(index)
    }

    const TabPanel= (props) => {
        const { children, value, index, ...other } = props;
      
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box sx={{ p: 3 }}>
                {children}
              </Box>
            )}
          </div>
        );
      }
      
    TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
    };
      
    function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
    }
  
        
    return (
        <Box sx={{ width: '100%' }}>
            <AppBar position="static"sx={{backgroundColor:'transparent'}}>
                <StyledTabs
                    value={tab}
                    onChange={handleChange}
                    aria-label="search-tabs"
                    centered
                >
                    <StyledTab 
                        aria-label="Clips"  
                        icon={<SvgIcon><path d={icons.ic_baseline_video_library_24}/></SvgIcon>}
                        {...a11yProps(0)}
                    />
                    { user.me &&
                        <StyledTab 
                            aria-label="Favorites"
                            icon={<SvgIcon><path d={icons.heart_gray}/></SvgIcon>} 
                            {...a11yProps(1)}
                        />
                    }
                    { user.me && 
                        <StyledTab 
                            aria-label="Bookmark"
                            icon={<SvgIcon><path d={icons.ic_baseline_book_24} /></SvgIcon>} 
                            {...a11yProps(2)}
                        />
                    }
                    { user.me &&
                        <StyledTab 
                            aria-label="Warning" 
                            icon={<SvgIcon><path d={icons.ic_baseline_warning_24}/></SvgIcon>}
                            {...a11yProps(3)}
                        />
                    }
                   
                    
                </StyledTabs>
            </AppBar>

            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={tab}
                onChangeIndex={handleChangeIndex}
            >
                <TabPanel value={tab} index={0} dir={theme.direction}>
                    <ClipsTab {...{userId}}/>
				</TabPanel>
                { user.me &&
                    <TabPanel value={tab} index={1} dir={theme.direction}>
                        <FavoritesTab {...{userId}}/>
                    </TabPanel>
                }
                { user.me &&    
                    <TabPanel value={tab} index={2} dir={theme.direction}>
                        <BookmarksTab {...{userId}}/>
                    </TabPanel>
                }
                { user.me &&
                    <TabPanel value={tab} index={3} dir={theme.direction}>
                        <WarningTab {...{userId}}/>
                    </TabPanel>
                }  
                
            </SwipeableViews>
        </Box>
    )
}

const StyledTabs = styled(Tabs)(({ theme }) => ({
    '& .MuiTabs-indicator': {
        backgroundColor: Theme.yellowColor,
    },
  })
)

const StyledTab = styled(Tab)(({ theme }) => ({
    color: Theme.grayColor,
    width: 150,
    '&.Mui-selected': {
        color: Theme.yellowColor,
    },
    '&.Mui-focusVisible': {
        backgroundColor: Theme.yellowColor,
    },
    
  })
);


