import React, {useState} from 'react'
import Theme from '../config/Theme'
import { useNavigate  } from 'react-router-dom'
import { Box, IconButton, SvgIcon } from '@mui/material'
import { styled, alpha } from '@mui/material/styles'
import icons from '../assets/icons'
import Login from '../views/auth/Login'


export default ({ isLoggedIn, colorSvgIcons }) => {
    const navigate = useNavigate()
    const [showLogin, setShowLogin] = useState(false)

    const toggleLogin = () => {
        setShowLogin(!showLogin)
    }

    const handleClickNotification = () => {
        if (isLoggedIn) 
           navigate('/notifications')
        else 
            toggleLogin()    
    }

    const handleClick = (to) => () => {
        navigate(to)
    }
   
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Login {...{showLogin, toggleLogin}}/>
            <StyledIconButton
                size="medium"
                aria-label="home"
                color="inherit"
                onClick={handleClick('/')}
            >
                <SvgIcon>
                    <path d={icons.Home} fill={colorSvgIcons}/>
                </SvgIcon>
            </StyledIconButton>

            <StyledIconButton
                size="medium"
                aria-label="discover"
                color="inherit"
                onClick={handleClick('/discover')}
            >
                <SvgIcon>
                    <path d={icons.Discover} fill={colorSvgIcons}/>
                </SvgIcon>
            </StyledIconButton>
            
            <StyledIconButton
                size="medium"
                aria-label="notification"
                color="inherit"
                onClick={handleClickNotification}
            >
                <SvgIcon>
                    <path d={icons.Notification} fill={colorSvgIcons}/>
                </SvgIcon>
            </StyledIconButton>
    </Box>
    )
}

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    '&:hover': {
        backgroundColor: alpha(Theme.whiteColor, 0.15),
    },
}));