import React, { useState, useEffect, useContext} from 'react'
import { Box, Typography, Avatar, Grid, CardHeader, Button, SvgIcon, IconButton, Link } from '@mui/material'
import { styled, alpha } from '@mui/material/styles'
import icons from "../../assets/icons"
import { useParams } from "react-router-dom"
import axios from 'axios'
import PageLayout from '../../Layout/PageLayout'
import TitlePageBar from '../../Layout/TitlePageBar'
import StatisticRow from './StatisticRow'
import { isEqual, toUpper } from 'lodash'
import Theme from '../../config/Theme'
import StarsIcon from '@mui/icons-material/Stars'
import GoBackButton from '../../components/GoBackButton'
import { useNavigate } from 'react-router-dom'
import { getApiRoutes } from '../../config/Routes'
import { AuthContext } from '../../redux/contexts/AuthContext'
import TabsSection from './TabsSection'
import Login from '../auth/Login'


export default () => {
    const api = getApiRoutes()
    const navigate = useNavigate()
    const params  = useParams()
    const { isLoggedIn, token } = useContext(AuthContext)
    const  userToken = token || localStorage.getItem('token')
    const [showLogin, setShowLogin] = useState(false)
    const [user, setUser] = useState(false)
    const [isFollow, setIsFollow] = useState(user.followed)
    
    const renderLeftComponent = () => <GoBackButton/>

    const handleClickReport = () => {
        if (!isLoggedIn) toggleLogin()
        else navigate('/report', {state: { userId: params.id, from: 'profile'}})
    }

    const renderRightComponent = () => {
        return (
             !user.me ?
                <StyledIconButton
                    size="medium"
                    aria-label="home"
                    color="inherit"
                    onClick={handleClickReport}
                >
                    <SvgIcon>
                        <path d={icons.ic_baseline_flag_24} fill={Theme.grayColor}/>
                    </SvgIcon>
                </StyledIconButton>
                :
                <StyledIconButton
                    size="medium"
                    aria-label="home"
                    color="inherit"
                    onClick={() => navigate('/wallet')}
                >
                <SvgIcon>
                    <path d={icons.ic_baseline_account_balance_wallet_24} fill={Theme.grayColor}/>
                </SvgIcon>
                </StyledIconButton>
            
        )
    }

    const toggleLogin = () => {
        setShowLogin(!showLogin)
    }

    const loadProfileUser = () => {
        axios.defaults.headers["Authorization"] = `Bearer ${userToken}`
        axios.get(api.users.path + '/' + params.id).then(response => {
            setUser(response.data.data)
        })
    }
    
    useEffect(() => {
        loadProfileUser()
    }, [params])

    const handleFollow = () => {
        if (isLoggedIn) {
            axios.post(api.users.path + '/' + user.id + '/followers').then(res => {
                setIsFollow(!isFollow)
            }).catch(err => {
                console.log(err)
            })
        }
        else toggleLogin ()
    }

    const handleChat = () => {
         if (isLoggedIn)  {
            navigate('/chat', {state: {'user': user, 'from':'profile'}})
         }
         else toggleLogin ()
    }
    

    return (
        <PageLayout bgColor='#000'>
            <Login {...{showLogin, toggleLogin}}/>
            <TitlePageBar title="Profile" showLeftComponent={true} leftComponent={renderLeftComponent} showRightComponent={true} rightComponent={renderRightComponent}/>
            <Box sx={{display:"flex",  flex: 1, flexDirection:"column"}}>
            
                <Grid container direction="column" alignItems="center" justifyContent="center">
                    <Grid item xs={3}>
                        <CardHeader sx={{padding:"16px 0px"}}
                            avatar={
                                <BorderAvatar
                                    alt={toUpper(user.email)}
                                    src={user.photo}
                                />
                            }
                            titleTypographyProps={{variant:'h4', color:"#fff" }}
                            title={user.name}
                            subheader={
                                <Box sx={{display:"flex"}}>
                                    <Typography variant="caption" color={Theme.grayColor}> 
                                        {'(@' + user.username + ')'}
                                    </Typography>
                                    {
                                        user.verified && <StarsIcon sx={{color:Theme.greenColor, fontSize:20, marginLeft: 1}}/>
                                    }
                                </Box>
                            }
                        />
                        {/* <Box> */}
                            { !user.me &&
                            <Box justifyContent="space-between" sx={{display:"flex"}}>
                                <StyledButton
                                    variant="contained" 
                                    size="small"
                                    startIcon={
                                        <SvgIcon>
                                            <path d={!isFollow ? icons.ic_follow : icons.ic_unfollow} fill={Theme.blackColor}/>
                                        </SvgIcon> 
                                    }
                                    onClick={handleFollow}
                                >
                                    
                                    {!isFollow ? "Follow" : "Unfollow"}
                                </StyledButton>
                                <StyledButtonChat
                                    size="small"
                                    startIcon={
                                        <SvgIcon>
                                            <path d={icons.ic_baseline_message_24_yellow} fill={Theme.yellowColor}/>
                                        </SvgIcon> 
                                    }
                                    onClick={handleChat}
                                >
                                    <Typography color={Theme.yellowColor}> Chat </Typography>
                                </StyledButtonChat>
                            </Box>
                            }
                            <Typography component="div" variant="subtitle1" sx={{pb: 2, pt: 2, color:Theme.grayColor}}>
                                {user.bio}
                            </Typography>
                            <Box sx={{display:"flex", justifyContent:"center", pb: 2, color:Theme.grayColor}}>
                                {user.links?.map(link => {
                                    return(
                                        <Link key={link.type} href={link.url} sx={{marginRight:2}}>
                                            {isEqual(link.type,"facebook") ?
                                            <SvgIcon className="svgIconShadow" fontSize="medium" >
                                                <path d={icons.ic_facebook_gray } fill={Theme.grayColor} />
                                            </SvgIcon>
                                            :
                                            isEqual(link.type,"youtube") ?
                                            <SvgIcon className="svgIconShadow" fontSize="medium">
                                                <path d={icons.ic_youtube_gray} fill={Theme.grayColor} />
                                            </SvgIcon>
                                            :
                                            isEqual(link.type,"snapchat") ?
                                            <SvgIcon className="svgIconShadow" fontSize="medium" viewBox='0 0 48 48'>
                                                <path d={icons.ic_snapchat} fill={Theme.grayColor} />
                                            </SvgIcon>
                                            :
                                            <SvgIcon className="svgIconShadow" fontSize="medium" viewBox='0 0 48 48'>
                                                <path d={icons.ic_instagram} fill={Theme.grayColor} />
                                            </SvgIcon>
                                            }
                                        </Link>
                                    )

                                })
                                }
                            </Box>

                        
                    </Grid>   
                </Grid>
            
                <StatisticRow {...{user}}/>

                <TabsSection {...{user}}/>
            </Box>
        </PageLayout>
    )
}

const BorderAvatar = styled(Avatar)(() => ({
    width: 56, height: 56,
    border:"3px solid",
    borderColor: Theme.yellowColor,
    '& img': {
        borderRadius:'50%'
    }
}));

const StyledIconButton = styled(IconButton)(() => ({
    '&:hover': {
        backgroundColor: alpha(Theme.whiteColor, 0.15),
    },
}));

const StyledButton = styled(Button)(() => ({
    width: "50%",   
    backgroundColor: Theme.yellowColor,
    color: Theme.primaryColor,
    '&:hover': {
        backgroundColor: alpha(Theme.yellowColor, 0.75),
    },
}));

const StyledButtonChat = styled(Button)(() => ({
    width: "50%",   
    backgroundColor: 'transparent',
    color: Theme.yellowColor,
    '&:hover': {
        backgroundColor: alpha(Theme.yellowColor, 0.15),
    },
}));