import React from 'react'
import {IconButton, SvgIcon} from '@mui/material'
import { styled, alpha } from '@mui/material/styles'
import Theme from "../config/Theme"
import icons from '../assets/icons'
import { useNavigate } from 'react-router-dom'


 const GoBackButton = () => {
    const navigate = useNavigate()

    return (
        <StyledIconButton
            size="medium"
            aria-label="home"
            color="inherit"
            onClick={() => navigate(-1)}
        >
            <SvgIcon>
                <path d={icons.ic_back} fill={Theme.grayColor}/>
            </SvgIcon>
        </StyledIconButton>
    )
}

export default GoBackButton

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    '&:hover': {
        backgroundColor: alpha(Theme.whiteColor, 0.15),
    },
}));