import React, { useState, useEffect } from 'react'
import { Box, IconButton, SvgIcon} from '@mui/material'
import { styled, alpha } from '@mui/material/styles'
import Theme from "../../config/Theme"
import PageLayout from '../../Layout/PageLayout'
import {getApiRoutes} from '../../config/Routes'
import axios from 'axios'
import LiveStream from './LiveStream'
import CarouselComponents from '../../components/CarouselComponents'
import { isEmpty } from 'lodash'
import ClipsSlider from './ClipsSlider'
import TitlePageBar from '../../Layout/TitlePageBar'
import CustomCircularProgress from '../../components/Loader'
import icons from '../../assets/icons'
import { useNavigate } from 'react-router-dom'

const Discover = () => {
    const api = getApiRoutes()
    const [loading, setLoading] = useState(true);
    const [challenges, setChallenges] = useState([])
    const [page, setPage] = useState(1)
    const [sections, setSections] = useState([])
    const [maxPage, setMaxPage] = useState(5)
    const navigate = useNavigate()

    const handleClick = (to) => () => {
        navigate(to)
    }
    
    const fetchChallenges = () => {
        axios({
            method: api.challenges.method,
            url: api.challenges.path,
        }).then((response) => {
            setChallenges(response.data.data);
        }).catch((error) => {
            console.log(error.message);
        });
    }

    const loadDiscover = () => {
        axios({
            method:api.sections.method,
            url: api.sections.path  + "?page=" + page
        }).then((response) => {
            setMaxPage(parseInt(response.data.meta.last_page))
            setPage(parseInt(page) + 1)
            setSections(response.data.data)
        }).catch((error) => {
            console.log(error.message);
        });
    }

   

    useEffect(() => {
        fetchChallenges()
        loadDiscover()
    }, []);

    const renderItems = () => {
        return (
            challenges.map(item => {
                return(
                    <Box key={item.hashtag} sx={{ maxHeight: 400, borderRadius: "5px", margin: "0 20px" }}>
                        <img alt="Smile" src={item.image} style={{maxHeight: 400, width:"100%", borderRadius: "5px", display:"block", margin:"auto"}}/>
                    </Box>
                )
            })
    )}

    const renderSections = () => {
        if(isEmpty(sections)){
            return <CustomCircularProgress/>
        }
        else return sections.map((section, index) => {
            return (
                <ClipsSlider section={section} key={index}/>
            )
        })
    }
    
    const renderLeftComponent = () => {
        return(
            <StyledIconButton
                size="medium"
                aria-label="home"
                color="inherit"
                onClick={handleClick('/news')}
            >
                <SvgIcon>
                    <path d={icons.ic_baseline_travel_explore_24} fill={Theme.grayColor}/>
                </SvgIcon>
            </StyledIconButton>
        )
    }

    const renderRightComponent = () => {
        return(
        <StyledIconButton
            size="medium"
            aria-label="home"
            color="inherit"
            onClick={handleClick('/search')}
        >
            <SvgIcon>
                <path d={icons.ic_baseline_search_24} fill={Theme.grayColor}/>
            </SvgIcon>
        </StyledIconButton>
        )
    }

    return (
        <PageLayout bgColor='#000'>
            <TitlePageBar title="Discover" showLeftComponent={true} leftComponent={renderLeftComponent} showRightComponent={true} rightComponent={renderRightComponent}/>
            
            <Box sx={{display:"flex",  flex: 1, flexDirection:"column"}}>
                <CarouselComponents {...{renderItems}} noSlides={2} noSlidesMobile={1}/>
                
                <LiveStream/>
                {renderSections()}
            </Box>
        </PageLayout>
    )
}

export default Discover


const StyledIconButton = styled(IconButton)(({ theme }) => ({
    '&:hover': {
        backgroundColor: alpha(Theme.whiteColor, 0.15),
    },
}));