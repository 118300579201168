const reasons = [
    {drugs: "Drugs"}, 
    {fake_news: "Fake news"}, 
    {fake_profile: "Fake profile"}, 
    {harassment: "Harassment"}, 
    {hateful: "Hateful"}, 
    {ip_infringement: "IP infringement"}, 
    {political_propaganda: "Political propaganda"}, 
    {pornography: "Pornography"}, 
    {spam: "Spam"}, 
    {violence: "Violence"}, 
    {weapons: "Weapons"}, 
    {other: "Other"}
]


export default reasons