import React, { Fragment } from 'react'
import { Grid, Typography, Divider, Button, Box } from '@mui/material'
import { styled, alpha } from '@mui/material/styles'
import Theme from "../../config/Theme"
import { useNavigate } from 'react-router-dom'
import {isEmpty} from 'lodash'
import {getApiRoutes} from '../../config/Routes'
import axios from 'axios'
import { useState } from 'react'

export default ({gifts, creditBalance}) => {
    const api = getApiRoutes()
    const navigate = useNavigate()
    
    const handleRedeem = (gift) => () => {
        console.log(gift)
        if(creditBalance < gift.item.minimum) {
            return alert("You don't have enough balance to redeem this item")
        }
        else sendRedeem(gift.item.id)
    }

    const sendRedeem = (id) => {
        // console.log("id", id)
        axios.post(api.wallet.redeem.path, {items: [id]}).then(res => {
            console.log("test", res)
        }).catch(err => {
            alert(err.response.data.errors.items[0])
        })
    }



    return (
        isEmpty(gifts) ?
            <Box sx={{display:"flex", justifyContent:"center", alignItems:"center", paddingTop:"20%"}}>
                <Typography variant="caption" color={Theme.grayColor}>No gifts found to display</Typography>
            </Box>
        :
        gifts.map((gift,idx) => {
            return (
                <Fragment key={idx}>
                    <Grid container sx={{display:"flex", alignItems:"center"}}>
                        <Grid item xs={12} sm={4} sx={{display:"flex", alignItems:"center"}} justifyContent="center">
                            <img src={gift.item.image} style={{width:50, height:50, borderRadius:10, marginRight: 10}} alt={gift.name}/>
                            <Typography color="#fff">{`${gift.item.name}  x${gift.balance}`}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography color="#fff" textAlign="center">{gift.value}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4} textAlign="center">
                            <StyledButton varaint="contained" onClick={handleRedeem(gift)} disabled={gift.balance >= gift.item.minimum}>Redeem</StyledButton>
                        </Grid>
                    </Grid>
                    <Divider component="li" sx={{borderColor:Theme.grayDarkColor, marginTop: 2, marginBottom: 2}}/>
                </Fragment>
            )
        })
    )
}

const StyledButton = styled(Button)(() => ({
    backgroundColor: Theme.grayDarkColor,
    color: Theme.grayColor,
    borderRadius: 5,
    paddingLeft: 20,
    paddingRight: 20,
    '&:hover': {
        backgroundColor: alpha(Theme.whiteColor, 0.15),
    },
}));