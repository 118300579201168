
import React, { useState, useContext, Fragment } from 'react'
import Theme from '../config/Theme'
import { AppBar, Toolbar, Typography, Box, IconButton, SvgIcon } from '@mui/material'
import { styled, alpha } from '@mui/material/styles'
import { AuthContext } from '../redux/contexts/AuthContext'
import LeftSideHeader from './LeftSideHeader'
import RightSideHeader from './RightSideHeader'
import icons from '../assets/icons'
import Login from '../views/auth/Login'
import { Link, useNavigate } from 'react-router-dom'


const colorSvgIcons = Theme.yellowColor
const logo = require('../assets/logo.jpg')

export default () => {
    const navigate = useNavigate()
    const { isLoggedIn, user } = useContext(AuthContext)
    const [showLogin, setShowLogin] = useState(false)
    const [isMuted, setIsMuted] = useState(false)

    const toggleLogin = () => {
        setShowLogin(!showLogin)
    }

    const muteVideos = () => {
        const videos = document.querySelectorAll('video');
        let muted = false;
        videos.forEach(video => {
            video.muted = !video.muted;
            muted = video.muted;
        });
        setIsMuted(muted);
    }

	const handleRecord = e => {
		if (isLoggedIn)
            navigate('/record')
        else 
            toggleLogin ()
	}

    const handleClickLogo=()=>{
        alert('sfrrf')
    }


return (
    <Fragment>
        <Login {...{showLogin, toggleLogin}}/>
        <AppBar position="fixed" sx={{ bgcolor: Theme.blackColor, color: Theme.yellowColor}}>
            <Toolbar sx={{paddingLeft: {xs: 0, sm: 2}, paddingRight: {xs: 0, sm: 2}  }}>
                <BoxLogo sx={{ borderRight: "1px dashed", borderRightColor: Theme.grayDarkColor, paddingRight: 1 }}>
                    <Link to={{pathname: `/`}}>
                        <img src={logo} width={30} height={30} alt="Smile"/>
                        <Typography variant="body2" noWrap sx={{ display: { xs: 'none', sm: 'block'}, color: Theme.yellowColor, textDecoration:"none" }}> Smile</Typography>
                    </Link>
                </BoxLogo>

                <LeftSideHeader {...{isLoggedIn, colorSvgIcons}}/>
                
                <Box sx={{ flexGrow: 1 }} >
                    <StyledIconRecord size="medium" aria-label="record" onClick={handleRecord}>
                        <SvgIcon>
                            <path d={icons.Record} fill="#000"/>
                        </SvgIcon>
                    </StyledIconRecord>
                    
                </Box>

                <RightSideHeader {...{ isLoggedIn, isMuted, muteVideos, colorSvgIcons, toggleLogin }}/>
                
            </Toolbar>
        </AppBar>
    </Fragment>
  );
}

const StyledIconRecord = styled(IconButton)(({ theme }) => ({
    backgroundColor: Theme.yellowColor,
    color: Theme.primaryColor,
    // marginLeft: 10,
    '&:hover': {
        backgroundColor: alpha(Theme.yellowColor, 0.75),
    },
}));

const BoxLogo = styled(Box)(({ theme }) => ({
    borderRight: "1px dashed", 
    borderRightColor: Theme.grayDarkColor, 
    paddingRight: 1,
    color: Theme.yellowColor,
    a: {
        color: Theme.yellowColor,
        textDecoration: "none"
    },
}));


