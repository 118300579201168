import React, { useEffect, useState, useContext } from 'react'
import { Tabs, Tab, Box } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import { styled, useTheme  } from '@mui/material/styles'
import PropTypes from 'prop-types'
import SwipeableViews from 'react-swipeable-views'
import Theme from "../../config/Theme"
import {getApiRoutes} from '../../config/Routes'
import axios from 'axios'
import CustomCircularProgress from '../../components/Loader'
import { isEmpty } from 'lodash'
import GiftsTab from './GiftsTab'
import RedemptionsTab from './RedemptionsTab'
import { AuthContext } from '../../redux/contexts/AuthContext'


export default ({creditBalance}) => {
    const theme = useTheme()
    const api = getApiRoutes()
	const { token } = useContext(AuthContext)
    const  userToken = token || localStorage.getItem('token')
	const [loading, setLoading] = useState(false)
    const[tab, setTab] = useState(0)
    const [gifts, setGifts] = useState([])
    const [redemptions, setRedemptions] = useState([])
   
	const [page, setPage] = useState(1)
	
	const loadGifts = () => {
		setLoading(true)
		axios.defaults.headers["Authorization"] = `Bearer ${userToken}`
        axios({
            method: 'get',
            url: api.wallet.gift.path,
        }).then(response => {
            setGifts(response.data.data)
        }).catch((error) => {
            console.log(error.message);
        });
		setLoading(false)
    }

	const loadRedemptions = () => {
		setLoading(true)
		axios.defaults.headers["Authorization"] = `Bearer ${userToken}`
        axios({
            method: 'get',
            url: api.wallet.redemptions.path,
        }).then(response => {
		  setRedemptions(response.data.data)
        }).catch((error) => {
            console.log(error.message);
        });
		setLoading(false)
    }
	

	useEffect(()=>{
		loadGifts()
		loadRedemptions()
	},[])


    const handleChange = (e, newValue) => {
        setTab(newValue);
    }
    
    const handleChangeIndex = (index) =>{
        setTab(index)
    }

    const TabPanel= (props) => {
        const { children, value, index, ...other } = props;
      
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box sx={{ p: 3 }}>
                {children}
              </Box>
            )}
          </div>
        );
      }
      
      TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
      };
      
      function a11yProps(index) {
        return {
          id: `full-width-tab-${index}`,
          'aria-controls': `full-width-tabpanel-${index}`,
        };
      }
        
    return (
        <Box sx={{ width: '100%' }}>
            <AppBar position="static"sx={{backgroundColor:'transparent'}}>
                <StyledTabs
                    value={tab}
                    onChange={handleChange}
                    aria-label="wallet-tabs"
                    centered
                >
                    <StyledTab label="Gifts" {...a11yProps(0)}/>
                    <StyledTab label="Redemptions" {...a11yProps(1)}/>
                </StyledTabs>
            </AppBar>

            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={tab}
                onChangeIndex={handleChangeIndex}
            >
                <TabPanel value={tab} index={0} dir={theme.direction}>
					{ loading ?
						<Box sx={{display:"flex", justifyContent:"center", alignItems:"center", paddingTop:"20%"}}>
							<CustomCircularProgress/>
						</Box>
					:
					<GiftsTab {...{gifts, creditBalance}}/>
					}
                    
                </TabPanel>
                <TabPanel value={tab} index={1} dir={theme.direction}>
					{ loading ?
						<Box sx={{display:"flex", justifyContent:"center", alignItems:"center", paddingTop:"20%"}}>
							<CustomCircularProgress/>
						</Box>
					:
					<RedemptionsTab {...{redemptions}}/>
					}
                </TabPanel>
            </SwipeableViews>
        </Box>
    )
}

const StyledTabs = styled(Tabs)(({ theme }) => ({
    '& .MuiTabs-indicator': {
        backgroundColor: Theme.yellowColor,
    },
  })
)

const StyledTab = styled(Tab)(({ theme }) => ({
    color: Theme.grayColor,
    width: 150,
    '&.Mui-selected': {
        color: Theme.yellowColor,
    },
    '&.Mui-focusVisible': {
        backgroundColor: Theme.yellowColor,
    },
    
  })
);


