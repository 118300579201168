import React, { useEffect, useState } from 'react'
import { Grid, Typography, Link } from '@mui/material'
import Theme from '../../config/Theme'
import {getApiRoutes} from '../../config/Routes'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { isEmpty } from 'lodash'


export default ({userId}) => {
    const navigate = useNavigate()
    const api = getApiRoutes()
    const [clips, setClips]= useState([])

    const loadClips = () => {
        axios({
            method: api.clips.method,
            url: api.clips.path+ '?user=' + userId +'&saved=true'
        }).then(response => {
            setClips(response.data.data)
        }).catch((error) => {
            console.log(error.message);
        });
    }

    useEffect(() => {
        if(userId)
        {   
            loadClips();
        }
        return () => {
            setClips([]);
        }
    }, [userId]);
    
    const handleClickClip = (id) => () => {
        navigate('/',{state:{'videoId':id}}) /*send clip id */
    }

    return (
        <Grid container spacing={2}>
            { isEmpty(clips) ?
                <Grid item>
                    <Typography variant="caption" color={Theme.grayColor}>No clips found to display</Typography>
                </Grid>
                :
                clips.map((clip,idx) => {
                    return (
                        <Grid item key={idx}>
                            <Link key={idx} component="button" sx={{textDecoration:"none"}} onClick={handleClickClip(clip.id)}>
                                <img src={clip.preview} style={{width:150, height:150, borderRadius:10}} alt={clip.id}/>
                            </Link>
                        </Grid>
                    )
                })
            }
        </Grid>
    )
}