import React, { useState } from 'react'
import { Box, TextField, InputAdornment, IconButton, SvgIcon } from '@mui/material'
import { styled, alpha } from '@mui/material/styles'
import Theme from "../../config/Theme"
import PageLayout from '../../Layout/PageLayout'
import TitlePageBar from '../../Layout/TitlePageBar'
import {getApiRoutes} from '../../config/Routes'
import axios from 'axios'
import icons from '../../assets/icons'
import { useNavigate } from 'react-router-dom'
import GoBackButton from '../../components/GoBackButton'
import SearchTabs from './SearchTabs'


export default () => {
    const api = getApiRoutes()
    const navigate = useNavigate()
    const [search, setSearch] = useState('')
       
    const renderLeftComponent = () => <GoBackButton/>

    const handleSearch = (e) => {
        setSearch(e.target.value)
    }

    const handleEmptySearch = () => {
        setSearch('')
    }
           
    return (
        <PageLayout bgColor='#000'>
            <TitlePageBar title="Search" showLeftComponent={true} leftComponent={renderLeftComponent}/>
            <Box sx={{display:"flex",  flex: 1, flexDirection:"column"}}>
                <CustomTextfield
                    autoFocus
                    margin="dense"
                    id="search"
                    label="Search user or hashtag..."
                    placeholder="Search user or hashtag..."
                    fullWidth
                    variant="outlined"
                    name="search"
                    value={search}
                    onChange={handleSearch}
                    InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SvgIcon>
                                <path d={icons.ic_baseline_search_24} fill={Theme.yellowColor}/>
                            </SvgIcon>
                            
                            
                          </InputAdornment>
                        ),
                        endAdornment:(
                            <InputAdornment position="end" >
                                <StyledIconButton onClick={handleEmptySearch} size="small">
                                    <SvgIcon>
                                        <path d={icons.ic_baseline_close_24} fill={Theme.grayColor}/>
                                    </SvgIcon>
                                </StyledIconButton>
                            </InputAdornment>
                        )
                    }}
                 />
                 <SearchTabs {...{search}}/>
            </Box>
        </PageLayout>
    )
}

const CustomTextfield = styled(TextField)(({ theme }) => ({
    width: '100%',
    marginTop: 20,
    fontSize:16,
    '& label.Mui-focused': {
        color: Theme.yellowColor,
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: Theme.yellowColor,
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: Theme.yellowColor,
        },
        '&:hover fieldset': {
            borderColor: Theme.yellowColor,
        },
        '&.Mui-focused fieldset': {
            borderColor: Theme.yellowColor,
        },
    },
    '& .MuiInputBase-input':{
        color: Theme.yellowColor,
        fontSize:14
    },
    '& label': {
        color: Theme.yellowColor,
        fontSize:16,
    },
  
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    '&:hover': {
        backgroundColor: alpha(Theme.whiteColor, 0.15),
    },
}));

