import React, { createContext, useEffect, useReducer } from 'react' 
import authReducer from '../reducers/authReducer'
import { LOGIN, LOGOUT } from '../actions/authActions'
import axios from 'axios'
import { useCookies } from 'react-cookie'
import { useNavigate } from 'react-router-dom'
import { getApiRoutes } from '../../config/Routes'

export const AuthContext = createContext()

const defaultState = {
	isLoading: false,
	isSignout: false,
	isLoggedIn: false,
	token: null,
	user: {}
}

export const AuthProvider = ({...props}) => {
    const api = getApiRoutes()
    const [state, dispatch] = useReducer(authReducer, defaultState)
	const [cookies, setCookie, removeCookie] = useCookies()
	const navigate = useNavigate()

    useEffect(() => { bootstrapAsync() }, [])
   
	/** */
	const bootstrapAsync = () => {
		// const { token } = cookies
		const  token = localStorage.getItem("token");
		
		if (token) {
			axios.defaults.headers["Authorization"] = `Bearer ${token}`
			axios.defaults.headers["Accept"] = 'application/json'
            const payload = { token }

			axios({
				method: api.profile.method,
				url: api.profile.path
			}).then(async function(response) {
				payload.user = response.data;
				dispatch({...LOGIN, payload})
			}).catch(function(error) {
				console.log(error.message);
			});
		}
	}

    const signIn = payload => {
		const { token } = payload
		// localStorage.setItem('token', token)
				
		if (token) {
			dispatch({...LOGIN, payload})
			// setCookie('token', token, { path: '/' })
			localStorage.setItem('token', token)
		}
	}

    const signOut = () => {
		dispatch(LOGOUT)
		// removeCookie('token', { path: '/' })
		localStorage.removeItem('token',{path:'/'})
		navigate('/')
	}
	    
    const authContext = React.useMemo(() => ({ bootstrapAsync, signIn, signOut }),[]) 


	
    return (
		<AuthContext.Provider value={Object.assign({}, state, authContext)}>
			{ props.children }
		</AuthContext.Provider>
	) 
}


