import React from 'react'
import { Typography, Grid, Chip, Link,Box } from '@mui/material'
import Theme from '../../config/Theme'
import { useNavigate } from 'react-router-dom'

export default ({user}) => {
    const navigate = useNavigate()

    const handleClickFollowers = () => {
        navigate('/followers', {state: {userId:user.id}})
    }

    const handleClickFollowing = () => {
        navigate('/following', {state: {userId:user.id}})
    }

    return (
        <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" marginBottom={3}>
            <Grid container justifyContent="center">
                <Grid item md={3}>
                </Grid>
                <Grid item md={6}>
                    <Grid container justifyContent="center">
                        <Grid item md={3} container spacing={0} direction="column" alignItems="center" justifyContent="center">
                            <Typography component="div" variant="h6" color="#fff">
                                { (user.views_count>=1000) ? (user.views_count/1000).toFixed(1) +`k `: user.views_count}
                            </Typography>
                            <Typography component="div" variant="subtitle1" color={Theme.grayColor}>
                                Views
                            </Typography>
                        </Grid>
                        <Grid item md={3} container spacing={0} direction="column" alignItems="center" justifyContent="center">
                            <Typography component="div" variant="h6" color="#fff">
                                {user.likes_count}
                            </Typography>
                            <Typography component="div" variant="subtitle1" color={Theme.grayColor}>
                                Likes
                            </Typography>
                        </Grid>
                        
                            <Grid item md={3} container spacing={0} direction="column" alignItems="center" justifyContent="center" >
                                <Link component="button" sx={{textDecoration:"none"}} onClick={handleClickFollowers}>
                                    <Typography component="div" variant="h6" color="#fff">
                                        {user.followers_count>=1000 ? (user.followers_count/1000).toFixed(1)+`k` : user.followers_count}
                                    </Typography>
                                    <Typography component="div" variant="subtitle1" color={Theme.grayColor}>
                                        Followers
                                    </Typography>
                                </Link>
                            </Grid>
                        
                            <Grid item md={3} container spacing={0} direction="column" alignItems="center" justifyContent="center" >
                                <Link component="button" sx={{textDecoration:"none"}} onClick={handleClickFollowing}>
                                    <Typography component="div" variant="h6" color="#fff">
                                        {user.followed_count>=1000 ? (user.followed_count/1000).toFixed(1)+`k` : user.followed_count}
                                    </Typography>
                                    <Typography component="div" variant="subtitle1" color={Theme.grayColor}>
                                        Following
                                    </Typography>
                                </Link>
                            </Grid>
                        
                    </Grid>
                </Grid>
                <Grid item md={3}>
                </Grid>
            </Grid>
            <Grid item marginTop={3}>
                <Chip label={user.level ? user.level?.name : "Lvl 8888"} sx={{backgroundColor: user.level ? `#${user.level?.color}` : Theme.pink, color:"#fff"}}/>
            </Grid>
        </Grid> 
    )

}