import React, { useState, useEffect, useContext, Fragment, useRef} from 'react'
import { useTranslation } from 'react-i18next'
import { TextField, SvgIcon, IconButton, Grid, Avatar, RadioGroup, Radio, FormControl, FormControlLabel, FormLabel, Badge, InputAdornment  } from '@mui/material'
import { styled, alpha } from '@mui/material/styles'
import { useParams, useNavigate } from "react-router-dom"
import axios from 'axios'
import PageLayout from '../../Layout/PageLayout'
import TitlePageBar from '../../Layout/TitlePageBar'
import Theme from '../../config/Theme'
import GoBackButton from '../../components/GoBackButton'
import { AuthContext } from '../../redux/contexts/AuthContext'
import icons from '../../assets/icons'
import { isNull, isEmpty, isUndefined, capitalize, toUpper} from 'lodash'
import { getApiRoutes } from '../../config/Routes'


const photo = require('../../assets/photo_placeholder.png')
const redemptions = [
    { label: 'none', value: 'paypal' },
    { label: 'payoneer', value: 'upi' },
    // { label: 'paypal', value: 'paypal' },
]

export default() => {
    const { t } = useTranslation()
    const api = getApiRoutes()
    const params  = useParams()
    const navigate = useNavigate()
    const {user, token} = useContext(AuthContext)
    const  userToken = token || localStorage.getItem('token')
    const [errorsMessage, setErrorsMessage] = useState('')
    const formRef = useRef()
    const [imageAvatar, setImageAvatar] = useState(!isEmpty(user) ? user.data.photo : photo)
    const [location, setLocation] = useState('')
    const [name, setName] = useState('')
    const [userName, setUsername] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [bio, setBio] = useState('')
    const [facebook, setFacebook]= useState('')
    const [instagram, setInstagram]= useState('')
    const [snapchat, setSnapchat]= useState('')
    const [youTube, setYouTube]= useState('')
    const [redemption, setRedemption] = useState(redemptions[0].value)
    const [redemptionAddress, setRedemptionAddress] = useState('')
    const [startLoading, setStartLoading] = useState(false)
    const [isFocused, setIsFocused] = useState(false)

    // const loadProfileUser = () => {
    //     axios.get('https://stage.smilelive.app/api/users/' + params.id).then(response => {
    //         setUser(response.data.data)
    //     })
    // }
    
    // useEffect(() => {
    //     loadProfileUser()
    // }, [])

    useEffect(() => {
        if (!isEmpty(user)) {
            setImageAvatar(user.data.photo)
            setLocation(user.data.location)
            setName(user.data.name)
            setUsername(user.data.username)
            setEmail(user.data.email)
            setPhone(user.data.phone)
            setBio(user.data.bio)
            !isNull(user.data.redemption_mode) && setRedemption(user.data.redemption_mode)
            !isNull(user.data.redemption_address) && setRedemptionAddress(user.data.redemption_address)
        }
    },[user])
    
    const renderLeftComponent = () => <GoBackButton/>

    const renderRightComponent = () => {
        return(
        <StyledIconButton
            size="medium"
            aria-label="home"
            color="inherit"
            onClick={handleSubmit}
        >
            <SvgIcon>
                <path d={icons.ic_baseline_check_24} fill={Theme.grayColor}/>
            </SvgIcon>
        </StyledIconButton>
        )
    }

    const handleChangeAvatar = e => {
        setImageAvatar(URL.createObjectURL(e.target.files[0]))
    }

    const  handleRedemption = e => {
        console.log(e.target.value)
        setRedemption(e.target.value)
    }

    if (isUndefined(FormData.prototype.toObject)) {
        FormData.prototype.toObject = function () {
            const obj = {}
    
            for (var pair of this.entries()) {
                let key = pair[0]
                if (key.includes('true_flag_')) {
                    key = key.replace('true_flag_', '')
                    obj[key] = 1
                } else {
                    obj[key] = pair[1]
                }
            }
    
            return obj
        }
    }

    const handleSubmit = () => {
        if (startLoading) return;

        const formData = new FormData
       
       


        if(!isNull(location)) {
            formData.append('location', location)
        }
        formData.append('name', name)
        formData.append('username', userName)

        if(!isNull(phone)) formData.append('phone', phone)
        formData.append('email', email)

        if(!isNull(bio)) 
            formData.append('bio', bio)

        if(!isNull(redemptionAddress) && redemptionAddress != 'none') 
            formData.append('redemption_address', redemptionAddress)

        formData.append('redemption_mode', redemption)
               
        // console.log("formData=", formData.toObject())
        

        // if(!isNull(imageAvatar)) {
        //     if(imageAvatar != photo && !imageAvatar.uri)
        //         formData.append(`photo`, {
        //             uri:  imageAvatar,
        //             name: 'profilePicture',
        //             type: 'image/jpeg' // or your mime type what you want
        //         })
        //     else
        //         formData.append(`photo`, {
        //             uri:  imageAvatar.uri,
        //             name: 'profilePicture',
        //             type: 'image/jpeg' // or your mime type what you want
        //         })
        // }
        
        // formData.append(`photo`, imageAvatar.uri)

        axios({
            method: 'post',
            headers: {
                "Authorization":`Bearer ${userToken}`,
                //"Content-Type": "multipart/form-data",
                "Content-Type": "application/json",
                'Accept': '*/*'
            },
            url: api.profile.path,
            data: formData.toObject(),
        }).then(res => {
            console.log(res)
        }).catch(err => {
            
            Object.keys(err.response.data.errors).map((error, key) => {
                alert(err.response.data.errors[error][0])
                return;
            })
        }) 

        setStartLoading(false)
    }
    

    return (
        <PageLayout bgColor='#000'>
            <TitlePageBar title="Edit" showLeftComponent={true} leftComponent={renderLeftComponent} showRightComponent={true} rightComponent={renderRightComponent}/>
            {
                !isEmpty(user) &&
                <form ref={formRef}>
                <Grid container columnSpacing={1}>
                    <Grid item xs={12} style={{display:"flex", justifyContent:"center", marginBottom: 10}}>
                    
                        <Badge
                            overlap="circular"
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            badgeContent={
                            <Fragment>
                            <input
                                style={{ display: 'none' }}
                                id="upload-photo"
                                name="imageAvatar"
                                type="file"
                                accept="image/*"
                                onChange={handleChangeAvatar}
                            />
                            <label htmlFor="upload-photo">
                            
                                <StyledIconButton
                                    size="medium"
                                    aria-label="upload-photo"
                                    color="inherit"
                                    component="span"
                                >
                                    <SvgIcon>
                                        <path d={icons.ic_record_new} fill={Theme.yellowColor}/>
                                    </SvgIcon>
                                </StyledIconButton>
                                </label>
                                </Fragment>
                            }
                        >
                            <BorderAvatar alt={toUpper(user.data.email)} src={imageAvatar}/>
                        </Badge>
                        
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <CustomTextfield
                            margin="dense"
                            id="location"
                            label="Location"
                            variant="filled"
                            error={!!errorsMessage?.location}
                            helperText={errorsMessage?.location && t("errorLocation")}
                            name="location"
                            defaultValue={user.data.location}
                            onChange={(e) => setLocation(e.target.value)}
                            autoComplete="off"
                            InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <SvgIcon>
                                        <path d={icons.ic_baseline_my_location_24} fill={Theme.grayColor}/>
                                    </SvgIcon>
                                  </InputAdornment>
                                ),
                            }}
                        />
                        <CustomTextfield
                            // required
                            // autoFocus
                            margin="dense"
                            id="name"
                            label="Name"
                            fullWidth
                            variant="filled"
                            error={!!errorsMessage?.name}
                            helperText={errorsMessage?.name && t("errorName")}
                            name="name"
                            defaultValue={user.data.name}
                            onChange={(e) => setName(e.target.value)}
                            autoComplete="off"
                            InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <SvgIcon>
                                        <path d={icons.ic_baseline_person_24_gray} fill={Theme.grayColor}/>
                                    </SvgIcon>
                                  </InputAdornment>
                                ),
                            }}
                        />
                        <CustomTextfield
                            // required
                            // autoFocus
                            margin="dense"
                            id="username"
                            label="Username"
                            fullWidth
                            variant="filled"
                            error={!!errorsMessage?.username}
                            helperText={errorsMessage?.username && t("errorUserName")}
                            name="username"
                            defaultValue={user.data.username}
                            onChange={(e) => setUsername(e.target.value)}
                            autoComplete="off"
                            InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <SvgIcon>
                                        <path d={icons.ic_baseline_person_24_gray} fill={Theme.grayColor}/>
                                    </SvgIcon>
                                  </InputAdornment>
                                ),
                            }}
                        />
                        <CustomTextfield
                            required
                            // autoFocus
                            margin="dense"
                            id="email"
                            label="Email"
                            type="email"
                            fullWidth
                            variant="filled"
                            error={!!errorsMessage?.email}
                            helperText={errorsMessage?.email && t("errorEmail")}
                            name="email"
                            value={user.data.email}
                            onChange={(e) => setEmail(e.target.value)}
                            autoComplete="off"
                            InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <SvgIcon>
                                        <path d={icons.ic_baseline_email_24_gray} fill={Theme.grayColor}/>
                                    </SvgIcon>
                                  </InputAdornment>
                                ),
                            }}
                        />
                        <CustomTextfield
                            required
                            margin="dense"
                            id="phone"
                            label="Phone"
                            placeholder='+XXXXXXXXXXX'
                            type="phone"
                            fullWidth
                            variant="filled"
                            error={!!errorsMessage?.phone}
                            helperText={errorsMessage?.phone && t("errorPhone")}
                            name="phone"
                            defaultValue={user.data.phone}
                            onChange={(e) => setPhone(e.target.value)}
                            autoComplete="off"
                            InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <SvgIcon>
                                        <path d={icons.ic_baseline_phone_24_gray} fill={Theme.grayColor}/>
                                    </SvgIcon>
                                  </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <CustomTextfield
                            margin="dense"
                            id="bio"
                            label="Bio"
                            variant="filled"
                            error={!!errorsMessage?.bio}
                            helperText={errorsMessage?.bio && t("errorBio")}
                            name="bio"
                            defaultValue={user.data.bio}
                            onChange={(e) => setBio(e.target.value)}
                            autoComplete="off"
                        />
                        <CustomTextfield
                            margin="dense"
                            id="fcb"
                            label="Facebook"
                            variant="filled"
                            error={!!errorsMessage?.facebook}
                            helperText={errorsMessage?.facebook && t("errorFacebook")}
                            name="facebook"
                            defaultValue={user.data.facebook}
                            onChange={(e) => setFacebook(e.target.value)}
                            autoComplete="off"
                            InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <SvgIcon>
                                        <path d={icons.ic_facebook_gray} fill={Theme.grayColor}/>
                                    </SvgIcon>
                                  </InputAdornment>
                                ),
                            }}
                        />
                        <CustomTextfield
                            margin="dense"
                            id="instagram"
                            label="Instagram"
                            variant="filled"
                            error={!!errorsMessage?.instagram}
                            helperText={errorsMessage?.instagram && t("errorInstagram")}
                            name="instagram"
                            defaultValue={user.data.instagram}
                            onChange={(e) => setInstagram(e.target.value)}
                            autoComplete="off"
                            InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <SvgIcon viewBox='0 0 48 48'>
                                        <path d={icons.ic_instagram} fill={Theme.grayColor}/>
                                    </SvgIcon>
                                  </InputAdornment>
                                ),
                            }}
                            
                        />
                        <CustomTextfield
                            margin="dense"
                            id="snapchat"
                            label="Snapchat"
                            variant="filled"
                            error={!!errorsMessage?.snapchat}
                            helperText={errorsMessage?.snapchat && t("errorSnapchat")}
                            name="snapchat"
                            defaultValue={user.data.snapchat}
                            onChange={(e) => setSnapchat(e.target.value)}
                            autoComplete="off"
                            InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <SvgIcon viewBox='0 0 48 48'>
                                        <path d={icons.ic_snapchat} fill={Theme.grayColor}/>
                                    </SvgIcon>
                                  </InputAdornment>
                                ),
                            }}
                        />
                        <CustomTextfield
                            margin="dense"
                            id="youtube"
                            label="Youtube"
                            variant="filled"
                            error={!!errorsMessage?.youTube}
                            helperText={errorsMessage?.youtube && t("errorYoutube")}
                            name="youtube"
                            defaultValue={user.data.youtube}
                            onChange={(e) => setYouTube(e.target.value)}
                            autoComplete="off"
                            InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <SvgIcon>
                                        <path d={icons.ic_youtube_gray} fill={Theme.grayColor}/>
                                    </SvgIcon>
                                  </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormControl>
                            <FormLabel id="redemption" sx={{color: Theme.grayColor, fontSize:13, '&.Mui-focused': { color: Theme.grayColor}}}>Redemption mode</FormLabel>
                            <RadioGroup row
                                aria-labelledby="redemption"
                                defaultValue={isNull(user.data.redemption_mode) ? redemption : user.data.redemption_mode}
                                name="redemption"
                                onChange={handleRedemption}
                                sx={{color:Theme.grayColor, fontSize:13}}
                            >
                                { redemptions.map((redemption, idx) => {
                                    return (
                                        <FormControlLabel 
                                            key={idx} 
                                            value={redemption.value} 
                                            control={
                                                <Radio 
                                                    sx={{
                                                        color:Theme.grayColor, 
                                                        fontSize:13, 
                                                        '&.Mui-checked': { color: Theme.greenColor}
                                                    }}/>
                                            } 
                                            label={capitalize(redemption.label)}
                                        />
                                    )})
                                }
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <CustomTextfield
                            margin="dense"
                            id="redemptionAddress"
                            label={redemption ==='paypal' ? 'Redemption address' : 'Payoneer ID'}
                            variant="filled"
                            error={!!errorsMessage?.redemptionAddress}
                            helperText={errorsMessage?.redemptionAddress && t("redemptionAddress")}
                            name="redemptionAddress"
                            defaultValue={isNull (user.data.redemption_address) ? redemptionAddress : user.data.redemption_address}
                            onChange={(e) => setRedemptionAddress(e.target.value)}
                            autoComplete="off"
                        />
                    </Grid>
                </Grid>
                </form>
            }
        </PageLayout>
    )
}

const CustomTextfield = styled(TextField)(() => ({
    width: '100%',
    fontSize:13,
    '& .MuiFilledInput-root':{
        backgroundColor:Theme.grayDarkColor,
        borderTopLeftRadius:4,
        borderTopRightRadius:4,
        borderBottom: "1px solid",
        borderBottomColor:Theme.grayColor
    },
    '& .MuiFilledInput-root:hover': {
        backgroundColor:Theme.grayDarkColor,
    },
    '& .MuiFilledInput-root.Mui-focused': {
        backgroundColor:Theme.grayDarkColor,
        borderBottom: "1px solid #fdf800",
    },
  
    '& label.Mui-focused': {
        color: Theme.yellowColor,
    },
    '& .MuiFilledInput-root:after':{
        borderBottom: "1px solid #fdf800",
    },
    '& .MuiInputBase-input':{
        color: Theme.whiteColor,
        fontSize:13,
        backgroundColor:Theme.grayDarkColor,
    },
    '& .MuiInputBase-input:focus':{
        color: Theme.whiteColor,
        fontSize:13,
        backgroundColor:Theme.grayDarkColor
    },
    '& label': {
        color: Theme.grayColor,
        fontSize:13,
    },
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    '&:hover': {
        backgroundColor: alpha(Theme.whiteColor, 0.15),
    },
}));

const BorderAvatar = styled(Avatar)(() => ({
    width: 100, 
    height: 100,
    border:"2px solid",
    borderColor: Theme.yellowColor,
    '& img': {
        borderRadius:'50%'
    }
}));


