import React, { useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Typography, SvgIcon, Link, Radio, RadioGroup, Button } from '@mui/material'
import FormControlLabel, {FormControlLabelProps} from '@mui/material/FormControlLabel'
import { styled } from '@mui/material/styles'
import PageLayout from '../../Layout/PageLayout'
import TitlePageBar from '../../Layout/TitlePageBar'
import {getApiRoutes} from '../../config/Routes'
import Theme from '../../config/Theme'
import icons from '../../assets/icons'
import { AuthContext } from '../../redux/contexts/AuthContext'
import LocaleDialog from './LocaleDialog'
import axios from 'axios'
import ConfirmDialog from '../../components/ConfirmDialog'
import LogoutIcon from '@mui/icons-material/Logout'
import NoAccountsIcon from '@mui/icons-material/NoAccounts'
import GoBackButton from '../../components/GoBackButton'


const logo = require('../../assets/logo.jpg')
const colorSvgIcons = Theme.whiteColor
const textColor = Theme.whiteColor

const Settings = () => {
    const api = getApiRoutes()
    const { t } = useTranslation()
    const languages = [
        { label: 'English', value: 'en' },
        { label: 'Romanian', value: 'ro-RO' },
    ]
    const faqUrl = 'https://smilelive.app/faq.html'
    const websiteUrl = 'https://smilelive.app'
    const email = 'support@smilelive.app'
    const privacyUrl = 'https://smilelive.app/policy.html'
    // const tosUrl = 'https://smilelive.app/tos.html'
    const tosUrl = 'terms-and-conditions'

    const { user, signOut } = useContext(AuthContext)
    const [showDialog, setShowDialog] = useState(false)
    const [language, setLanguage] = useState(languages[0].label)
    const [data, setData] = useState({})
    const [showConfirmDialog, setShowConfirmDialog] = useState(false)

    const renderLeftComponent = () => <GoBackButton/>

    const toggleDialog = () => {
        setShowDialog(!showDialog)
    }

    const handleChangeLanguage = e => {
        setLanguage(e.target.value)
    }

    const handleApply = () => {
        // update language
    }

    const toggleConfirmDialog = () => {
        setShowConfirmDialog(!showConfirmDialog)
    }

    const handleOk = () => {
        signOut()
        toggleConfirmDialog()
    }

    const renderContentDialog = () => {
        return (
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={language}
                name="language"
                onChange={handleChangeLanguage}
            >
                {
                    languages.map(language => {
                        return (
                            <StyledFormControlLabel
                                key={language.value} 
                                value={language.value} 
                                control={<Radio sx={{color:Theme.greenColor}}/>}
                                label={t(language.label)}
                                labelPlacement="end"
                                color="success"
                            />
                        )
                    })
                }
            </RadioGroup>
        )
    }

    const renderContentConfirmDialog = () => {
        return (
            <Box margin={3} textAlign="center">
                <Typography color={textColor} sx={{fontSize: 18}}>Are you sure you want to logout?</Typography>
            </Box>
        )
    }

  
    return (
        <PageLayout bgColor='#000'>
            <LocaleDialog {...{showDialog, toggleDialog, renderContentDialog, handleApply}} />
            <ConfirmDialog {...{showConfirmDialog, toggleConfirmDialog, renderContentConfirmDialog, handleOk}} />

            <TitlePageBar title="About" showLeftComponent={true} leftComponent={renderLeftComponent}/>

            <Box sx={{display:"flex",  flex: 1, flexDirection:"column"}}>               
                <CustomBox>
                    <Row>
                        <img alt="Smile" src={logo} width={50} height={50} />
                        <Typography fontSize={30} color={textColor} marginLeft={2}>Smile</Typography>
                    </Row>
                    <Row>
                        <Box sx={{display: "flex", flexDirection:"row"}}>
                            <Box width={50} marginRight={2} textAlign="center">
                                <SvgIcon>
                                    <path d={icons.ic_about_version} fill={colorSvgIcons}/>
                                </SvgIcon>
                            </Box>

                            <Box>
                                <Typography variant="body2" color={textColor}>Version</Typography>
                                <Typography variant="caption" color={Theme.grayColor}>3</Typography>
                            </Box>
                        </Box>
                    </Row>
                </CustomBox>

                <CustomBox>
                    <Row>
                        <Typography variant="caption" color={textColor} >Settings</Typography>
                    </Row>
                    <Row>
                        <Box sx={{display: "flex", flexDirection:"row"}} onClick={toggleDialog}>
                            <Box width={50} marginRight={2} textAlign="center">
                                <SvgIcon>
                                    <path d={icons.ic_language} fill={colorSvgIcons}/>
                                </SvgIcon>
                            </Box>
                            <Typography variant="body2" color={textColor}>Locale</Typography>
                        </Box>
                    </Row>
                    <Row>
                        <Box sx={{display: "flex", flexDirection:"row"}}>
                            <Box width={50} marginRight={2} textAlign="center">
                                <SvgIcon>
                                    <path d={icons.ic_baseline_language_24} fill={colorSvgIcons}/>
                                </SvgIcon>
                            </Box>

                            <Typography variant="body2" color={textColor}>Preffered Language</Typography>
                        </Box>
                    </Row>
                </CustomBox>

                <CustomBox>
                    <Row>
                        <Typography variant="caption" color={textColor} >Support</Typography>
                    </Row>
                    <Row>
                        <Link href={faqUrl}>
                            <Box sx={{display: "flex", flexDirection:"row"}}>
                                <Box width={50} marginRight={2} textAlign="center">
                                    <SvgIcon>
                                        <path d={icons.ic_about_faq} fill={colorSvgIcons}/>
                                    </SvgIcon>
                                </Box>
                                <Box>
                                    <Typography variant="body2" color={textColor}>F.A.Q.</Typography>
                                    <Typography variant="caption" color={Theme.grayColor}>{faqUrl}</Typography>
                                </Box>
                            </Box>
                        </Link>
                    </Row>
                    <Row>
                        <Link href={websiteUrl}>
                        <Box sx={{display: "flex", flexDirection:"row"}}>
                            <Box width={50} marginRight={2} textAlign="center">
                                <SvgIcon>
                                    <path d={icons.ic_about_website} fill={colorSvgIcons}/>
                                </SvgIcon>
                            </Box>

                            <Box>
                                <Typography variant="body2" color={textColor}>Website</Typography>
                                <Typography variant="caption" color={Theme.grayColor}>{websiteUrl}</Typography>
                            </Box>
                        </Box>
                        </Link>
                    </Row>
                    <Row>
                        <a href={`mailto:${email}`}>
                        <Box sx={{display: "flex", flexDirection:"row"}}>
                            <Box width={50} marginRight={2} textAlign="center">
                                <SvgIcon>
                                    <path d={icons.ic_about_email} fill={colorSvgIcons}/>
                                </SvgIcon>
                            </Box>

                            <Box>
                                <Typography variant="body2" color={textColor}>Email</Typography>
                                <Typography variant="caption" color={Theme.grayColor}>{email}</Typography>
                            </Box>
                        </Box>
                        </a>
                    </Row>
                </CustomBox>

                <CustomBox>
                    <Row>
                        <Typography variant="caption" color={textColor} >Legal</Typography>
                    </Row>
                    <Row>
                        <Link href={privacyUrl}>
                            <Box sx={{display: "flex", flexDirection:"row"}}>
                                <Box width={50} marginRight={2} textAlign="center">
                                    <SvgIcon>
                                        <path d={icons.ic_baseline_link_24} fill={colorSvgIcons}/>
                                    </SvgIcon>
                                </Box>
                                <Box>
                                    <Typography variant="body2" color={textColor}>Privacy Policy</Typography>
                                    <Typography variant="caption" color={Theme.grayColor}>{privacyUrl}</Typography>
                                </Box>
                            </Box>
                        </Link>
                    </Row>
                    <Row>
                        <Link href={tosUrl}>
                        <Box sx={{display: "flex", flexDirection:"row"}}>
                            <Box width={50} marginRight={2} textAlign="center">
                                <SvgIcon>
                                    <path d={icons.ic_baseline_link_24} fill={colorSvgIcons}/>
                                </SvgIcon>
                            </Box>

                            <Box>
                                <Typography variant="body2" color={textColor}>Terms of Use</Typography>
                                <Typography variant="caption" color={Theme.grayColor}>{tosUrl}</Typography>
                            </Box>
                        </Box>
                        </Link>
                    </Row>
                </CustomBox>
                <CustomBox>
                    <Row>
                        <Box sx={{display: "flex", flexDirection:"row"}} >
                            <Box width={50} marginRight={2} justifyContent="center" alignItems="center" sx={{display: "flex"}}>
                                <LogoutIcon sx={{color:"#fff"}} size="small"/>
                            </Box>
                            
                            <Button sx={{color: textColor}} onClick={toggleConfirmDialog}>
                                <Typography variant="body2" color={textColor}>Logout</Typography>
                            </Button>
                        </Box>
                    </Row>
                    <Row>
                        <Box sx={{display: "flex", flexDirection:"row"}}>
                            <Box width={50} marginRight={2} justifyContent="center" alignItems="center" sx={{display: "flex"}}>
                                <NoAccountsIcon sx={{color:"#fff"}} size="small"/>
                            </Box>
                            
                            <Button sx={{color: textColor}} onClick={toggleConfirmDialog}>
                                <Typography variant="body2" color={textColor}>Disable Account</Typography>
                            </Button>
                        </Box>
                    </Row>
                </CustomBox>
            </Box>
        </PageLayout>
    )
}

export default Settings

const CustomBox = styled(Box)(({ theme }) => ({
    display:"flex",
    flexDirection:"column",
    width:'100%',
    maxWidth:500, 
    margin: "auto",
    border: "1px solid", 
    borderColor: Theme.grayDarkColor,
    borderRadius: 4,
    color: Theme.yellowColor,
    padding: 10,
    marginTop:10,
    marginBottom:10,
    a: {
        color: Theme.yellowColor,
        textDecoration: "none"
    },
}));

const Row = styled(Box)(({ theme }) => ({
    display:"flex", 
    flexDirection:"row", 
    alignItems:"center",
    marginBottom:10,
    a: {
        color: Theme.yellowColor,
        textDecoration: "none"
    },
}));


const StyledFormControlLabel = styled(FormControlLabel)(({ theme, checked }) => ({
        color: "#fff",
        '.MuiFormControlLabel-label': checked && {
            color: Theme.greenColor
        },
}));





