import React, { useState, Fragment, useContext } from 'react'
import { Grid, Box, Typography, Divider, Button,  } from '@mui/material'
import { styled, alpha } from '@mui/material/styles'
import Theme from "../../config/Theme"
import PageLayout from '../../Layout/PageLayout'
import TitlePageBar from '../../Layout/TitlePageBar'
import {getApiRoutes} from '../../config/Routes'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import GoBackButton from '../../components/GoBackButton'
import CustomCircularProgress from '../../components/Loader'
import {isEmpty} from 'lodash'
import { useEffect } from 'react'
import { AuthContext } from '../../redux/contexts/AuthContext'


export default () => {
    const api = getApiRoutes()
    const navigate = useNavigate()
    const { token } = useContext(AuthContext)
    const  userToken = token || localStorage.getItem('token')
    const [credits, setCredits] = useState([])
  
    const renderLeftComponent = () => <GoBackButton/>

    const loadCredits = () => {
        axios.defaults.headers["Authorization"] = `Bearer ${userToken}`
        axios({
            method: api.credits.method,
            url: api.credits.path+ '?page=1'
        }).then(response => {
            setCredits(response.data.data)
        }).catch((error) => {
            console.log(error.message);
        });
    }

    const handleBuy = (credit) => () => {
        axios({
            method: 'post',
            url: api.wallet.recharge.path,   
            data: {
                credit: credit.id,
                isWeb: 1
            }
        }).then(function(response){
            //console.log("recharge", response.data)
            window.open(response.data.data.redirect) /* redirect catre pagina de stripe*/
            /* dupa ce am raspuns de la stripe "https://stage.smilelive.app/wallet/response/8b30f895-03d5-4763-933c-318b8c1ca9db" navigation back to wallet screen */
        })
    }

    useEffect(() => {
        loadCredits()
    },[])

    return (
        <PageLayout bgColor='#000'>
            <TitlePageBar title="Recharge" showLeftComponent={true} leftComponent={renderLeftComponent}/>

            <Box sx={{display:"flex",  flex: 1, flexDirection:"column"}}>
                {isEmpty(credits) ?
                <Box sx={{display:"flex", justifyContent:"center", alignItems:"center", flex: 1}}>
                    <CustomCircularProgress/>
                </Box>
                :
                credits.map((credit,idx) => {
                    return (
                        <Fragment key={idx}>
                            <Grid container sx={{display:"flex", alignItems:"center"}}>
                                <Grid item xs={12} sm={4} sx={{display:"flex", alignItems:"center"}} justifyContent="center">
                                    <Typography color="#fff">{credit.title} x{credit.value}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Typography variant="h6" color="#fff" textAlign="center"> {credit.price}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={4} textAlign="center">
                                    <StyledButton variant="contained" onClick={handleBuy(credit)}>Buy</StyledButton>
                                </Grid>
                            </Grid>
                            <Divider component="li" sx={{borderColor:Theme.grayDarkColor, marginTop: 2, marginBottom: 2}}/>
                        </Fragment>
                    )
                })
            }
            </Box>
        </PageLayout>
    )
}

const StyledButton = styled(Button)(() => ({
    backgroundColor: Theme.grayDarkColor,
    color: Theme.grayColor,
    borderRadius: 5,
    paddingLeft: 20,
    paddingRight: 20,
    '&:hover': {
        backgroundColor: alpha(Theme.whiteColor, 0.15),
    },
}));

