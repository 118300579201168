import React, { useState, useEffect, useContext } from 'react'
import {Box, SvgIcon, IconButton } from '@mui/material'
import { styled, alpha } from '@mui/material/styles'
import Theme from '../../config/Theme'
import PageLayout from '../../Layout/PageLayout'
import TitlePageBar from '../../Layout/TitlePageBar'
import GoBackButton from '../../components/GoBackButton'
import icons from '../../assets/icons'
import { useNavigate } from 'react-router-dom'
import {getApiRoutes} from '../../config/Routes'
import axios from 'axios'
import NotificationsList from './NotificationsList'
import { AuthContext } from '../../redux/contexts/AuthContext'
import { isEmpty } from 'lodash'
import CustomCircularProgress from '../../components/Loader'

const Notifications = () => {
    const {user} = useContext(AuthContext)
    const navigate = useNavigate()
    const api = getApiRoutes()
    const [page, setPage] = useState(1)
    const [notifications, setNotifications] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const handleClick = (to) => () => {
        navigate(to)
    }

    const renderLeftComponent = () => <GoBackButton/>

    const renderRightComponent = () => {
        return(
        <StyledIconButton
            size="medium"
            aria-label="home"
            color="inherit"
            onClick={handleClick('/conversations')}
        >
            <SvgIcon>
                <path d={icons.ic_message} fill={Theme.grayColor}/>
            </SvgIcon>
        </StyledIconButton>
        )
    }

    const loadNotifications = () => {
        setIsLoading(true)
        axios({
            method: api.notifications.method,
            url: api.notifications.path + '?user=' + user.data.id
        }).then(response => {
            setNotifications(response.data.data)
            setIsLoading(false)
        })
    }
       

    useEffect(() => {
        !isEmpty(user) && loadNotifications()
    },[])

    useEffect(() => {
        !isEmpty(user) && loadNotifications()
    },[user])


    return (
        <PageLayout bgColor='#000'>
            <TitlePageBar title="Notifications" showLeftComponent={true} leftComponent={renderLeftComponent} showRightComponent={true} rightComponent={renderRightComponent}/>
            {isLoading ?
            <Box sx={{display:"flex",  flex: 1, flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
                <CustomCircularProgress/>
             </Box>
             :
            <Box sx={{display:"flex",  flex: 1, flexDirection:"column"}}>
               <NotificationsList {...{notifications}}/>
            </Box>
            }
        </PageLayout>
    )
}

export default Notifications

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    '&:hover': {
        backgroundColor: alpha(Theme.whiteColor, 0.15),
    },
}));