import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Typography, IconButton } from '@mui/material'
import { styled, alpha, createTheme, ThemeProvider } from '@mui/material/styles'
import Theme from '../config/Theme'
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp'
import PageLayout from '../Layout/PageLayout'
import TitlePageBar from '../Layout/TitlePageBar'
import GoBackButton from '../components/GoBackButton'

export default() => {
    const { t } = useTranslation()
    const renderLeftComponent = () => <GoBackButton/>

    const handleGoToTop = () => {
        window.scroll(0,0)
    }

    return (
        <PageLayout bgColor='#000'>
            <TitlePageBar title="Terms and Conditions" showLeftComponent={true} leftComponent={renderLeftComponent}/>
            <Box color="#fff">
                <Typography variant="h5" textAlign="center">Application Provision Terms and Conditions<br/> Smile Live Stream<br/><br/></Typography>
                <Typography variant="p" fontWeight="300">We recommend that you read this document in its entirety, including the Terms and conditions of providing the Smile Live Stream application, before deciding whether
                you accept them and continue the registration process.<br/><br/></Typography>
                <Typography variant="p">If you do not agree with any of the provisions of the Terms and Conditions,please do not access, browse or use the Application.<br/><br/></Typography>
                <Typography variant="p">If at any time after registering on the Application you decide that you no longer agree with the Terms and Conditions, please request account deactivation at
                contact@smilelive.app<br/><br/></Typography>
                <Typography variant="p">Contents:<br/>
                    <a href="#1" style={{color:"#fff"}}>1. Definitions;</a><br/>
                    <a href="#2" style={{color:"#fff"}}>2. About us;</a><br/>
                    <a href="#3" style={{color:"#fff"}}>3. How and under what conditions you can use the Smile Live Stream application;</a><br/>
                    <a href="#4" style={{color:"#fff"}}>4. Processing of personal data;</a><br/>
                    <a href="#5" style={{color:"#fff"}}>5. The main features of the application and its correct use;</a><br/>
                    <a href="#6" style={{color:"#fff"}}>6. What you are forbidden to do, as a user of the application;</a><br/>
                    <a href="#7" style={{color:"#fff"}}>7. Protection of copyright and user-generated content;</a><br/>
                    <a href="#8" style={{color:"#fff"}}>8. The end of the contract;</a>
                </Typography>
                <br/><br/>

                <Typography id="1" variant="p">1. Definitions<br/><br/></Typography>
                <Typography variant="p" fontWeight="300">
                    <Typography variant="span" fontWeight="400">The application –</Typography> hereinafter referred to as the electronic platform called Smile Live
                    Stream app (or Smile Live Stream).<br/>
                    <Typography variant="span" fontWeight="400">User –</Typography> any physical person who is at least 16 years of age and who accesses the Smile
                    Live Stream application by creating a user account as detailed in these Terms<br/>
                    <Typography variant="span" fontWeight="400">Account –</Typography> main set of login data, consisting of an e-mail address and password (or login
                    with a Facebook account, phone number via SMS code, Gmail account), which allows
                    the user to access the services provided by <Typography variant="span" fontWeight="400">SMILE LIVE STREAM SRL </Typography>through the
                    <Typography variant="span" fontStyle="italic"> Smile Live Stream app.<br/></Typography>
                    <Typography variant="span" fontWeight="400">Services provided by the Smile Live Stream app</Typography> - refers to all the facilities made
                    available to the user through the application, even if some of them are the property of
                    third-party providers vis-à-vis <Typography variant="span" fontWeight="400">SMILE LIVE STREAM SRL</Typography>
                </Typography>
                <br/><br/>

                <Typography id="2" variant="p" fontWeight="400">2. About us<br/><br/></Typography>
                <Typography variant="p" fontWeight="300">
                    <Typography variant="span" fontWeight="400">2.1. SMILE LIVE STREAM SRL</Typography>, with headquarters in Bucharest, Str. Liviu Rebreanu,
                        No. 46-58, Tronsonul (block) No. Iii, Room 1, Sc.E, Floor 6, Ap. 61, Sector 3, registered
                        at the Office of the Trade Registry next to the Bucharest Court with no. J40/454/2022,
                        CIF 45464132, makes available to you the Smile Live Stream app, hereinafter referred to
                        as the application.<br/><br/>
                    <Typography variant="span" fontWeight="400">2.2. Smile Live Stream app is a short</Typography> - form video live streaming and sharing platform.
                        It is particularly important that you read the terms of service to know the rules that
                        govern the relationship between us, which are an agreement between you and us
                        regarding the terms and conditions under which you can access and use our platform.    
                </Typography>
                <br/><br/>
                
                <Typography id="3" variant="p">3. How and under what conditions you can use the Smile Live Stream app<br/><br/></Typography>
                <Typography variant="p" fontWeight="300">
                    <Typography variant="span" fontWeight="400">3.1.</Typography> The application can only be used after completing the registration steps, for
                        this the user must fulfill the following:<br/>
                        - to be at least 16 years old;<br/>
                        - to completely complete the mandatory fields of the registration form;<br/>
                        - to accept these Terms and Conditions;<br/>
                        - to accept the Personal Data Processing Policy;<br/>
                        - to accept the Cookie Policy;<br/>
                        - to validly complete all identification steps.<br/>
                        - The user`s account must not have been previously disabled for violating the law or
                        any of our policies.<br/><br/>

                    <Typography variant="span" fontWeight="400">3.2.</Typography>  The user guarantees that all information regarding his identity and capacity,
                        provided in the registration forms in the Application and for the provision of services
                        through the Application are true, correct, accurate and complete. In addition, the user
                        undertakes to keep his personal details updated.<br/><br/>
                        <Typography variant="span" fontWeight="400">3.3.</Typography> When registering in the Application, users must choose their user code and
                        password or log in using one of the application login methods (login via Facebook
                        account, Gmail account, phone number and SMS code). These are strictly confidential,
                        personal and non-transferable.<br/><br/>

                    <Typography variant="span" fontWeight="400">3.4.</Typography> The user undertakes not to divulge the details of the personal account 
                    and not to allow access to it to third parties. Users will be solely responsible for any use
                    of these details or the services on the Application that could be made by third parties,
                    including the statements and/or contents entered in the Application or any action carried
                    out by using their user code or access password. <br/><br/>

                    <Typography variant="span" fontWeight="400">3.5.</Typography> The user has the obligation to secure access to his own account opened in the application
                    , especially since unauthorized access to it and/or loss of the phone/device from which the application is accessed and/or failure to secure them could
                    lead to access to the Smile Stream Live app account and of the content of this account,
                    including the uploading of video content by unauthorized persons. 
                </Typography>
                <br/><br/>

                <Typography id="4" variant="p">4. Processing of personal data<br/><br/></Typography>
                <Typography variant="p" fontWeight="300">
                    <Typography variant="span" fontWeight="400">4.1.</Typography> The terms and conditions are completed with the Personal Data Processing
                    Policy and the Cookie Policy.<br/><br/>

                    <Typography variant="span" fontWeight="400">4.2.</Typography> The User accepts and agrees that the Application will transmit the User&#39;s
                    personal data to the Third Party Providers if the User intends to access the services
                    offered by them. Voluntary access by the User to Third Party Providers through the
                    Application has the value of an express request and consent to the transmission of data to
                    the latter.<br/><br/>

                    <Typography variant="span" fontWeight="400">4.3.</Typography> The User is recommended to consult the privacy policy of the Third Party
                    Providers.<br/><br/>

                    <Typography variant="span" fontWeight="400">4.4.</Typography> The application does not request or store sensitive personal data such as:<br/>
                    - ethnic or racial origin;<br/>
                    - political opinions;<br/>
                    - religious confession or philosophical beliefs;<br/>
                    - union membership;<br/>
                    - genetic data processing;<br/>
                    - the processing of biometric data for the unique identification of a natural person;<br/>
                    - the health;<br/>
                    - sex life or sexual orientation.
                </Typography>
                <br/><br/>

                <Typography id="5" variant="p">5. The main features of the application and its correct use<br/><br/></Typography>
                <Typography variant="p" fontWeight="300">
                <Typography variant="p" fontWeight="400">5.1.</Typography> Through the application you can make your own clips, publish and share short
                videos created by other users and at the same time you can interact with other users.<br/><br/>

                <Typography variant="p" fontWeight="400">5.2.</Typography> You must be at least 16 years old to use our services.<br/><br/>

                <Typography variant="p" fontWeight="400">5.3.</Typography> You can use the Smile Live Stream app through the mobile app.<br/><br/>

                <Typography variant="p" fontWeight="400">5.4.</Typography> So, the Smile Live Stream app allows you to create and share videos, watch
                videos created by other users and have the ability to interact with them in writing.<br/><br/>

                <Typography variant="span" fontWeight="400" fontStyle="italic">5.4.1.</Typography> Once an account has been created on the application, the user can
                record / upload their own videos and is solely responsible for their content (both video and audio / text), including the respect of their copyright<br/><br/>

                <Typography variant="span" fontWeight="400" fontStyle="italic">5.4.2.</Typography> Also, accepting to use the application even only as a user who only views/ listens 
                to the video content uploaded by other users, each individual user assumes the fact that there may be content not yet reported, which they may view / hear 
                and which may affect emotionally<br/><br/>

                <Typography variant="span" fontWeight="400" fontStyle="italic">5.4.3.</Typography> Each user can complain about the content both through the application
                and by sending an e-mail to the <a href="mailto=contact@smilelive.app" style={{color:"#fdf800"}}>contact@smilelive.app</a> address, and the deadline for
                taking over and blocking / solving the situation can be different from case to case,
                starting from a few minutes, up to in a few days (depends on the severity, the
                interpretation of the respective audio / video content, etc.)<br/><br/>

                <Typography variant="p" fontWeight="400">5.5.</Typography> The entire team of the application provider strives to provide the application without 
                significant interruptions by constantly improving its quality. However, the service may be completely or partially unavailable for certain periods
                of time due to planned or unplanned maintenance outages or technical difficulties.<br/><br/>

                <Typography variant="p" fontWeight="400">5.6.</Typography> Accessing and using the application implies tacit agreement regarding the acceptance and 
                observance of these Conditions and also regarding the fact that you are at least 16 years old and you will use the application in the spirit of good faith
                only for the purposes for which it was created.<br/><br/>

                <Typography variant="p" fontWeight="400">5.7.</Typography> In the event that these Terms and Conditions will be modified as a result of changes in the operation
                of the application or as a result of legislative changes, notifications may not be sent, but the content of the documentation related to the
                Terms and Conditions of Use will be adapted as soon as possible.<br/><br/>

                <Typography variant="p" fontWeight="400">5.8.</Typography> Therefore, we recommend that you regularly consult the Terms and Conditions to check whether 
                such changes have occurred.<br/><br/>

                <Typography variant="p" fontWeight="400">5.9.</Typography> If you do not agree to these Terms, you must stop accessing or using the Application 
                and close your account.<br/><br/>

                <Typography variant="p" fontWeight="400">5.10.</Typography> When creating your user account, it is absolutely mandatory that you provide correct
                and up-to-date information.<br/><br/>

                <Typography variant="p" fontWeight="400">5.11.</Typography> It is also very important that you keep your account password
                confidential and do not disclose it to any third party.<br/><br/>

                <Typography variant="p" fontWeight="400">5.12.</Typography> If you know or suspect that any third party knows your password or has
                accessed your account, you must notify us immediately and also change it.<br/><br/>

                <Typography variant="p" fontWeight="400">5.13.</Typography> We reserve the right to temporarily or permanently suspend access to the Application or 
                impose certain limitations if we have reasonable grounds to believe that you are violating the terms of these Terms or any applicable laws orregulations;<br/><br/>

                <Typography variant="p" fontWeight="400">5.14.</Typography> The application interface as well as all the information posted on it are
                the property of SMILE LIVE STREAM SRL and its partners, which makes any use
                of this information under the content of software, images, text, graphics, logos, etc.,
                other than as regulated in this document, is strictly prohibited.<br/><br/>

                <Typography variant="p" fontWeight="400">5.15.</Typography> The users of the application do not have any rights to the audio
                recordings and the musical works embedded in them that are made available in the
                application, at the same time transferring all copyrights for them without having
                any claim of any kind.<br/><br/>
                <Typography variant="p" fontWeight="400">5.16.</Typography> Users accept and agree that when viewing content provided by other
                users through the Application, they do so in full knowledge. The content of our app
                is for entertainment.
                </Typography>
                <br/><br/>

                <Typography id="6" variant="p">6. The main features of the application and its correct use<br/><br/></Typography>
                <Typography variant="p" fontWeight="300">
                <Typography variant="p" fontWeight="400">6.1.</Typography>As a user of the application, you are prohibited from taking the following actions:<br/><br/>
                - to use the Application for advertising purposes or for launching commercial requests
                unless such activities are explicitly authorized by us in advance;<br/><br/>
                - to use the application if you are not at least 16 years old or if you do not express your
                agreement with these Conditions;<br/><br/>
                - copy, modify, adapt, translate or create any derivative works based on the services
                made available through the application, including any files, tables or documents;<br/><br/>
                - identify or attempt to identify the source code, algorithms, methods or techniques
                incorporated in the Application or any derivative works thereof;<br/><br/>
                - use the Application, without our express prior written consent, for any commercial or
                unauthorized purpose;<br/><br/>
                - incorporate the Platform or any portions thereof into any other program or product, in
                which case we reserve the right to suspend accounts or limit access to the Services at our
                sole discretion;<br/><br/>
                - use any automated system or software, whether operated by a third party or otherwise,
                to extract data from the Application for commercial purposes;<br/><br/>
                - use or attempt to use another person&#39;s account, or create false identities;<br/><br/>
                - distribute, copy, transfer or sell, in whole or in part, any of the information, data,
                videos found on the application or any derivative works thereof;<br/><br/>
                - impersonate another person or entity, make false statements or otherwise misrepresent
                your own identity or affiliation with any entity;<br/><br/>
                - intimidate or harass other people or promote material with explicit sexual content,
                violence or discrimination based on race, gender, religion, nationality, disability, sexual
                orientation or age, etc.;<br/><br/>
                - use the Application in a way that could create a conflict of interest between the
                parties;<br/><br/>
                - use the Application to upload, transmit, distribute or store, whether intentionally or
                inadvertently or negligently, videos or other storable information that violates any
                applicable law or violates the rights of any person.<br/><br/>
                - to use the Application in such a way as to cause damage by uploading viruses, Trojan
                horses, or other materials of a malicious nature;<br/><br/>
                - to use on the application any materials that violate the right to privacy and privacy of
                another person;<br/><br/>
                - use any defamatory, obscene, offensive, pornographic, malicious or inciting materials
                on the application;<br/><br/>
                - post any advertising, unauthorized or promotional material or any other sensitive or
                prohibited information on the Application.<br/><br/>
                - use on the application any material that infringes or may infringe any copyright,
                trademark or other intellectual property rights of any other person;<br/><br/>
                - use on the application any material with racist or discriminatory content, including
                discrimination based on a person&#39;s race, religion, age, gender, disability or sexuality;<br/><br/>
                - use on the application any materials that would constitute, encourage or provide
                instructions for the commission of a crime, dangerous activities, or any material that is
                intentionally created to cause incitement of persons against each other;<br/><br/>
                - use on the Application any responses, comments, opinions, analysis or
                recommendations that you are not authorized or qualified to provide;
                </Typography>
                <br/><br/>

                <Typography id="7" variant="p">7. Protecting Copyright and User Generated Content<br/><br/></Typography>
                <Typography variant="p" fontWeight="300">
                <Typography variant="p" fontWeight="400">7.1.</Typography>The application complies with the legislation specific to the protection of
                copyright and as such, posts that violate these rights are totally prohibited.<br/><br/>

                <Typography variant="p" fontWeight="400">7.2.</Typography> Any post that infringes another person&#39;s copyright may be removed. The
                account may also be suspended or closed for multiple copyright violations.<br/><br/>

                <Typography variant="p" fontWeight="400">7.3.</Typography> Users of the Application may be permitted to upload, post or otherwise
                make available to other users, including without limitation any text, photos, videos,
                audio recordings and musical works uploaded to or otherwise made available
                through the Application . You acknowledge and agree that users of the Services may
                also extract all or part of the User Content uploaded or otherwise made available by
                you through the Application to produce additional User Content.<br/><br/>

                <Typography variant="p" fontWeight="400">7.4.</Typography> Users can use all the facilities provided by the application to create and
                post their own content that will become common to other users.<br/><br/>

                <Typography variant="p" fontWeight="400">7.5.</Typography> Any material (by material meaning any post, whether clip or text
                message or compilations) of the user will be considered non-confidential.<br/><br/>

                <Typography variant="p" fontWeight="400">7.6.</Typography> You will not post any material on the Application or transmit any
                material that you consider to be confidential or the property of another person.
                When you transmit materials, you agree and represent that you own that user
                content or that you have received all necessary permissions, approvals or that you
                are authorized by the owner of that part of the content to transmit such content.<br/><br/>

                <Typography variant="p" fontWeight="400">7.7.</Typography> By adding content to the App, you grant us a royalty-free license to use
                your name, image, voice and likeness to identify yourself as the source of any material that belongs to you.<br/><br/>

                <Typography variant="p" fontWeight="400">7.8.</Typography> All rights that you grant in your materials under these Terms are
                provided with the right to transfer to another user of the platform, which means
                that they will have no separate liability to you or any other third party arising out of
                or in connection with a such availability of materials belonging to you.<br/><br/>

                <Typography variant="p" fontWeight="400">7.9.</Typography> By posting materials on the Application, you waive any rights to review
                or pre-approve any marketing or promotional materials associated with such
                material. You also waive any rights of privacy, publicity or any other rights of a
                similar nature in connection with the posted materials. You hereby waive and agree
                never to assert any claims related to any moral rights you may have in connection
                with any material you upload or otherwise make available through the Application.<br/><br/>

                <Typography variant="p" fontWeight="400">7.10.</Typography> You acknowledge and agree that, under certain circumstances, we may
                also have the right to disclose to you the identity of any third party who claims that
                any material posted or uploaded by you to the Application constitutes a violation of
                their intellectual property rights or their right to privacy.<br/><br/>

                <Typography variant="p" fontWeight="400">7.11.</Typography> We have the right to remove, refuse, block or delete any of your
                postings on the App if, in our opinion, your posting does not meet the moral or legal
                content standards set forth in these Terms.<br/><br/>

                <Typography variant="p" fontWeight="400">7.12.</Typography> We assume no responsibility for any content or information submitted
                by users of the application and published by us or on our behalf<br/><br/>

                <Typography variant="p" fontWeight="400">7.13.</Typography> The Services are provided &quot;as is&quot; and we make no warranties or
                representations of any kind with respect to them. In particular, we do not represent
                or warrant that:<br/><br/>
                - the use of the services will correspond to your requirements;<br/>
                - use of the services will be uninterrupted, prompt, secure or error-free;<br/>
                - any information obtained by you as a result of using the Services will be
                accurate or valid or will not contain errors;<br/>
                - defects in the functioning or operation of any software made available to
                you as part of the services will be corrected.<br/>

                Please note that we provide the Platform solely for your personal and private use. You agree not to use our platform for any commercial or 
                business purposes unless you have obtained our written consent to do so.
                </Typography>
                <br/><br/>

                <Typography id="8" variant="p">8. The end of the contract<br/><br/></Typography>
                <Typography variant="p" fontWeight="300">
                    <Typography variant="p" fontWeight="400">8.1.</Typography> Cessation of the use of the Application by the User may occur in the
                    following situations:<br/><br/>
                    a) at the User&#39;s initiative, by deactivating the Account;<br/><br/>
                    b) at the initiative of SMILE LIVE STREAM SRL or its partners with a notice
                    period of 10 days or immediately (without notice) for situations considered serious;<br/><br/>
                    c) at the initiative of SMILE LIVE STREAM SRL, without completing any
                    formality, if it is found that the User does not comply with the provisions of the
                    Terms and Conditions, the Application Guide or the applicable legal framework.<br/><br/>
                    
                    <Typography variant="p" fontWeight="400">8.2. SMILE LIVE STREAM SRL</Typography> reserves the right at any time during the
                    provision of the service, in a discretionary manner, without notice and without other
                    formalities, to stop providing the facilities of the Application, without being held
                    responsible towards the User.
                </Typography>
            </Box>
            <Box sx={{position:"sticky", bottom:10, display:"flex", justifyContent:"flex-end", }}>
                <StyledIconButton size="medium" color="inherit" onClick={handleGoToTop}>
                    <ArrowCircleUpIcon />
                </StyledIconButton>
            </Box>
        </PageLayout>

    )
}

const StyledIconButton = styled(IconButton)(() => ({
    backgroundColor:Theme.yellowColor,
    '&:hover': {
        backgroundColor: alpha(Theme.yellowColor, 0.75),
    },
}));