import React from 'react'
import NumberFormat from 'react-number-format'

function ValidateNumber(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            type={'text'}
            decimalSeparator="."
            isNumericString
            allowNegative={true} 
            getInputRef={inputRef}
            onValueChange={(price) => {
                onChange({
                    target: {
                        name: props.name,
                        value: price.value,
                    },
                });
            }}
        />
    );
}

export default ValidateNumber