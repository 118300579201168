import React, { useState, useEffect } from 'react'
import {Box, Typography, Grid, SvgIcon} from '@mui/material'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import Theme from "../../config/Theme"
import axios from 'axios'
import CarouselComponents from '../../components/CarouselComponents'
import {getApiRoutes} from '../../config/Routes'
import icons from '../../assets/icons'
import { useNavigate, Link  } from 'react-router-dom'
import {isEqual} from 'lodash'


export default ({section, key}) => {
    const api = getApiRoutes()
    const navigate = useNavigate()
    const { t } = useTranslation()
    const [clips, setClips] = useState([])


    const loadClips = () => {
        axios({
            method: api.clips.method,
            url: api.clips.path + '?sections[]='+ section.id,
        }).then((response) => {
            setClips(response.data.data)
        }).catch((error) => {
            console.log(error.message);
        });
    }

    // const handleClickClip = (id) => () => {
    //     navigate('/',{state:id}) /*send clip id */
    // }

    useEffect(() => {
        loadClips()
    }, [])


    const title = () => {
        return (
            <CustomBox key={section.name}>
                <Typography variant="body2" color="#fff" fontWeight="500" sx={{fontSize:16}}>
                    {section.name}
                </Typography>
                <Link  to={'/clips/'+section.id} state={section.name} textDecoration="none" color="#fff">
                    <Typography variant="body2" color={Theme.yellowColor} fontWeight="300" sx={{fontSize:16}}>
                    { isEqual(section.clips_count, 0) ? 
                        `${section.clips_count} clips`
                        :
                        t('See all {{count_clips}} clips', {count_clips: section.clips_count} )
                    }
                </Typography>
                </Link>
            </CustomBox>
        )
    }

  
    const renderClips = () =>{
        return (
            clips.map((clip,index) => {
                return (
                    <Link key={clip.id} component="button" sx={{textDecoration:"none"}} to={'/'} state={clip.id}>
                        <Box key={index} sx={{display:"flex", width:150, height:150}}>
                            <img src={clip.preview} style={{width:150, height:150, borderRadius:10}} alt={clip.id}/>
                            <Box sx={{position:"absolute", display:"flex", alignSelf:"flex-end" }} marginBottom={1} paddingLeft={1}>
                                <SvgIcon fontSize="small">
                                    <path d={icons.ic_baseline_favorite_border_24} fill="#fff"/>
                                </SvgIcon>
                                <Typography variant="caption" color={Theme.whiteColor} fontWeight={300} marginLeft={1}>{clip.likes_count}</Typography>
                            </Box>
                        </Box>
                    </Link>
                )
            })
        )
    }

    return(
        <Grid container spacing={2} margin="auto">
            <Grid item xs={10} margin="auto" sx={{backgroundColor:Theme.grayDarkColor, marginTop:3, marginBottom:3, padding:3}} borderRadius={1}>
                {title()}
                <CarouselComponents renderItems={renderClips} noSlides={3} noSlidesMobile={1} showArrow={false} showDots={false} centerMode={true}/>
            </Grid>
        </Grid>
    )

}

const CustomBox = styled(Box)(({ theme }) => ({
    display:'flex', 
    justifyContent:"space-between", 
    alignItems:"center",
    paddingBottom: 8,
    paddingTop: 8,
    a: {
        color: Theme.yellowColor,
        textDecoration: "none"
    },
}));