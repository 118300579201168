import React from 'react'
import { useTranslation } from 'react-i18next'
import Theme from '../config/Theme'
import { Box, IconButton, SvgIcon, Dialog, DialogTitle, DialogContent, DialogActions, Button, Link } from '@mui/material'
import { styled, alpha } from '@mui/material/styles'
import icons from '../assets/icons'


export default ({showConfirmDialog, toggleConfirmDialog, renderContentConfirmDialog, handleOk}) => {
    const { t } = useTranslation()

    const handleCancel = () => {
        toggleConfirmDialog()
    };
   
    return (
            <Dialog
                keepMounted
                open={showConfirmDialog}
                onClose={toggleConfirmDialog}
                // onBackdropClick="false"
                maxWidth="xs"
                fullWidth
                scroll={'body'}
                PaperProps={{
                    style: {
                        backgroundColor: Theme.blackColor,
                        color: 'white',
                        borderRadius: 5
                    },
                }}
                BackdropProps={{
                    style: {
                        backgroundColor: "#3c3c3c66",
                        color: 'white',
                        borderRadius: 5
                    },
                }}
            >
        
            {/* <DialogTitle sx={{backgroundColor: Theme.blackColor}}>
                <Box sx={{display:"flex", alignItems:"center", justifyContent:"center"}}>
                    <StyledIconButton aria-label="close" onClick={toggleConfirmDialog}>
                        <SvgIcon>
                            <path d={icons.ic_baseline_close_24} fill="#fff"/>
                        </SvgIcon>
                    </StyledIconButton>
                </Box>
            </DialogTitle> */}
            
            {/* <DialogContent sx={{borderTop:'1.5px solid', borderTopColor:Theme.grayDarkColor}}> */}
            <DialogContent>
                {renderContentConfirmDialog()}
            </DialogContent>
            <DialogActions>
                <StyledButton sx={{color: Theme.yellowColor}} autoFocus onClick={handleCancel}>
                    Cancel
                </StyledButton>
                <StyledButton sx={{color: "#fff"}} onClick={handleOk}>Ok</StyledButton>
            </DialogActions>
        </Dialog>  
        
    )
}

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    left: 0,
    '&:hover': {
        backgroundColor: alpha(Theme.whiteColor, 0.15),
    },
}));

const StyledButton = styled(Button)(({ theme }) => ({
    '&:hover': {
        backgroundColor: alpha(Theme.whiteColor, 0.15),
    },
}));

