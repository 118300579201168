import React,{useState, useEffect} from 'react'
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"

export default ({renderItems, showArrow="true", showDots="true", noSlides=1, noSlidesMobile=1, centerMode=""}) => {

    const [width, setWidth] = useState(window.innerWidth);
    const handleWindowResize = () => {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        }
    }, []);


    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: noSlides,
           slidesToSlide: 1
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: noSlides,
            partialVisibilityGutter: 40,
            slidesToSlide: 1, // optional, default to 1.,
        },
        tablet: {
          breakpoint: { max: 1024, min: 850 },
          items: noSlides,
          partialVisibilityGutter: 30,
          slidesToSlide: 1, // optional, default to 1.
        },
        sm: {
            breakpoint: { max: 850, min: 634 },
            items: 3,
            partialVisibilityGutter: 0,
            slidesToSlide: 1, // optional, default to 1.
        },
        xs: {
            breakpoint: { max: 634, min: 432 },
            items: 2,
            partialVisibilityGutter: 0,
            slidesToSlide: 1, // optional, default to 1.
        },
        mobile: {
          breakpoint: { max: 432, min: 0 },
          items: noSlidesMobile,
          partialVisibilityGutter: 0,
          slidesToSlide: 1, // optional, default to 1.
        }
      };

      
    return (
        <div
            style={{
                paddingBottom: '30px',
                position: 'relative'
            }}
        >
            <Carousel swipeable={false}
                draggable={false}
                showDots={showDots}
                arrows={showArrow}
                responsive={responsive}
                // ssr={true} // means to render carousel on server-side.
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={1500}
                keyBoardControl={true}
                customTransition="all .5"
                transitionDuration={500}
                containerClass=""
                removeArrowOnDeviceType={["tablet", "mobile"]}
                deviceType={"mobile"}
                renderDotsOutside
                centerMode={width < 850 ? false : centerMode }
            >
            { renderItems() }
        </Carousel>
    </div>
    )
}