export const baseURL = "https://stage.smilelive.app/api";
// export const baseURL = "https://smilelive.app/api";
export const getApiRoutes = () => {
    return {
        login: {
            otp: {
                path: `${baseURL}/login/email/otp`,
                method: 'POST'
            },
            otpPhone: {
                path: `${baseURL}/login/phone/otp`,
                method: 'POST'
            },
            email: {
                path: `${baseURL}/login/email`,
                method: 'POST'
            },
            phone: {
                path: `${baseURL}/login/phone`,
                method: 'POST'
            },
            google: {
                path: `${baseURL}/login/google`,
                method: 'POST'
            }
        },
        sections: {
            path: `${baseURL}/clips/sections`,
            method: 'GET'
        },
        clips: {
            path: `${baseURL}/clips`,
            method: 'GET'
        },
        challenges:{
            path: `${baseURL}/challenges`,
            method: 'GET'
        },
        users: {
            path: `${baseURL}/users`,
            method: 'GET'
        },
        stickers:{
            path: `${baseURL}/stickers`,
            method: 'GET'
        },
        wallet: {
            gift:{
                path: `${baseURL}/wallet/gifts`,
                method: 'POST'
            },
            balance:{
                path: `${baseURL}/wallet/balance`,
                method: 'GET'
            },
            redeem:{
                path: `${baseURL}/wallet/redeem`,
                method: 'POST'
            },
            redemptions:{ 
                path: `${baseURL}/wallet/redemptions`,
                method: 'GET'
            },
            recharge: {
                path: `${baseURL}/wallet/recharge`,
                method: 'POST'
            }
        },
        credits: {
            path: `${baseURL}/credits`,
            method: 'GET'
        },
        items: {
            path: `${baseURL}/items`,
            method: 'GET'
        },
        stickersSections:{
            path: `${baseURL}/stickers/sections`,
            method: 'GET'
        },
        profile: {
            path: `${baseURL}/profile`,
            method: 'GET'
        },
        livestreams: {
            path: `${baseURL}/live-streams`,
            method: 'GET'
        },
        notifications: {
            path: `${baseURL}/notifications`,
            method: 'GET'
        },
        articles:{
            path: `${baseURL}/articles`,
            method: 'GET'
        },
        articlesSections:{
            path: `${baseURL}/articles/sections`,
            method: 'GET'
        },
        hashtags:{
            path: `${baseURL}/hashtags`,
            method: 'GET'
        },
        threads:{
            path: `${baseURL}/threads`,
            method: 'GET'
        },
       
        reports: {
            path: `${baseURL}/reports`, 
            method: 'POST'
        },
        suggestions: {
            path: `${baseURL}/suggestions`, 
            method: 'GET'
        },
        verifications: {
            path: `${baseURL}/verifications`,
            method: 'POST'
        },
        songs: {
            path: `${baseURL}/songs`,
            method: 'GET'
        },
        songSections: {
            path: `${baseURL}/songs/sections`,
            method: 'GET'
        },
        sectionSong: {
            path: `${baseURL}/songs/song`,
            method: 'GET'
        },

    }
}
