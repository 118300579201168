import React, {useState, useRef, useEffect} from 'react'
import Theme from '../config/Theme'
import { Box, IconButton, SvgIcon, Typography, Button, Slider } from '@mui/material'
import { styled, alpha } from '@mui/material/styles'
import icons from '../assets/icons'
import { isNull } from 'lodash'
import { useNavigate } from 'react-router-dom'


export default ({song, setSong}) => {
    const navigate = useNavigate()
    const audioRef = useRef(new Audio())
    const [isPlaying, setIsPlaying] = useState(true)
    const [trackProgress, setTrackProgress] = useState(0);
    const { currentTime, duration } = audioRef.current;
    

    const playPauseSong = () =>{
        if(audioRef.current.paused) {
            audioRef.current.play()
            setIsPlaying(false)
        }
        else{
            audioRef.current.pause()
            setIsPlaying(true)
        }
    }
    
    const handleClose = () => {
        setSong(null)
        audioRef.current.src=null
    }

    const handleCheckSong = () => {
        setSong(null)
        audioRef.current.src=null
        navigate('/record',{state:{chosenSong:song}})
    }
 
    useEffect(() => {
        if(!isNull(song)) {
            audioRef.current.src = song.audio
        }
        playPauseSong()
    },[song])

    function formatDuration(seconds) {
        const minutes = Math.floor(seconds / 60);
        // minutes = (minutes >= 10) ? minutes : "0" + minutes;
        seconds = Math.floor(seconds % 60);
        // seconds = (seconds >= 10) ? seconds : "0" + seconds;
        // return minutes + ":" + seconds;
        return `${(minutes >= 10) ? minutes : "0" + minutes}:${(seconds >= 10) ? seconds : "0" + seconds}`;
    }

    console.log("duration", song)

    return (
        <Box sx={{backgroundColor: '#0e0e0e', borderTop: '1px solid', borderTopColor: Theme.grayDarkColor,position:"sticky", bottom:0}}>
            <Box sx={{borderBottom:'1px solid', borderTopColor:Theme.grayDarkColor, display:"flex", alignItems:"center"}}>
                <StyledIconButton aria-label="close" onClick={handleClose}>
                    <SvgIcon>
                        <path d={icons.ic_baseline_close_24} fill="#fff"/>
                    </SvgIcon>
                </StyledIconButton>
                <Typography color="#fff" component="div" textAlign="center" width="100%">Preview </Typography>
            </Box>
            <Box sx={{display:"flex", justifyContent:"center", paddingBottom:2, paddingTop:2}}>
                <PlayPauseButton
                    size="small" 
                    onClick={() => playPauseSong()}
                >
                    <SvgIcon>
                        <path d={!isPlaying ? icons.ic_baseline_pause_24 : icons.ic_baseline_play_arrow_24} fill={Theme.yellowColor}/>
                    </SvgIcon>
                </PlayPauseButton>
                
                <Box paddingX={2} flexGrow={1}>
                    <Typography color="#fff" variant="caption">{song.title}</Typography>
                    <Box sx={{display:"flex", justifyContent:"center"}} >
                        <Typography color="#fff" variant="caption" marginRight={2}>{currentTime ? formatDuration(currentTime) : '00:00'}</Typography>
                        <Slider
                            size="small"
                            value={trackProgress}
                            aria-label="Small"
                            valueLabelDisplay="auto"
                            style={{color:Theme.greenColor}}
                            min={0}
                            step={1}
                            max={duration}
                            onChange={(_, value) => setTrackProgress(value)}
                        />
                        <Typography color="#fff" variant="caption" marginLeft={2} marginRight={2}>{duration ? formatDuration(duration) : '00:00'}</Typography>
                    </Box>
                </Box>

                <CheckButton onClick={handleCheckSong} size="small">
                    <SvgIcon>
                        <path d={icons.ic_baseline_check_24} fill={Theme.blackColor}/>
                    </SvgIcon>
                </CheckButton>
            </Box>
        </Box>
    )
}

const StyledIconButton = styled(IconButton)(() => ({
    '&:hover': {
        backgroundColor: alpha(Theme.whiteColor, 0.15),
    },
}));


const PlayPauseButton = styled(Button)(() => ({
    color:"white",
    backgroundColor:"transparent",
    border: "1px solid",
    borderColor: Theme.grayColor,
    '&:hover': {
        backgroundColor: alpha(Theme.whiteColor, 0.15),
    },
}));

const CheckButton = styled(Button)(() => ({
    marginLeft: 10,
    color:"white",
    backgroundColor:Theme.yellowColor,
    '&:hover': {
        backgroundColor: alpha(Theme.whiteColor, 0.15),
    },
}));
