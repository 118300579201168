import React, { useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, SvgIcon, Typography } from '@mui/material'
import { styled, alpha } from '@mui/material/styles'
import Theme from "../../config/Theme"
import PageLayout from '../../Layout/PageLayout'
import TitlePageBar from '../../Layout/TitlePageBar'
import {getApiRoutes} from '../../config/Routes'
import axios from 'axios'
import icons from '../../assets/icons'
import { useNavigate } from 'react-router-dom'
import GoBackButton from '../../components/GoBackButton'
import WalletTabs from './WalletTabs'
import AddIcon from '@mui/icons-material/Add'
import { AuthContext } from '../../redux/contexts/AuthContext'
import { useEffect } from 'react'


export default () => {
    const { t } = useTranslation()
    const api = getApiRoutes()
    const { token } = useContext(AuthContext)
    const  userToken = token || localStorage.getItem('token')
    const navigate = useNavigate()
    const [creditBalance, setCreditBalance] = useState(0)

    const renderLeftComponent = () => <GoBackButton/>

    const handleAdd = () => {
        navigate('/recharge')
    }

    const loadWallet = () => {
        axios.defaults.headers["Authorization"] = `Bearer ${userToken}`
        axios({
            method: 'get',
            url: api.wallet.balance.path,
        }).then(response => {
            setCreditBalance(response.data.data.balance)
            
        }).catch((error) => {
            console.log(error.message);
        });
    }

    useEffect(()=>{
        loadWallet()
    },[])

           
    return (
        <PageLayout bgColor='#000'>
            <TitlePageBar title="Wallet" showLeftComponent={true} leftComponent={renderLeftComponent}/>
            
            <Box sx={{display:"flex",  flex: 1, flexDirection:"column"}}>
                <Box margin={3} sx={{display:"flex", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                    <Typography variant="h4" color={Theme.grayColor} fontWeight="bold" marginBottom={3}>{creditBalance}</Typography>
                    <Typography variant="h6" color={Theme.grayColor} marginBottom={3}>{t("You can use the wallet balance to instantly send gifts to other users within the app")}</Typography>   
                    <StyledButton 
                            variant="contained" 
                            size="small"
                            startIcon={ <AddIcon/> }
                            onClick={handleAdd}
                        >
                            {t('Add')}
                        </StyledButton>
                </Box>
               
                <WalletTabs {...{creditBalance}}/>
            </Box>
        </PageLayout>
    )
}


const StyledButton = styled(Button)(() => ({
    paddingLeft:20, 
    paddingRight:20,
    backgroundColor: Theme.yellowColor,
    color: Theme.primaryColor,
    '&:hover': {
        backgroundColor: alpha(Theme.yellowColor, 0.75),
    },
}));

