import React, { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography, Divider, List, ListItemButton , ListItemText, ListItemAvatar, Avatar, Box, SvgIcon, Button } from '@mui/material'
import { styled, alpha } from '@mui/material/styles'
import StarsIcon from '@mui/icons-material/Stars'
import Theme from "../../config/Theme"
import { useNavigate } from 'react-router-dom'
import PageLayout from '../../Layout/PageLayout'
import TitlePageBar from '../../Layout/TitlePageBar'
import GoBackButton from '../../components/GoBackButton'
import axios from 'axios'
import {getApiRoutes} from '../../config/Routes'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import icons from '../../assets/icons'


export default () => {
    const { t } = useTranslation()
    const api = getApiRoutes()
    const navigate = useNavigate()
    const [followers, setFollowers] = useState([])
    const location = useLocation()
    const { userId } = location.state
    const [isFollow, setIsFollow] = useState(false)

    const renderLeftComponent = () => <GoBackButton/>

    const loadFollowers = () => {
        axios({
            method: api.users.method,
            url: api.users.path + '/' + userId + '/followers?following=false&page=1',
        }).then(function(response) {
            setFollowers(response.data.data)
            console.log('loaded followers ', response.data.data.length)
        }).catch(function(error) {
            console.log(error.message);
        });
    }

    useEffect(() => {
        loadFollowers()
    },[])

    const handleClick = (follower) => () => {
        navigate('/profile/' + follower.id)
    }

    const handleFollow = () => {
        console.log('follow')
    }

    return (
        <PageLayout bgColor='#000'>
            <TitlePageBar title="Followers" showLeftComponent={true} leftComponent={renderLeftComponent}/>
            <Box sx={{display:"flex",  flex: 1, flexDirection:"column"}}>
                <List>
                {
                    followers.map((follower,idx) => {
                        return (
                            <Fragment key={idx}>
                                <ListItemButton key={idx} alignItems="flex-start" onClick={handleClick(follower)}>
                                    <ListItemAvatar>
                                        <BorderAvatar alt={follower.name} src={follower.photo} />
                                    </ListItemAvatar>
                                    <ListItemText 
                                        primaryTypographyProps={{
                                            fontSize: 14,
                                            fontWeight: 'bold',
                                            letterSpacing: 0,
                                            color:"#fff"
                                        }}
                                        primary={follower.name}
                                        secondary={
                                            <Fragment>
                                                <Typography
                                                sx={{ display: 'inline' }}
                                                component="span"
                                                variant="caption"
                                                color={Theme.grayColor}
                                                >
                                                { (follower.views_count>=1000) ? (follower.views_count/1000).toFixed(1) +`k `: follower.views_count} - @{follower.username}
                                            </Typography>
                                            <StarsIcon sx={{color:Theme.greenColor, fontSize:20, marginLeft: 2}}/>
                                            </Fragment>
                                        }
                                    />
                                    {/* <StyledButton
                                        variant="contained" 
                                        size="small"
                                        startIcon={
                                            <SvgIcon>
                                                <path d={!follower.followed ? icons.ic_follow : icons.ic_unfollow} fill={Theme.blackColor}/>
                                            </SvgIcon> 
                                        }
                                        onClick={handleFollow}
                                    >
                                    
                                    {!follower.followed ? "Follow" : "Unfollow"}
                                </StyledButton> */}
                                </ListItemButton>
                                                        
                                <Divider variant="inset" component="li" sx={{borderColor:Theme.grayDarkColor}}/>
                            </Fragment> 
                        )
                    })
                }
                </List>
            </Box>
        </PageLayout>
    )
}

const BorderAvatar = styled(Avatar)(({ theme }) => ({
    border:"3px solid",
    borderColor: Theme.yellowColor,
    '& img': {
        borderRadius:'50%'
    }
}));

const StyledButton = styled(Button)(() => ({
    alignSelf:"center",
    backgroundColor: Theme.yellowColor,
    color: Theme.primaryColor,
    marginLeft: 10,
    '&:hover': {
        backgroundColor: alpha(Theme.yellowColor, 0.75),
    },
}));