import React, { useEffect, useState } from 'react'
import { Tabs, Tab, Box, Typography } from '@mui/material'
import Theme from '../../config/Theme'
import {isEmpty} from 'lodash'

export default ({redemptions}) => {

    return (
         isEmpty(redemptions) ?
            <Box sx={{display:"flex", justifyContent:"center", alignItems:"center", paddingTop:"20%"}}>
                <Typography variant="caption" color={Theme.grayColor}>No redemtpions found to display</Typography>
            </Box>
            :
            redemptions.map((redemption,idx) => {
                return(
                    <Box key={idx}>
                        <Typography color="#fff">{redemption.amount}-{redemption.created_at}</Typography>
                        <Typography color="#fff">{redemption.status}</Typography>
                    </Box>
                )
            })
    )
}

