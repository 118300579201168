import React, { useState, useEffect } from 'react'
import {Container, Box, Typography, IconButton, SvgIcon} from '@mui/material'
import Theme from '../config/Theme'
import icons from '../assets/icons'
import { styled, alpha } from '@mui/material/styles'


export default ({title, showLeftComponent=false, leftComponent, showRightComponent=false, rightComponent}) => {
   
    return (
        <CustomBox padding={2}>
            {showLeftComponent && leftComponent() }

            <Typography variant="h6" color={Theme.whiteColor} className='textShadow'>{title}</Typography>

            {showRightComponent && rightComponent() }
        </CustomBox>
    )
}

const CustomBox = styled(Box)(({ theme }) => ({
    marginTop: 75,
    marginBottom: 16,
    display:'flex', 
    justifyContent:"space-between", 
    alignItems:"center",
    borderTop: '1px solid', 
    borderTopColor: Theme.grayDarkColor,
    backgroundColor: 'rgb(245 240 240 / 6%)',
    borderRadius: 4,
    a: {
        color: Theme.yellowColor,
        textDecoration: "none"
    },
}));