import React from 'react'
import { useTranslation } from 'react-i18next'
import { Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material'
import { styled, alpha } from '@mui/material/styles'

import Theme from '../../config/Theme'


const LocaleDialog = ({showDialog, toggleDialog, renderContentDialog, handleApply}) => {
    const { t } = useTranslation()

    return (
        <Dialog
            keepMounted
            open={showDialog}
            onClose={toggleDialog}
            maxWidth="xs"
            fullWidth
            scroll={'body'}
            PaperProps={{
                style: {
                    backgroundColor: Theme.dialogColor,
                    color: 'white',
                    borderRadius: 5
                },
            }}
        >
            <DialogTitle sx={{backgroundColor: Theme.dialogColor}}>
                <Typography variant="body2" color={Theme.whiteColor}>Locale</Typography>
            </DialogTitle>
            <DialogContent>
                {renderContentDialog()}
            </DialogContent>
            <DialogActions>
                <StyledButton
                    variant="text" 
                    size="small" 
                    onClick={toggleDialog}
                >
                    {t('Cancel')} 
                </StyledButton>

                <StyledButton 
                    variant="text" 
                    size="small" 
                    onClick={handleApply}
                >
                    {t('Apply')} 
                </StyledButton>
            </DialogActions>

        </Dialog>
    )
}

export default LocaleDialog

const StyledButton = styled(Button)(({ theme }) => ({
    backgroundColor:"transparent",
    color: Theme.yellowColor,
    marginLeft: 10,
    '&:hover': {
        color: alpha(Theme.yellowColor, 0.75),
    },
}));