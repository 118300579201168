import React from 'react'
import { GoogleLogin } from 'react-google-login'
import { SvgIcon, Button } from '@mui/material'
import { styled, alpha } from '@mui/material/styles'
import Theme from '../../config/Theme'
import icons from '../../assets/icons'

const googleClientId="235080584945-gamdrj6gtthd9j25nmge42nkaeinuusq.apps.googleusercontent.com"


export default ({onSuccess, onFailure}) => {
       
    return (
        <GoogleLogin
            clientId={googleClientId}
            render={renderProps => (
                <GoogleBtn sx={{backgroundColor: Theme.colorButtonGoogle, width:"100%", color:"#fff", marginTop: 3}}
                    startIcon = { <SvgIcon> <path d={icons.ic_google} fill="#fff"/></SvgIcon> }
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                >
                Google
                </GoogleBtn>
            )}
            buttonText="GOOGLE"
            onSuccess={onSuccess}
            onFailure={onFailure}
            cookiePolicy={'single_host_origin'}
            // isSignedIn={true}
            theme={'dark'}
        />
    )
}

const GoogleBtn = styled(Button)(({ theme }) => ({
    '&:hover': {
        backgroundColor: alpha(Theme.colorButtonGoogle, 0.75),
    },
}));