import React, { Fragment } from 'react'
import { Typography, Divider, List, ListItemButton, ListItemText, ListItemIcon, SvgIcon } from '@mui/material'
import Theme from "../../config/Theme"
import icons from '../../assets/icons'
import { useNavigate } from 'react-router-dom'

export default ({hashtags}) => {
    const navigate = useNavigate()

    const handleClick = (hashtag) => () => {
        navigate('/' + hashtag.name)
    }

    return (
        <List>
        {
            hashtags.map((hashtag,idx) => {
                return (
                    <Fragment key={idx}>
                        <ListItemButton key={idx} alignItems="flex-start" onClick={handleClick(hashtag)}>
                            <ListItemIcon>
                                <SvgIcon viewBox='0 0 48 48' fontSize='large'>
                                    <path d={icons.ic_baseline_hashtag_36} fill={Theme.grayColor}/>
                                </SvgIcon>
                            </ListItemIcon>
                            
                            <ListItemText 
                                primaryTypographyProps={{
                                    fontSize: 14,
                                    fontWeight: 'bold',
                                    letterSpacing: 0,
                                    color:"#fff"
                                }}
                                primary={hashtag.name}
                                secondary={
                                    <Fragment>
                                        <Typography
                                        sx={{ display: 'inline' }}
                                        component="span"
                                        variant="caption"
                                        color={Theme.grayColor}
                                        >
                                        {hashtag.clips} clips
                                    </Typography>
                                    </Fragment>
                                }
                            />
                        </ListItemButton>
                        
                        <Divider variant="inset" component="li" sx={{borderColor:Theme.grayDarkColor}}/>
                    </Fragment> 
                )
            })
        }
        </List>
    )
}