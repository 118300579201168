import React, {useState, useContext, useRef} from 'react'
import { useTranslation } from 'react-i18next'
import Theme from '../../config/Theme'
import { Box, IconButton, SvgIcon, Dialog, DialogTitle, DialogContent, Typography, Button, Link, TextField  } from '@mui/material'
import { styled, alpha } from '@mui/material/styles'
import icons from '../../assets/icons'
import ValidateNumber from '../../components/ValidateNumber'
import {getApiRoutes} from '../../config/Routes'
import axios from 'axios'
import { isEmpty } from 'lodash'
import { AuthContext } from '../../redux/contexts/AuthContext'
import { useNavigate } from 'react-router-dom'


export default ({showLoginSms, setShowLoginSms, toggleLoginSms, toggleLogin}) => {
    const { t } = useTranslation()
    const api = getApiRoutes();
    const { signIn } = useContext(AuthContext)
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [phone, setPhone] = useState('')
    const [otp, setOtp] = useState('')
    const [errorsMessage, setErrorsMessage] = useState()
    const [isVisible, setIsVisible] = useState(false)
    
    const handleChange = (e) => {
        // setEmail(e.target.value)
        if (e.target.name === "phone") {
            setPhone(e.target.value) 
        }
        else if(e.target.name==="otp") {
            setOtp(e.target.value)
        }
    }

    
    // const handleChange = e => {
    //     setOtp(e.target.value)
    // }

    const handleClickGenerate = (e) => {
        e.preventDefault()
        setLoading(true)
        
        if (isEmpty(phone)) { 
            setErrorsMessage({phone:'errorPhone'}) 
        }
        else {
            setErrorsMessage('')
            //send axios request to otp
            console.log('sending otp message', phone);
            axios({
                method: api.login.otpPhone.method,
                url: api.login.otpPhone.path,
                data: {
                    phone: phone
                }
            }).then(function(response) {
                console.log(response)
                let data = response.data;
                
                if(data.exists != true) 
                {
                    setErrorsMessage({phone:'errorPhone'}) 
                    setIsVisible(false)
                } else {
                    setLoading(false)
                    setErrorsMessage('')
                    setIsVisible(true)

                }
            }).catch(function(error) {
                console.log(error.message);
            });
        }
    }

    const handleClickVerify = (e) => {
        if (isEmpty(otp) || otp.length < 6 ) { 
            setErrorsMessage({otp:'errorOtp'}) 
        }
        else {
            setErrorsMessage('') 
            setIsVisible(true)

            axios({
                method: api.login.phone.method,
                url: api.login.phone.path,
                data: {
                    phone: phone,
                    otp: otp
                }
            }).then(function(response) {
                console.log(response)
                let data = response.data;
                
                if(data.existing != true)
                {
                    setErrorsMessage({otp:'errorOtp'})
                } else {
                    signIn({token: data.token})
                    toggleLoginSms()
                    navigate('/')
                }
            }).catch(function(error) {
                console.log(error.message);
            });
        }
    }
    

    return (
        <Dialog
            keepMounted
            open={showLoginSms}
            onClose={toggleLoginSms}
            maxWidth="xs"
            fullWidth
            scroll={'body'}
            PaperProps={{
                style: {
                    backgroundColor: Theme.blackColor,
                    color: 'white',
                    borderRadius: 5
                },
            }}
            BackdropProps={{
                style: {
                    backgroundColor: "#3c3c3c66",
                    color: 'white',
                    borderRadius: 5
                },
            }}
        >
        
            <DialogTitle sx={{backgroundColor: Theme.blackColor}}>
                <Box sx={{display:"flex", alignItems:"center", justifyContent:"center"}}>
                    <StyledIconButton aria-label="close" onClick={toggleLoginSms}>
                        <SvgIcon>
                            <path d={icons.ic_baseline_close_24} fill="#fff"/>
                        </SvgIcon>
                    </StyledIconButton>
                    <Box sx={{ flexGrow: 1, textAlign:"center"}}>Login</Box>
                </Box>
            </DialogTitle>
            
            <DialogContent sx={{borderTop:'2px solid', borderTopColor:Theme.grayDarkColor}}>
                <Typography color="#fff" fontSize={13} textAlign="center" marginTop={3}> 
                    {t("Please enter your mobile number below & click on")}
                </Typography>
                <Typography color="#fff" fontSize={13} textAlign="center"> 
                    {t("Generate button to receive an OTP")}
                    {t(errorsMessage?.email)}
                </Typography>
                
                <CustomTextfield
                    required
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Phone"
                    fullWidth
                    variant="outlined"
                    error={!!errorsMessage?.phone}
                    helperText={errorsMessage?.phone && t("errorPhone")}
                    name="phone"
                    value={phone}
                    onChange={handleChange}
                    autoComplete="off"
                 />
                { isVisible &&
                    <CustomTextfield
                        required
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Otp"
                        fullWidth
                        variant="outlined"
                        name="otp"
                        value={otp}
                        onChange={handleChange}
                        autoComplete="off"
                        inputProps={{ minLength: 1, maxLength: 6 }}
                        InputProps = {{ inputComponent: ValidateNumber }}
                    />
                 }
                 
                 <Box sx={{display:"flex", justifyContent:"space-between", alignItems:"center", marginTop: 2}}>
                    <GenerateBtn variant="text" sx={{color:Theme.yellowColor, width:"50%"}}
                        endIcon={ <SvgIcon><path d={icons.ic_baseline_send_24} fill={Theme.yellowColor}/></SvgIcon> }
                        onClick={handleClickGenerate}
                    >
                        {t("Generate")}
                    </GenerateBtn>

                    <VerifyBtn variant="text" 
                        sx={{color: isVisible ? Theme.grayColor : Theme.yellowColor, backgroundColor: !isVisible ? Theme.grayDarkColor:  Theme.yellowColor, width:"50%"}} 
                        disabled={!isVisible}
                        endIcon={ <SvgIcon><path d={icons.ic_baseline_verified_user_24} fill={!isVisible ? Theme.grayColor : Theme.grayDarkColor}/></SvgIcon> }
                        onClick={handleClickVerify}
                    >
                        <Typography sx={{color: !isVisible ? Theme.grayColor :Theme.grayDarkColor}}>{t("Verify")}</Typography>
                    </VerifyBtn>
                </Box>

            </DialogContent>

        </Dialog>       
    )
}

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    left: 0,
    '&:hover': {
        backgroundColor: alpha(Theme.whiteColor, 0.15),
    },
}));

const GenerateBtn = styled(Button)(({ theme }) => ({
    '&:hover': {
        backgroundColor: 'rgb(239 239 230 / 12%)',
    },
}));

const VerifyBtn = styled(Button)(({ theme }) => ({
    '&:hover': {
        backgroundColor: alpha(Theme.whiteColor, 0.15),
    },
}));

const CustomTextfield = styled(TextField)(({ theme }) => ({
    width: '100%',
    marginTop: 20,
    // marginBottom: 20,
    fontSize:12,
    '& label.Mui-focused': {
        color: Theme.yellowColor,
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: Theme.yellowColor,
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: Theme.yellowColor,
        },
        '&:hover fieldset': {
            borderColor: Theme.yellowColor,
        },
        '&.Mui-focused fieldset': {
            borderColor: Theme.yellowColor,
        },
    },
    '& .MuiInputBase-input':{
        color: Theme.yellowColor,
        fontSize:12
    },
    '& label': {
        color: Theme.yellowColor,
        fontSize:13,
    },
}));


