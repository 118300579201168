import React from 'react'
import { Box, Typography, Avatar, SvgIcon, Chip } from '@mui/material'
import Theme from '../../../config/Theme'
import { Link } from "react-router-dom"
import icons from "../../../assets/icons"
import { isNull } from 'lodash'
import StarsIcon from '@mui/icons-material/Stars'
import { useNavigate } from 'react-router-dom'


const VideoFooter = ({ data }) => {
    const navigate = useNavigate()

    const handleClickUserHashtag = hashtag => () => {
        console.log(hashtag)
        navigate('/'+hashtag)

    }

    return (
        <Box className="videoFooter">
		    <Box sx={{display:"flex", alignItems:"center", marginBottom:2}}>
                <Avatar src={data.user.photo} sx={{marginRight: 2, border:"3px solid", borderColor:Theme.yellowColor, width: 56, height: 56 }}></Avatar>
                <Box sx={{display:"flex", alignItems:"center"}}>
                    <SvgIcon className="svgIconShadow" fontSize="medium">
                        <path d={icons.ic_follow} fill="#fff" />
                    </SvgIcon>
                    <Typography variant="body1" color ="#fff" className="textShadow" marginLeft={0.5} sx={{fontSize:'1.4rem'}}>Follow</Typography>
                </Box>
            </Box>

            <Box>
                <Box sx={{display:"flex"}}>
                    <Typography 
                        gutterBottom
                        className="textShadow" 
                        sx={{ color: 'inherit', textDecoration: 'inherit', fontWeight: 600 }} 
                        component={Link} 
                        to={'/profile/'+data.user.id}
                    > 
                        @{data.user.username}
                    </Typography>
                    {data.user.verified && <StarsIcon sx={{color:Theme.greenColor, fontSize:18, marginLeft: 1}}/>}
                </Box>

                <Box>{data.description}</Box>
            </Box>

            <Box sx={{display:"flex"}}>
                <SvgIcon className="svgIconShadow" fontSize="medium">
                    <path d={icons.ic_baseline_music_note_24} fill="#fff" />
                </SvgIcon>

                <Typography variant="subtitle2" color ="#fff" className="textShadow" fontWeight={300} marginLeft={0.5}>
                    {(data.song || !isNull(data.song) ) ?  data.song.title : 'User`s original audio.'}
                </Typography>
                    
            </Box>
            <Box sx={{marginTop:2}}>
            {
                data.hashtags?.map(hashtag => {
                    return(
                        <Chip key={hashtag} label={"#"+hashtag} onClick={handleClickUserHashtag(hashtag)} size="small" sx={{backgroundColor: Theme.grayDarkColor, color:"#fff", marginRight:0.5}}/>
                    )
                })
            }
            </Box>
        </Box>
    )
}

export default VideoFooter