import React, {Fragment} from 'react'
import Header from './Header'
import { CssBaseline, Container, Box } from '@mui/material'


export default({ header, children, bgColor="#fff"}) => {

    return (
        <Box style={{height:"100vh", display:"flex", flexDirection:"column"}}>
            <CssBaseline />

            { header || <Header /> }

            {/* <Container maxWidth={false} sx={{backgroundColor: bgColor, display:"flex", flex: 1, flexDirection:"column"}}> */}
            <Container maxWidth="md" sx={{backgroundColor: bgColor, display:"flex", flex: 1, flexDirection:"column"}}>
                {children}
            </Container>

            {/* { footer || <Footer/> } */}
        </Box>
    ) 


}