import React from 'react'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { SvgIcon, Button } from '@mui/material'
import { styled, alpha } from '@mui/material/styles'
import Theme from '../../config/Theme'
import icons from '../../assets/icons'

const appId = 5084019214991351

export default ({responseFacebook}) => {
   
    
    return (
        // <FacebookLogin
        //     appId={appId}
        //     autoLoad={true}
        //     callback={responseFacebook}
        //     fields='name, email, picture'
        //     render={renderProps => (
        //         <FcbBtn sx={{backgroundColor: Theme.colorButtonFacebook, width:"100%", color:"#fff", marginTop: 3}}
        //             startIcon={ <SvgIcon><path d={icons.ic_facebook} fill="#fff"/></SvgIcon> }
        //             onClick={renderProps.onClick}
        //         >
        //         Facebook
        //         </FcbBtn>
        //     )}
        // />
        <FcbBtn sx={{backgroundColor: Theme.colorButtonFacebook, width:"100%", color:"#fff", marginTop: 3}}
            startIcon={ <SvgIcon><path d={icons.ic_facebook} fill="#fff"/></SvgIcon> }
            // onClick={renderProps.onClick}
        ></FcbBtn>
       
    )
}

const FcbBtn = styled(Button)(({ theme }) => ({
    '&:hover': {
        backgroundColor: alpha(Theme.colorButtonFacebook, 0.75),
    },
}));