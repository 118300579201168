import { isUndefined } from 'lodash'

const reducer = {    
    'LOGIN': (prevState, payload) => ({
        ...prevState,
        isSignout: false,
        isLoggedIn: true,        
        token: payload.token,
        user: payload.user
    }),
    'LOGOUT': (prevState) => ({
        ...prevState,
        isSignout: true,
        isLoggedIn: false,        
        token: null,        
        user: {}                 
    })    
}

export default (prevState, action) => {
    if (! isUndefined(reducer[action.type]))
        return reducer[action.type](prevState, action.payload)
    else return prevState
}
