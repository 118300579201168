export default {
    primaryColor: "#000000",
	secondaryColor: "#000000de",
	redColor: "#e80e16",
	radioBtnColor: "#343333",//gri
	linkColor: "#3a4ba5",
	disabled: "#c0c0bf",
	whiteColor: '#ffffff',
    blackColor: '#000000',
    grayColor: '#CCCCCC',
    grayDarkColor: '#303030',
	dialogColor: '#2c2c2c', /*'#343434'*/
    darkPinkColor: '#FE2B54',
    backColor: '#212121',
    yellowColor: '#fdf800',
	logoYellow:"#fdf800",
    lightGreenColor: '#B2FF59',
    pink: '#E7166B',
	greenColor: "#00c16e",
	greenDarkColor: "#00a04c",
	colorRedemptionApproved: "#0abf53",
	purpleColor: "#821CED",
	colorButtonFacebook: '#3b5998',
	colorButtonGoogle:  '#ea4335',
    colorButtonSms:  '#fdf800',
    colorButtonEmail:  '#008eaa',
	boxInputColor:"#1e1e1e",
	
}