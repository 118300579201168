import React, { useState, useContext } from 'react'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import { styled, alpha } from '@mui/material/styles'
import Theme from '../config/Theme'
import { Link } from 'react-router-dom'
import { AuthContext } from '../redux/contexts/AuthContext'
import ShareDialog from './ShareDialog'


export default ({isLoggedIn, anchorEl, isMenuOpen, menuId, handleMenuClose}) => {

  const { user } = useContext(AuthContext)
  const [data, setData] = useState(user)
  const [showShareDialog, setShowShareDialog] = useState(false)

  
  const toggleShareDialog = () => {
	handleMenuClose()
    setShowShareDialog(!showShareDialog)
  }

    return (
        <StyledMenu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
			id={menuId}
			keepMounted
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			open={isMenuOpen}
			onClose={handleMenuClose}
    	>
			<ShareDialog {...{showShareDialog, toggleShareDialog, data}}/>
			{isLoggedIn &&  
				<MenuItem onClick={handleMenuClose}>
          			<Link to={`/profile/${user?.data.id}`} sx={{textDecoration:"none", color:"#fff"}}> Profile </Link> 
				</MenuItem> 
			}
			{isLoggedIn &&  
				<MenuItem onClick={handleMenuClose}>
          			<Link to={`/edit-profile/${user?.data.id}`} sx={{textDecoration:"none", color:"#fff"}}> Edit </Link>
        		</MenuItem> 
			}
				<MenuItem onClick={toggleShareDialog}>Share</MenuItem>
			{isLoggedIn && 
				<MenuItem onClick={handleMenuClose}>Invite&earn</MenuItem> 
			}
			{isLoggedIn && 
				<MenuItem onClick={handleMenuClose}>
          <Link to={`/verification/${user?.data.id}`} sx={{textDecoration:"none", color:"#fff"}}> Verification </Link>
          
        </MenuItem> 
			}
				<MenuItem onClick={handleMenuClose}>
					<Link to={`/settings`} sx={{textDecoration:"none", color:"#fff"}}> Settings </Link>  
				</MenuItem>
		</StyledMenu>
    )
}

const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 3,
      minWidth: 180,
      backgroundColor: Theme.dialogColor,
      color: "#fff",
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        fontSize: 15,
        '&:hover': {
            backgroundColor: alpha(Theme.whiteColor, 0.05),
            color:Theme.yellowColor,
            fontSize: 15,
        },
        '&:active': {
          backgroundColor: alpha(Theme.whiteColor, 0.15),
          fontSize: 15,
        },

		'& a': {
			textDecoration:"none",
			color:"#fff",
			fontSize: 15,
		  },
		'& a:hover': {
			textDecoration:"none",
			backgroundColor: alpha(Theme.whiteColor, 0.05),
            color:Theme.yellowColor,
            fontSize: 15,
		}, 
		'& a:active': {
			textDecoration:"none",
			backgroundColor: alpha(Theme.whiteColor, 0.15),
            fontSize: 15,
		},  
      },
    },
  }));