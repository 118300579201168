import React, { useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Box, Button, SvgIcon, Typography, TextField, Switch } from '@mui/material'
import { styled, alpha } from '@mui/material/styles'
import Theme from "../../config/Theme"
import PageLayout from '../../Layout/PageLayout'
import TitlePageBar from '../../Layout/TitlePageBar'
import {getApiRoutes} from '../../config/Routes'
import axios from 'axios'
import icons from '../../assets/icons'
import { useNavigate } from 'react-router-dom'
import GoBackButton from '../../components/GoBackButton'
import { AuthContext } from '../../redux/contexts/AuthContext'
import {isEqual} from 'lodash'


export default () => {
    const { t } = useTranslation()
    const api = getApiRoutes()
    const navigate = useNavigate()
    const { user } = useContext(AuthContext)
    
    const [cardId, setCardId] = useState('No file chosen.')
    const [file, setFile] = useState()
    const [business, setBusiness] = useState({active: isEqual(user?.business,1) ? true : false})
    
  
    const renderLeftComponent = () => <GoBackButton/>

    const handleSwitch = (event) => {
        setBusiness(event.target.checked)
    }

    const handleBrowse = e => {
        setFile(URL.createObjectURL(e.target.files[0]))
        setCardId(`Identification document (${e.target.files[0].type}) selected.`)
        console.log(e.target.files[0])
    }

    const handleSubmit = () => {
        console.log('submit',file)
        const formData = new FormData

        formData.append('document', file)
        formData.append('business', business === true ? 1 : 0)

        axios.post(api.verifications.path, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }).then(res => {
            console.log('loaded', res.data)
        }).catch(err => {
            console.log(err.response)
        })
    }

    return (
        <PageLayout bgColor='#000'>
            <TitlePageBar title="Verification" showLeftComponent={true} leftComponent={renderLeftComponent}/>

            <Box sx={{display:"flex",  flex: 1, flexDirection:"column"}}>
                <Typography color={Theme.grayColor} variant="body2" textAlign="center" margin={3}> 
                    {t("To request your profile for verification, please upload a copy of your government issued ID card in jpg/png/pdf format")}
                </Typography>

                <Grid container>
                    <Grid item xs={12} sm={6}>
                        <CustomTextfield
                            autoFocus
                            margin="dense"
                            id="idCard"
                            label="ID card"
                            variant="filled"
                            name="newMessage"
                            value={cardId}
                            autoComplete="off"
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box sx={{display:"flex", alignItems:"center"}}>
                        <Typography color={Theme.whiteColor} variant="body2" margin={3}> 
                            {t("Request business account?")}
                        </Typography>
                        <CustomSwitch
                            name="business"
                            value={business}
                            onChange={handleSwitch}
                        />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} marginTop={3}>
                        <input
                            style={{ display: 'none' }}
                            id="uploadFile"
                            name="file"
                            type="file"
                            onChange={handleBrowse}
                            accept="image/*,application/pdf"
                        />
                        <label htmlFor="uploadFile">
                            <StyledButtonChat
                                size="small"
                                aria-label="uploadFile"
                                component="span"
                            >
                                <Typography color={Theme.yellowColor}> Browse </Typography>
                            </StyledButtonChat>
                        </label>
                    </Grid>

                    <Grid item xs={12} sm={6} marginTop={3}>
                        <StyledButton
                            size="small"
                            onClick={handleSubmit}
                            startIcon={
                                <SvgIcon>
                                    <path d={icons.ic_baseline_check_24} fill={Theme.primaryColor}/>
                                </SvgIcon> 
                            }
                        >
                            Submit
                        </StyledButton>
                    </Grid>
                    
                </Grid>

            </Box>

        </PageLayout>        
    )

}

const CustomTextfield = styled(TextField)(() => ({
    display:"flex",
    flex:1,
    fontSize:13,
    '& .MuiFilledInput-root':{
        backgroundColor:Theme.grayDarkColor,
        borderTopLeftRadius:4,
        borderTopRightRadius:4,
        borderBottom: "1px solid",
        borderBottomColor:Theme.grayColor
    },
    '& .MuiFilledInput-root:hover': {
        backgroundColor:Theme.grayDarkColor,
    },
    '& .MuiFilledInput-root.Mui-focused': {
        backgroundColor:Theme.grayDarkColor,
        borderBottom: "1px solid",
        borderBottomColor:Theme.grayColor
    },
    '& .MuiInputBase-input.Mui-disabled': {
        WebkitTextFillColor: Theme.grayColor,
        color: Theme.grayColor,
    },
  
    '& label.Mui-focused': {
        color: Theme.grayColor,
    },
    '& .MuiFilledInput-root:after':{
        borderBottom: "1px solid",
        borderBottomColor:Theme.grayColor
    },
    '& .MuiInputBase-input':{
        color: Theme.grayColor,
        fontSize:13,
        backgroundColor:Theme.grayDarkColor,
    },
    '& .MuiInputBase-input:focus':{
        color: Theme.grayColor,
        fontSize:13,
        backgroundColor:Theme.grayDarkColor
    },
  
    '& label': {
        color: Theme.whiteColor,
        fontSize:13,
    },
    '& .MuiInputLabel-root.Mui-disabled': {
        color: Theme.whiteColor,
        fontSize:13,
    },
}));

const CustomSwitch = styled(Switch)(() => ({
    '& .MuiSwitch-switchBase': {
        color: Theme.grayDarkColor,
        '&$checked': {
            color: Theme.yellowColor,
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: Theme.greenColor,
    },
    '& .MuiSwitch-switchBase.Mui-checked+ .MuiSwitch-track': {
        backgroundColor: Theme.greenColor,
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        border: 0,
        backgroundColor: Theme.grayColor,
        '&$checked': {
            color: Theme.greenColor,
        },
    },
   
}));

const StyledButton = styled(Button)(() => ({
    width: "50%",   
    backgroundColor: Theme.yellowColor,
    color: Theme.primaryColor,
    '&:hover': {
        backgroundColor: alpha(Theme.yellowColor, 0.85),
    },
}));

const StyledButtonChat = styled(Button)(() => ({
    width: "50%",   
    backgroundColor: alpha(Theme.grayColor, 0.10),
    color: Theme.yellowColor,
    '&:hover': {
        backgroundColor: alpha(Theme.grayColor, 0.15),
    },
}));